import {
    Box,
    Typography,
    Divider,
    Button,
    Grid,
    CardContent,
    ListItemIcon,
    Card,
  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import propvisionLogoWithText from '../../../assets/svg/propvisionLogoWithText.svg';
import propmagnetLogoWithText from '../../../assets/svg/propmagnetLogoWithText.svg';
import lockOpen from '../../../assets/svg/lockOpen.svg';
import CONSTANTS from '../../../constants/common'
const {propvisionCards, promagnetCards} = CONSTANTS;

const ExploreOurProducts = () => {
    const navigate = useNavigate();

    const handleNavigate = (navType) => {
        switch (navType) {
          case 'home':
            navigate('/')
            break;
          case 'brand_req_list':
            navigate('/brand-requirement-list')
            break;
          case 'propvision':
            navigate('/propvision')
            break;
          case 'propmagnet':
            navigate('/propmagnet')
            break;
          case 'requirements':
            navigate('/brand-requirement-list')
            break;
          case 'about':
            navigate('/about')
            break;
          default:
            console.log('Invalid navigation type');
        }
      };
    return (
    <Box
        className='dashboardSectionWrap'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        p={{xsl: '15px 15px 15px 15px', sm: '20px 20px 20px 20px', md: '10px 10px 10px 10px', lg: '42px 41.49px 52px 41px'}}
        textAlign='center'
        sx={{
          backgroundColor: (theme) => theme.palette.customColor.neutralWhite,
          borderRadius: '16px',

        }}
        mb={{xs: '57px'}}
      >
        <Typography variant='type_24_600_414141'>
          Explore our products and offerings
        </Typography>
        <Divider sx={{ mt: '12px', mb: '18px', width: { xs: '10rem', xsl: '15rem', sm: '20rem', md: '34.375rem'}, border: '1px solid #D3D7DE' }} />
        <Typography
          variant='type_32_500_414141'
          sx={{
            mb: {xsl: '1.5rem', sm: '2rem', md: '3rem', lg: '4.875rem'},
            fontSize: {xs: '22px', sm: '28px', md: '30px', lg: '32px'},
          }}
          gutterBottom
        >
          Agprop aims to democratize real estate by leveraging technology to provide transparent, efficient, and accessible solutions for brokers and retailers.        </Typography>
        {/* Propvision starts */}
        <Box mb={6.625} display='flex' flexDirection={{xs: 'column', md: 'row'}} width='100%'>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent='space-between'
            width={{md: '30%', lg: '27.9%'}}
            mr={{ sm: 0, md: 2}}
            mb={{ xs: 1, xsl: 2, sm: 3, md: 0}}
            sx={{
              background: '#D7EDFF',
              borderRadius: '32.94px',
              padding: {xs: '10px 10px 10px 10px', xsl: '20px 20px 20px 20px', sm: '30px 30px 30px 30px', md: '20px 20px 20px 20px', lg: '26px 30px 26px 29px'}
            }}
          >
            <Box mb={2} textAlign="start">
              <Box
                component="img"
                src={propvisionLogoWithText}
                alt="propvisionLogoWithText"
                sx={{
                  width: { xs: 200, xsl: 220, md: 180, lg: 248 },
                  height: 50.84,
                }}
              />
              <Divider sx={{ my: 2 }} />
              <Typography
                variant="type_16_500_414141"
                sx={{
                  paddingRight: '15px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 8,
                  textOverflow: 'ellipsis',
                  lineHeight: '24px'
                }}
              >
                Propvision is a powerful property management tool revolutionizes 
                the real estate expansion experience. The tool is designed to offer personalized solutions 
                for buyers and leasers alike.
              </Typography>
            </Box>
            <Box textAlign="start" display="flex" alignItems={'center'}>
              <Button
                variant="contained"
                sx={{ background: '#0048D0', py: '7px', px: '15px', borderRadius: '3rem', textTransform: 'none' }}
                onClick={() => handleNavigate('propvision')}
              >
                <Typography variant="type_12_500_FFFFFF">
                  Explore more
                </Typography>
              </Button>
              <ListItemIcon 
                sx={{
                  ml: '3px',
                  borderRadius: '100%',
                  minWidth: '28.45px',
                  height: '28.45px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#363636'
                }}
              >
                <CallMadeIcon
                  sx={{width: '14.22px', height: '14.22px', color: '#FFFFFF'}}
                  onClick={() => handleNavigate('propvision')}
                />
              </ListItemIcon>
            </Box>
          </Box>
          <Box
            width={{md: '72%', lg: '72.1%'}}
            sx={{
              borderRadius: '32px',
              background: '#F3F7FF',
              padding: { xs: '10px 10px 10px 10px', sm: '16px 16px 16px 16px', md: '16px 16px 16px 16px', lg: '23px 26.49px 24.51px 25.51px'}
            }}
          >
            <Grid container spacing={{xs: 1.5, xsl: 2.5, sm: 3, md: 2, lg: 4}} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
              {propvisionCards?.map((item, index) => (
                <Grid gap={{xsl: '10px', sm: '32px'}} item xs={12} sm={6} key={item.title + index}>
                  <Card sx={{ borderRadius: '16px', textAlign: 'start', height: '100%'}} >
                    <Grid container sx={{height: '100%'}}>
                      <Grid item sx={{ width: '10px', backgroundColor: item.bgColor}} />
                      <Grid item xs sx={{padding: {xs: '24px 15px 24px 17px', lg: '24px 36px 24px 24px'}}}>
                        <CardContent sx={{padding: '0px', paddingBottom: '0px !important'}} >
                          <Typography  variant="type_20_500_414141">{item.title}</Typography>
                          <Divider sx={{ border: '1px solid #0000001C', my: 1, width: '185px'}} />
                          <Typography variant="type_12_600_414141">
                            {item.description}.
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {/* Propvision ends */}
        {/* Propmagnet starts */}
        <Box mb={'43px'} display="flex" flexDirection={{xs: 'column', md: 'row'}} width="100%">
          <Box
            order={{ xs: 2, md: 1 }}
            width={{md: '72%', lg: '70%'}}
            sx={{
              borderRadius: '32px',
              background: '#F3F7FF',
              padding: { xs: '10px 10px 10px 10px', sm: '16px 16px 16px 16px', md: '16px 16px 16px 16px', lg: '23px 26.49px 24.51px 25.51px'}
            }}>
            <Grid container spacing={{ xs: 1.5, xsl: 2.5, sm: 3, md: 2, lg: 4}} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }}>
              {promagnetCards?.map((item, index) => (
                <Grid gap={{xsl: '10px', sm: '32px'}} item xs={12} sm={6} key={item.title + index}>
                  <Card sx={{ borderRadius: '16px', textAlign: 'start', height: '100%'}}>
                    <Grid container sx={{height: '100%'}}>
                      <Grid item sx={{ width: '10px', backgroundColor: item.bgColor}} />
                      <Grid item xs sx={{padding: {xs: '24px 15px 24px 17px', lg: '24px 29px 24px 24px'}}}>
                        <CardContent sx={{padding: '0px', paddingBottom: '0px !important'}} >
                          <Typography  variant="type_20_500_414141">{item.title}</Typography>
                          <Divider sx={{ border: '1px solid #0000001C', my: 1, width: '185px'}} />
                          <Typography variant="type_12_600_414141">
                            {item.description}.
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent="space-between"
            order={{ xs: 1, md: 2 }}
            width={{md: '30%', lg: '30%'}}
            ml={{ sm: 0, md: 2}}
            mb={{ xsl: 2, sm: 3, md: 0}}
            sx={{
              background: '#D7EDFF',
              borderRadius: '32.94px',
              padding: {xs: '10px 10px 10px 10px', xsl: '20px 20px 20px 20px', sm: '30px 30px 30px 30px', md: '20px 20px 20px 20px', lg: '26px 30px 26px 29px'}
            }}
          >
            <Box mb={2} textAlign="start">
              <Box
                component="img"
                src={propmagnetLogoWithText}
                alt="propmagnetLogoWithText"
                sx={{
                  width: { xs: 200, xsl: 220, md: 180, lg: 273 },
                  height: 39,
                }}
              />
              <Divider sx={{ my: 2 }} />
              <Typography 
                variant="type_16_500_414141"
                sx={{
                  paddingRight: '24px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 8,
                  textOverflow: 'ellipsis',
                  lineHeight: '24px'
                }}
              >
                Propmagnet is specifically desgined for Brokers to seamlessly customize and match properties 
                according to specific requirements. Enhance efficiency in managing client requirements and 
                generating leads resulting in maximize deal closures.
              </Typography>
            </Box>
            <Box display="flex" alignItems={'center'}>
              <Button
                variant="contained"
                sx={{ background: '#0048D0', py: '7px', px: '15px', borderRadius: '3rem', textTransform: 'none' }}
                onClick={() => handleNavigate('propmagnet')}
              >
                <Typography variant="type_12_500_FFFFFF">
                  Explore more
                </Typography>
              </Button>
              <ListItemIcon 
                sx={{
                  ml: '3px',
                  borderRadius: '100%',
                  minWidth: '28.45px',
                  height: '28.45px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#363636'
                }}
              >
                <CallMadeIcon
                  sx={{width: '14.22px', height: '14.22px', color: '#FFFFFF'}}
                  onClick={() => handleNavigate('propmagnet')}
                />
              </ListItemIcon>
            </Box>
          </Box>
        </Box>
        {/* Propmagnet ends */}
        <Typography variant="type_16_500_414141" mb={'12px'}>
          Precision Property Matchmaking
        </Typography>
        <Typography variant="type_24_500_414141"  mb={'14px'} sx={{maxWidth: '730px'}}>
          Find the perfect property unit with research-driven insights and customized listings
        </Typography>
        <Box display="flex" alignItems={'center'}>
          <Button
            variant="contained"
            sx={{ background: '#0048D0', py: '7px', px: '15px', borderRadius: '3rem', textTransform: 'none' }}
            onClick={() => handleNavigate('propvision')}
          >
            <Typography variant="type_12_500_DFE5F0">
              Unlock Property Options
            </Typography>
          </Button>
          <ListItemIcon 
            sx={{
              ml: '3px',
              borderRadius: '100%',
              minWidth: '38px',
              height: '38px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#363636'
            }}
          >
            <Box
              component="img"
              src={lockOpen}
              alt="propvisionLogoWithText"
              sx={{width: '18px', height: '18px', color: '#FFFFFF'}}
            />
          </ListItemIcon>
        </Box>
      </Box>
    )
}

export default ExploreOurProducts;
const COMMON_LANG = {
  LOGIN: 'Login',
};

const DASHBOARD_LANG = {

};

const BUTTON_LANG = {
  EXPLORE_MORE: 'Explore more'
};


export { COMMON_LANG, DASHBOARD_LANG, BUTTON_LANG};

const API_VERSION = {
  V1: '/api/v1',
};

const API_ROUTES = {
  V1: {
    TOP_BRAND_LIST: `${API_VERSION.V1}/brands/top-brands`,
    BRAND_REQ_LIST: `${API_VERSION.V1}/brand_requirements/requirementsById`,
    REQ_DETAILS: `${API_VERSION.V1}/brand_requirements/requirement/details`,
    CONTACT_US: `${API_VERSION.V1}/auth/user/contact-us`,
    SUBSCRIBE_NEWSLETTER: `${API_VERSION.V1}/auth/user/news-letter`,
    BRAND_DETAIL: `${API_VERSION.V1}/brands`
  },
};

export default API_ROUTES;

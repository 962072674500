import '@fontsource/inter';
import '@fontsource/poppins';
import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
  palette: {
    error: {
      main: '#FF4949',
      light: '#FCEDEA'
    },
    success: {
      main: '#13CE66',
      light: '#EBF7EE'
    },
    warning: {
      main: '#FFCC3D',
      light: '#FEF7EA'
    },
    info: {
      main: '#2D8EFF',
      light: '#E5EFFA'
    },
    customColor: {
      primaryBlue100: '#0048D0',
      primaryBlue60: '#B2CFFF',
      primaryBlue40: '#E9EEF8',
      primaryBlue20: '#F3F7FF',
      secondaryGreen: '#2F3038',
      neutralBlack: '#202226',
      neutralParaGrey: '#535B69',
      neutralDisabledGrey: '#A4ACBB',
      neutralBorder: '#DDE0E5',
      neutralWhite: '#FFFFFF',
      textColor1: '#344054',
      textColor2: '#414141',
    },
  },
  typography: {
    fontWeights: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 800,
    },
    fontSizes: {
      small: 14,
      medium: 16,
      large: 18,
      xLarge: 20,
    },
    type_8_600_707070: {
      fontFamily: 'Inter',
      fontSize: 8,
      fontWeight: 600,
      color: '#707070',
    },
    type_8_600_3E3E3E_POPPINS: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 8,
      fontWeight: 600,
      color: '#3E3E3E',
    },
    type_10_600_404040: {
      fontFamily: 'Inter',
      fontSize: 10,
      fontWeight: 600,
      color: '#404040',
    },
    type_12_500_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_12_500_DFE5F0: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#DFE5F0',
    },
    type_12_500_414141: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#414141',
    },
    type_12_500_DFE5F0_POPPINS: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 12,
      fontWeight: 500,
      color: '#DFE5F0',
    },
    type_12_500_707070: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#707070',
    },
    type_12_500_3E3E3E: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#3E3E3E',
    },
    type_12_500_F0F0F0: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#F0F0F0',
    },
    type_12_600_808080: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#808080',
    },
    type_12_600_3E3E3E: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#3E3E3E',
    },
    type_12_600_414141: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#414141',
    },
    type_12_600_424242: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#424242',
    },
    type_12_600_0048D0: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#0048D0',
    },
    type_12_600_6D6D6D: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 600,
      color: '#6D6D6D',
    },
    type_14_500_76786B: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#76786B',
    },
    type_14_500_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_14_500_FFFFFF_POPPINS: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_14_500_414141: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#414141',
    },
    type_14_500_0048D0: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#0048D0',
    },
    type_14_500_000000: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#000000',
    },
    type_14_500_404040: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 500,
      color: '#404040',
    },
    type_14_600_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 600,
      color: '#FFFFFF',
    },
    type_14_600_404040: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 600,
      color: '#404040',
    },
    type_14_600_2F3038: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 600,
      color: '#2F3038',
    },
    type_14_600_424242: {
      fontFamily: 'Inter',
      fontSize: 14.56,
      fontWeight: 600,
      color: '#424242',
    },
    type_14_600_707070: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 600,
      color: '#707070',
    },
    type_16_400_707070: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#707070',
    },
    type_16_400_414141: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#414141',
    },
    type_16_500_414141: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#414141',
    },
    type_16_500_76786B: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#76786B',
    },
    type_16_500_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_16_500_151515: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 500,
      color: '#151515',
    },
    type_16_600_0048D0: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 600,
      color: '#0048D0',
    },
    type_16_600_808080: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 600,
      color: '#808080',
    },
    type_16_600_404040: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 600,
      color: '#404040',
    },
    type_16_700_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 700,
      color: '#FFFFFF',
    },
    type_18_400_414141: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 18,
      fontWeight: 400,
      color: '#414141',
    },
    type_18_600_3E3E3E: {
      fontFamily: 'Inter',
      fontSize: 18,
      fontWeight: 600,
      color: '#3E3E3E',
    },
    type_18_600_414141: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 18,
      fontWeight: 600,
      color: '#414141',
    },
    type_18_700_3E3E3E: {
      fontFamily: 'Inter',
      fontSize: 18,
      fontWeight: 700,
      color: '#3E3E3E',
    },
    type_20_500_414141: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 20,
      fontWeight: 500,
      color: '#414141',
    },
    type_20_600_3E3E3E: {
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 600,
      color: '#3E3E3E',
    },
    type_20_600_414141: {
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 600,
      color: '#414141',
    },
    type_24_500_414141: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 500,
      color: '#414141',
    },
    type_24_500_404040: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 500,
      color: '#404040',
    },
    type_24_600_414141: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 600,
      color: '#414141',
    },
    type_24_600_424242: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 600,
      color: '#424242',
    },
    type_24_600_404040: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 600,
      color: '#404040',
    },
    type_24_600_424242_POPPINS: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 24,
      fontWeight: 600,
      color: '#424242',
    },
    type_24_700_414141: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 700,
      color: '#414141',
    },
    type_28_500_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 28,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_28_500_414141_POPPINS: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: 28,
      fontWeight: 500,
      color: '#414141',
    },
    type_28_600_414141: {
      fontFamily: 'Inter',
      fontSize: 28,
      fontWeight: 600,
      color: '#414141',
    },
    type_32_500_414141: {
      fontFamily: 'Inter',
      fontSize: 32,
      fontWeight: 500,
      color: '#414141',
    },
    type_32_500_404040: {
      fontFamily: 'Inter',
      fontSize: 32,
      fontWeight: 500,
      color: '#404040',
    },
    type_32_600_414141: {
      fontFamily: 'Inter',
      fontSize: 32,
      fontWeight: 600,
      color: '#414141',
    },
    type_32_600_464646: {
      fontFamily: 'Inter',
      fontSize: 32,
      fontWeight: 600,
      color: '#464646',
    },
    type_36_500_404040: {
      fontFamily: 'Inter',
      fontSize: 36,
      fontWeight: 500,
      color: '#404040',
    },
    type_36_500_414141: {
      fontFamily: 'Inter',
      fontSize: 36,
      fontWeight: 500,
      color: '#414141',
    },
    type_42_600_45464F: {
      fontFamily: 'Inter',
      fontSize: 42,
      fontWeight: 600,
      color: '#45464F',
    },
    type_72_500_FFFFFF: {
      fontFamily: 'Inter',
      fontSize: 72,
      fontWeight: 500,
      color: '#FFFFFF',
    },
    type_72_500_404040: {
      fontFamily: 'Inter',
      fontSize: 72,
      fontWeight: 500,
      color: '#404040',
    },
  },
  customStyles: {
    backdrop: {
      background: 'rgba(255, 255, 255, 0.01)',
      backdropFilter: 'blur(22px)',
    },
    bg_262626: '#262626'
  },
  breakpoints: {
    values: {
      xs: 0,
      xsl: 335,
      sm: 600,
      md: 900,
      lg: 1200,
      lxs: 1440,
      xl: 1536,
      xll: 1700,
    },
  },
});

import { styled } from '@mui/material/styles';

const StyledContactUs = styled('div')(
  ({ theme }) => `
    .PhoneInput{
      border: unset;
      background-color: #F8F8F8;
      border-radius: 7.67px;
      display: flex;
      padding: 0px;
      height: 100%;
      overflow: hidden;
    }

    .PhoneInput{
      .PhoneInputCountry{
        padding-left: 6.71px;

      }
    }

    .PhoneInput input{
      border: unset;
      background-color: #F8F8F8;
      padding-left: 16px;
      height: 100%;
    }

    .PhoneInput .PhoneInputCountryIcon{
      box-shadow: unset;
      border-radius: 4px;
      overflow: hidden;
    }

    .PhoneInput .PhoneInputCountrySelectArrow {
      width: 10px;
      height: 15px;
      margin-bottom: 3px;
      clip-path: polygon(25% 50%, 100% 0, 100% 50%, 0 50%);
      background-color: #1C1B1F;
      opacity: 1;
    }
    .PhoneInput input:hover,
    .PhoneInput input:focus,
    .PhoneInput input:focus-visible,
    .PhoneInput input:active {
      outline: unset;
      border: unset; /* Remove border on hover/focus/active */
      box-shadow: none; /* Remove shadow effects */
      background-color: #F8F8F8; /* Ensure background color stays same */
    }
    
    .MuiOutlinedInput-notchedOutline{ 
      border: 0.96px solid #F1F1F1; 
      border-radius: 8px; 
    }

    .contactUsWrap{
      margin-left: 7.5rem;
      margin-right: 7.5rem;
    }
    .contactUsWrapPadding{
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    }
    
    @media (min-width: ${theme.breakpoints.values.xs}px) {
      .contactUsWrap{
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
      
      .contactUsWrapPadding{
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.xsl}px) {
      .contactUsWrap{
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .contactUsWrapPadding{
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      .contactUsWrap{
        margin-left: 2rem;
        margin-right: 2rem;
      }        
      .contactUsWrapPadding{
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      .contactUsWrap{
        margin-left: 4rem;
        margin-right: 4rem;
      }

      .contactUsWrapPadding{
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      .contactUsWrap{
        margin-left: 7.2rem;
        margin-right: 7.2rem;
      }
        
      .contactUsWrapPadding{
        padding-left: 7.2rem;
        padding-right: 7.2rem;
      }
    }
  `,
);

export default StyledContactUs;
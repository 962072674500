import { useState } from 'react';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { validationSchemaContactUpForm } from '../../utils/validations';
import { fetchContactUs } from '../../store/reducers/contactUs/apiThunk';
import messageSent from '../../assets/svg/messageSent.svg';

const ContactUsForm = () => {
    const dispatch = useDispatch();
    const [isMessageSent, setIsMessageSent] = useState(false);

    const submitHandler = async (values, formikHelpers) => {
        const postData = {
            name: values.name,
            mobile: values.contactNumber,
            email: values.email,
            subject: values.subject,
            message: values.message,
        }
        const response = await dispatch(fetchContactUs(postData)).then(({ payload }) => payload)
        if(response) {
            setIsMessageSent(true)
            formikHelpers.resetForm();
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            contactNumber: '',
            email: '',
            subject: '',
            message: '',
        },
        validationSchema: validationSchemaContactUpForm,
        onSubmit: submitHandler
    })

    const {
        handleSubmit,
        values: {
            name,
            contactNumber,
            email,
            subject,
            message
        } = {},
        touched: {
            name: tucName = false,
            contactNumber: tucContactNumber = false,
            email: tucEmail = false,
            subject: tucSubject = false,
            message: tucMessage = false,

        } = {},
        errors: {
            name: errName,
            contactNumber: errContactNumber,
            email: errEmail,
            subject: errSubject,
            message: errMessage,
        } = {}
    } = formik;

    return (
        isMessageSent ? 
        (
            <Grid item xs={12} md={6} sx={{pt: '146px !important', pl: {xs: '10px !important', sm: '32px !important'}, pr: {xs: '10px', sm: '30px'}}}>
                <Box sx={{mb: '48px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography variant='type_32_600_464646' sx={{mb: '9px'}}>
                        We've received your query!
                    </Typography>
                    <Typography variant='type_14_500_404040'>
                        Keep checking your email, We'll react out to you within 2 hours.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mb: '48px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',  // Full width
                    }}
                >
                    <Box
                        component="img"
                        src={messageSent}
                        alt="messageSent"
                        sx={{maxWidth: '213px', width: '100%', height: 'auto', borderRadius: '8px'}}
                    />
                </Box>
                <Box display='flex' justifyContent='center'>
                    <Button
                        variant='contained'
                        onClick={() => setIsMessageSent(false)}
                        sx={{
                            lineHeight: 'unset',
                            textTransform: 'none',
                            py: '9.5px',
                            px: '52px',
                            minWidth: '0px',
                            borderRadius: '40px',
                            backgroundColor: '#0048D0'
                        }}
                    >
                        <Typography variant='type_14_600_FFFFFF'>
                            Send another query
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        )
        :
        (
            <Grid item xs={12} md={6} sx={{pt: '0px !important', pl: {xs: '10px !important', sm: '32px !important'}, pr: {xs: '10px', sm: '30px'}}}>
                <Box sx={{mb: '40px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography variant='type_32_600_464646' sx={{mb: '9px'}}>
                        We are here to help
                    </Typography>
                    <Typography variant='type_14_500_404040'>
                        We believe in seamless and effective services for our client
                    </Typography>
                </Box>
                <form  onSubmit={handleSubmit}>
                    <Grid container spacing={2} mb={'20.14px'}>
                        <Grid item xs={12} sm={6}>
                            <Typography 
                                variant="type_14_500_404040"
                                sx={{mb: '4.5px', display: 'block', textAlign: 'left'}}
                            >
                                Name*
                            </Typography>
                            <TextField
                                placeholder='Name'
                                fullWidth
                                name='name'
                                variant='outlined'
                                value={name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={tucName && Boolean(errName)}
                                helperText={tucName && errName}
                                inputProps={{
                                    maxLength: 100,
                                }}
                                sx={{
                                    borderRadius: '7.67px',
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: '7.67px',
                                    backgroundColor: '#F8F8F8',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '7.67px',
                                    border: '0.96px solid #F1F1F1',
                                    overflow: 'hidden'
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography 
                                variant="type_14_500_404040"
                                sx={{mb: '4.5px', display: 'block', textAlign: 'left'}}
                            >
                                Contact Number*
                            </Typography>
                            <TextField
                                fullWidth
                                error={Boolean(tucContactNumber && Boolean(errContactNumber))}
                                helperText={tucContactNumber && errContactNumber}
                                InputProps={{
                                    inputComponent: PhoneInput,
                                    inputProps: {
                                        value: contactNumber,
                                        onChange: (value) => {formik.setFieldTouched('contactNumber', true); formik.setFieldValue('contactNumber', value)},
                                        international: true,
                                        defaultCountry: 'IN',
                                        maxLength: 16,
                                    },
                                }}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        height: '56px',
                                        maxHeight: '100%'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mb={'17.26px'}>
                        <Grid item xs={12}>
                            <Typography 
                                variant="type_14_500_404040"
                                sx={{mb: '4.5px', display: 'block', textAlign: 'left'}}
                            >
                                Email*
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder='Email address'
                                variant='outlined'
                                name='email'
                                value={email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={tucEmail && Boolean(errEmail)}
                                helperText={tucEmail && errEmail}
                                inputProps={{
                                    maxLength: 254,
                                }}
                                sx={{
                                    borderRadius: '7.67px',
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: '7.67px',
                                    backgroundColor: '#F8F8F8',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '7.67px',
                                    border: '0.96px solid #F1F1F1',
                                    overflow: 'hidden'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Third Row */}
                    <Grid container mb={'20px'}>
                        <Grid item xs={12}>
                            <Typography 
                                variant="type_14_500_404040"
                                sx={{mb: '4.5px', display: 'block', textAlign: 'left'}}
                            >
                                Subject*
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder='Subject'
                                variant='outlined'
                                name='subject'
                                value={subject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={tucSubject && Boolean(errSubject)}
                                helperText={tucSubject && errSubject}
                                inputProps={{
                                    maxLength: 300,
                                }}
                                sx={{
                                    borderRadius: '7.67px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '7.67px',
                                        backgroundColor: '#F8F8F8',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '7.67px',
                                        border: '0.96px solid #F1F1F1',
                                        overflow: 'hidden'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Fourth Row */}
                    <Grid container mb={'20px'}>
                        <Grid item xs={12}>
                            <Typography 
                                variant="type_14_500_404040"
                                sx={{mb: '4.5px', display: 'block', textAlign: 'left'}}
                            >
                                Message*
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder='Write here'
                                variant='outlined'
                                name='message'
                                multiline
                                minRows={4}
                                value={message}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={tucMessage && Boolean(errMessage)}
                                helperText={tucMessage && errMessage}
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                sx={{
                                    borderRadius: '7.67px',
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: '7.67px',
                                    backgroundColor: '#F8F8F8',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '7.67px',
                                    border: '0.96px solid #F1F1F1',
                                    overflow: 'hidden'
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Box display='flex' justifyContent='flex-end'>
                        <Button
                            type='submit'
                            variant='contained'
                            sx={{
                                lineHeight: 'unset',
                                textTransform: 'none',
                                py: '9.5px',
                                px: '52px',
                                minWidth: '0px',
                                borderRadius: '40px',
                                backgroundColor: '#0048D0'
                            }}
                        >
                            <Typography variant='type_14_600_FFFFFF'>
                                Submit
                            </Typography>
                        </Button>
                    </Box>
                </form>
            </Grid>
        )
    )
}

export default ContactUsForm;
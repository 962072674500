import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    isLoading: false,
    isShimmerLoading: false,
    currentPageRedux: 1,
  },
  reducers: {
    updateLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateShimmer: (state, action) => {
      state.isShimmerLoading = action.payload;
    },
    updateCurrentPage: (state, action) => {
      state.currentPageRedux = action.payload;
    }
  },
});

const {
  actions: { updateLoading, updateShimmer, updateCurrentPage },
  reducer,
} = loaderSlice;

const selectorLoader = (state) => state.loader;

export { updateLoading, updateShimmer, updateCurrentPage, selectorLoader };

export default reducer;

import { combineReducers } from '@reduxjs/toolkit';
// import authentication from './authentication';
import topBrand from './topBrandReq';
import loader from './loader';
import contactData from './contactUs';
const rootReducer = combineReducers({
  topBrand,
  loader,
  contactData,
});

export default rootReducer;

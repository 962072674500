import { useEffect, useState } from 'react';
import { Card as MUICard, CardContent, CardMedia, Typography, Box, Skeleton } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
//import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import verifiedIcon from '../../../assets/svg/verifiedIcon.svg';

const Card = ({ card, handleCardClick }) => {
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImageError(false);
    setLoading(true);
    const img = new Image();
    img.src = card.logo;
    img.onload = () => handleImageLoad();
    img.onerror = () => handleImageError();
  }, [card.logo]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setImageError(true);
    setLoading(false);
  };

  const handleClick = () => {
    handleCardClick(card._id || '')
  }

  return (
    <MUICard
      onClick={handleClick}
      sx={{
        width: '100%',
        position: 'relative',
        borderRadius: '18px',
        cursor: 'pointer',
        border: '1px solid #9EC1FF80',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140 }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
                width: '100%',
                height: {xs: '140px'},
            }}
          />
        </Box>
      ) 
      :
      <Box sx={{ p: '5px', height: '140px' }}>
        {
          imageError ? (        
            <ImageNotSupportedIcon sx={{ height: '140px', width: '100%', fontSize: '124.89px', color: 'gray' }} />
        )
        :
        (
          
          <CardMedia
            component="img"
            image={card.logo}
            alt={card.title}
            loading="lazy"
            sx={{objectFit: 'contain', height: '100%', maxHeight: '140px', display: loading ? 'none' : 'unset',}}
          />        
        )
        }
      </Box>
      }
      <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography
          variant="type_20_600_3E3E3E"
          sx={{
            mb: '8px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 1,
            wordBreak: 'break-word',
            textOverflow: 'ellipsis'
          }}
        >
          {card.name}
        </Typography>
        <Typography variant="type_12_600_808080" color="textSecondary" sx={{mb: '12px', display: 'flex', alignItems: 'center'}}>
          <Box
            component="img"
            src={verifiedIcon}
            alt="verifiedIcon"
            sx={{
              width: 20,
              height: 20,
              mr: '5px'
            }}
          />
          Verified by Agprop
        </Typography>
        <Typography
          variant="type_12_600_3E3E3E"
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            wordBreak: 'break-word',
            textOverflow: 'ellipsis',
            minHeight: '30px'
          }}
        >
          {
            card.requirementCount === 0 && card.locationCount === 0 ?
            ''
            : card.requirementCount < 99 && card.locationCount > 0 ? 
            `${card.requirementCount} Requirements in ${card.locationCount} Locations`
            : `99+ Requirements in ${card.locationCount} Locations`
          }
        </Typography>
      </CardContent>
    </MUICard>
  );
};

export default Card;

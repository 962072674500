import { Box, Divider, Grid, Skeleton, Typography, Link } from '@mui/material';
import { useState } from 'react';
import StyledAbout from './StyledAbout';
import heroSectionAbout from '../../assets/jpeg/heroSectionAbout.jpg';
import aboutPage1 from '../../assets/png/aboutPage1.png';
import shivekProfilePic from '../../assets/png/shivekProfilePic.png';
import awardGroupPic1 from '../../assets/png/awardGroupPic1.png';
import awardGroupPic1Res from '../../assets/png/awardGroupPic1Res.png';
import awardGroupPic2 from '../../assets/jpeg/awardGroupPic2.jpg';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import CONSTANTS from '../../constants/common';
import linkedInIcon from '../../assets/svg/linkedInIcon.svg';

const {ourApproachData} = CONSTANTS

const About = () => {
    const [loadingStates, setLoadingStates] = useState({
        isHeroSectionLoading: true,
        isAboutPage1Loading: true,
        isShivekProfilePicLoading: true,
        isAwardGroupPicLoading: true,
        isAwardGroupPic2Loading: true,
    });

    const setLoadingState = (key, value) => {
        setLoadingStates(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    return (
        <StyledAbout>
            <Box
                className='aboutWrap'
                sx={{
                    mt: { xs: '95px', md: '120px', lg: '201px'},
                    display: 'flex',
                    flexDirection: 'column',
                    mb: { xs: '30%', xsl: '25%', sm: '20%', md: '10%'}
                }}
            >
                {loadingStates.isHeroSectionLoading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        width: '100%',
                        height: { xs: '360px', xsl: '400px', sm: '420px', md: '575px', lg: '718px'},
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={heroSectionAbout}
                    alt='heroSectionAbout'
                    onLoad={() => setLoadingState('isHeroSectionLoading', false)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        width: '100%',
                        objectFit: { xs: 'cover', sm: 'fill'},
                        objectPosition: {xs: '70% 0px', sm: 'unset'},
                        minHeight: { xs: '360px', xsl: '400px', sm: '420px', md: 'unset'},
                        display: loadingStates.isHeroSectionLoading ? 'none' : 'block',
                    }}
                />
                <Typography
                    variant='type_72_500_FFFFFF'
                    sx={{
                        fontSize: {xs: '25px',  xsl: '30px', md: '40px', lg: '72px'},
                        lineHeight: {xs: '25px', xsl: '30px', md: '50px', lg: '93.6px'}
                    }}
                >
                    Making India More
                </Typography>
                <Typography
                    variant='type_72_500_FFFFFF'
                    sx={{
                        fontSize: {xs: '25px',  xsl: '30px', md: '40px', lg: '72px'},
                        mb: {xs: '8px', sm: '10px', md: '12px', lg: '16px'},
                        lineHeight: { xs: '25px', xsl: '30px', md: '50px', lg: '93.6px'}
                    }}
                >
                Accessible
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xs: '15px', xsl: '18px', md: '20px', lg: '28px'},
                        lineHeight: { xs: '16px', xsl: '20px', md: '30px', lg: '42px'},
                        maxWidth: '61%',
                        pr: '5%',
                        mb: {sm: '10px', md: '15px', lg: '21px'}
                    }}
                    variant='type_28_500_FFFFFF'
                >
                    A multidisciplinary team collaborates to ensure all your needs are met, 
                    from detailed client consultations to building critical recommendations 
                    through data-driven research.
                </Typography>
                <Box
                    display='flex'
                    alignItems={'center'}
                    mb={{ sm: '22px', md: '40px', lg: '52px'}}
                >
                </Box>
            </Box>
            <Box className='aboutWrap' sx={{mb: '24px'}}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={4.4} sx={{mb: { xs: '30px', md: '0px'}, display: 'flex', justifyContent: 'center' }}>
                        {loadingStates.isAboutPage1Loading && (
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                    borderRadius: '32.94px',
                                    width: '426px',
                                    height: {xs: '271px', xsl: '343px', md: '444px', lg: '366px'},
                                }}
                            />
                        )}
                        <Box
                            component='img'
                            src={aboutPage1}
                            alt='aboutPage1'
                            onLoad={() => setLoadingState('isAboutPage1Loading', false)}
                            sx={{
                                borderRadius: '32.94px',
                                maxWidth: '427.62px',
                                width: '100%',
                                objectFit: 'fill',
                                display: loadingStates.isAboutPage1Loading ? 'none' : 'block',
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={7.6}
                        sx={{
                            pl: {xs: '0px', md: '14.22px'}, 
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#FFFFFF',
                                pt: '31.36px',
                                pl: '25.38px',
                                pr: '31px',
                                pb: '30px',
                                borderRadius: '32.94px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Typography variant='type_32_600_414141' sx={{mb: '21px'}}>
                                About
                            </Typography>
                            <Typography
                                variant="type_16_500_414141"
                                align="left"
                                sx={{lineHeight: '19.36px', mb: '25px'}}
                            >
                                agprop is a leading Software as a Service (SaaS) provider delivering innovative and robust 
                                property solutions tailored to the diverse needs of businesses across various industries. 
                                Our comprehensive suite of products, known as the go-to SaaS platform for Brands and 
                                Brokers, empowers businesses and property advisors with the tools to enhance 
                                operations, increase efficiency, and achieve strategic goals.
                            </Typography>
                            <Typography
                                variant="type_16_500_414141"
                                align="left"
                                sx={{lineHeight: '19.36px', mb: '26px'}}
                            >
                                With a subscription-based model, our cutting-edge platform is designed to streamline 
                                property management for both Brands and Brokers. We are committed to expanding our 
                                reach and growing our customer base across India. The positive results we've achieved 
                                with specific brands and brokers underscore the quality and effectiveness of our 
                                solutions.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className='aboutWrap' sx={{mb: '24px'}}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{minHeight: '118px', backgroundColor: '#FFFFFF', borderRadius: '36px', py: '27px', pl: '25px',  pr: '45px'}}>
                            <Typography variant="type_32_600_414141" sx={{mb: '8px', display: 'inline-block'}}>
                                Mission
                            </Typography>
                            <Divider sx={{width: '54%', height: '1px', background: '#D3D7DE', border: 'unset', mb: '13px'}} />
                            <Typography variant="type_16_500_414141">
                                Digitalize and democratize real estate by leveraging technology for 
                                sustainable and informed solutions, making services accessible and 
                                standardized across India.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{minHeight: '118px', backgroundColor: '#FFFFFF', borderRadius: '36px', py: '27px', pl: '25px', pr: '45px'}}>
                            <Typography variant="type_32_600_414141" sx={{mb: '8px', display: 'inline-block'}}>
                                Vision
                            </Typography>
                            <Divider sx={{width: '54%', height: '1px', background: '#D3D7DE', border: 'unset', mb: '13px'}} />
                            <Typography variant="type_16_500_414141">
                                Establish a process-driven institution that upholds global service 
                                standards, becoming a trusted leader in real estate consultancy.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className='aboutWrap' sx={{mb: '78.41px'}}>
                <Grid container sx={{backgroundColor: '#FFFFFF', borderRadius: '38.69px' }}>
                    <Grid item xs={12} md={7.9} sx={{order: { xs: 2, md: 1 },}}>
                        <Box sx={{ pt: '49.24px', pl: '36.05px',  pr: '45px', pb: '26.59px'}}>
                            <Typography variant="type_14_500_414141" sx={{mb: '14.76px', display: 'inline-block'}}>
                                Our Leader
                            </Typography>
                            <Divider sx={{width: '54%', height: '1px', background: '#D3D7DE', border: 'unset', mb: '17px'}} />
                            <Typography variant="type_32_600_414141" sx={{mb: '8px', display: 'block'}}>
                                Shivek Agarwal
                            </Typography>
                            <Typography variant="type_18_600_414141" sx={{mb: '27.94px', display: 'block'}}>
                                CEO
                            </Typography>
                            <Typography variant="type_18_600_414141" sx={{mb: '17.06px', display: 'block'}}>
                                Founders Vision
                            </Typography>
                            <Box sx={{minHeight: '162px', maxWidth: {xs: '100%', md: '76%', lg: '86%'}}}>
                                <Typography variant="type_14_500_414141" sx={{mb: '17px', display: 'block'}}>
                                    Agprop is a dynamic institution that, despite its youth, has garnered the loyalty and satisfaction of 
                                    numerous brands and clients. We serve a diverse clientele, including corporates, brands, organizations, 
                                    and small businesses seeking to expand their operations. From leasing and buying commercial real 
                                    estate to monitoring asset sales, Agprop comprehensively meets your needs.
                                </Typography>
                                <Typography variant="type_14_500_414141">
                                    Our unique solutions stem from in-depth ground surveys complemented by robust secondary research. 
                                    Our multidisciplinary team collaborates to ensure all your needs are met, from detailed client 
                                    consultations to building critical recommendations through data-driven research.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4.1} sx={{ order: { xs: 1, md: 2 }, display: 'flex', alignItems: 'end', justifyContent: {xs: 'flex-start', md: 'flex-end'}, overflow: 'hidden'}}>
                        {loadingStates.isShivekProfilePicLoading && (
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                    borderRadius: '32.94px 0',
                                    width: '411px',
                                    height: '353px',
                                }}
                            />
                        )}
                        <Box sx={{position: 'relative'}}>
                            <Box
                                component="img"
                                src={shivekProfilePic}
                                alt="shivekProfilePic"
                                onLoad={() => setLoadingState('isShivekProfilePicLoading', false)}
                                sx={{
                                    width: '100%',
                                    maxWidth: 411,
                                    maxHeight: 353,
                                    height: '100%',
                                    display: loadingStates.isShivekProfilePicLoading ? 'none' : 'block',
                                }}
                            />
                            <Link
                                href="https://www.linkedin.com/company/agprop/"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    position: 'absolute',
                                    left: '5px',
                                    bottom: '0px',
                                    
                                }}
                            >
                                <Box
                                    component='img'
                                    src={linkedInIcon}
                                    alt='locationIcon'
                                    sx={{
                                        width: '26px', 
                                        height: '26px',
                                        bgcolor: '#0077B5',
                                        borderRadius: '4px',
                                    }}
                                />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className='aboutWrap' sx={{mb: '78px'}}>
                <Typography
                    variant='type_32_500_404040'
                    sx={{display: 'block', textAlign: 'center', lineHeight: '54px', mb: '24px'}}
                >
                    Our Approach
                </Typography>
                <Grid container spacing={{ xs: 2, lg: 4}}>
                    {ourApproachData?.map((item, index) => (
                        <Grid item xs={12} md={4} key={index} sx={{mb: {xs: '30px', md: '0px'}}}>
                            <Box
                                sx={{
                                    height: '100%',
                                    maxHeight: {xs: '260px', xsl: '240px', sm: '160px', md: '260px', lg: '250px'},
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '36px',
                                    pt: '24px',
                                    pb: {xs: '0px', sm: '24px'},
                                    pl: { xs: '15px', lg: '24px' },
                                    pr: {xs: '20px', lg: '58px'},
                                }}
                            >
                                <Typography
                                    variant="type_32_600_414141"
                                    sx={{ mb: '15px', display: 'inline-block' }}
                                >
                                {item.title}
                                </Typography>
                                <Divider
                                    sx={{
                                        width: '57%',
                                        height: '1px',
                                        background: '#D3D7DE',
                                        border: 'unset',
                                        mb: '15px',
                                    }}
                                />
                                <Typography
                                    variant="type_16_500_414141"
                                    sx={{ mb: '17px', display: 'block', letterSpacing: '-0.02em' }}
                                >
                                    {item.description1}
                                </Typography>
                                <Typography variant="type_16_500_414141" sx={{ display: 'block', letterSpacing: '-0.02em'}}>
                                    {item.description2}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box className='aboutWrap' sx={{mb: '78px'}}>
                <Typography
                    variant='type_32_500_404040'
                    sx={{display: 'block', textAlign: 'center', lineHeight: '54px', mb: '24px'}}
                >
                    Awards & Recognition
                </Typography>
                {loadingStates.isAwardGroupPicLoading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            borderRadius: '32.94px',
                            width: '100%',
                            height: { xs: '942px', sm: '373px', md: '493px', lg: '558px'},
                            mb: '42.51px',
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={awardGroupPic1}
                    alt='awardGroupPic1'
                    onLoad={() => setLoadingState('isAwardGroupPicLoading', false)}
                    sx={{
                        borderRadius: '32.94px',
                        width: '100%',
                        objectFit: 'contain',
                        mb: '42.51px',
                        display: {xs: 'none', sm: loadingStates.isAwardGroupPicLoading ? 'none' : 'block'}
                    }}
                />
                <Box
                    component='img'
                    src={awardGroupPic1Res}
                    alt='awardGroupPic1'
                    sx={{
                        borderRadius: '32.94px',
                        width: '100%',
                        objectFit: 'contain',
                        mb: '42.51px',
                        display: {xs: loadingStates.isAwardGroupPicLoading ? 'none' : 'block', sm: 'none'}
                    }}
                />
                {loadingStates.isAwardGroupPic2Loading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            borderRadius: '32.94px',
                            width: '100%',
                            height: { xs: '166px', xsl: '288px', sm: '442px', md: '565px', lg: '646px'},
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={awardGroupPic2}
                    alt='awardGroupPic2'
                    onLoad={() => setLoadingState('isAwardGroupPic2Loading', false)}
                    sx={{
                        borderRadius: '32.94px',
                        width: '100%',
                        objectFit: 'contain',
                        display: loadingStates.isAwardGroupPic2Loading ? 'none' : 'block',
                    }}
                />
            </Box>
            <SubscribeToNewsletter className={'aboutWrapPadding'}/>
        </StyledAbout>
    )
}
export default About;
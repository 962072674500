import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validationMessages from '../../constants/messages';
import CONSTANTS from '../../constants/common';

const {
    MSG_NAME_REQ,
    MSG_EMAIL_USERNAME_REQ,
    MSG_VALID_EMAIL,
    MSG_PHONE_REQ,
    MSG_VALID_PHONE,
    MSG_EMAIL_LENGTH,
    MSG_FIRST_NAME_REQ,
    MSG_LAST_NAME_REQ,
    MSG_FIRST_NAME_VALID,
    MSG_LAST_NAME_VALID,
    MSG_CITY_REQ,
    MSG_STATE_REQ,
    MSG_PINCODE_REQ,
    MSG_AREA_REQ,
    MSG_SUPER_AREA_REQ,
    MSG_CARPET_AREA_REQ,
    MSG_FRONTAGE_REQ,
    MSG_CELLING_HEIGHT_REQ,
    MSG_FLOOR_REQ,
    MSG_FINANCIAL_REQ,
    MSG_VALID_PINCODE,
    MSG_VALID_MAP_URL,
    MSG_SUBJECT_REQ,
    MSG_MESSAGE_REQ,
    MSG_VALID_NAME,
    MSG_MIN_MESSAGE,
    MSG_MAX_MESSAGE
} = validationMessages;

const {
  REGEX: {
    EMAIL_PATTERN = '',
    NAME_PATTERN = '',
    PINCODE_PATTERN = '',
    MAP_URL_PATTERN = '',
    //PHONE_PATTERN = '',
  },
} = CONSTANTS;

const validationSchemaContactUpForm = yup.object({
  name: yup
    .string()
    .matches(NAME_PATTERN, MSG_VALID_NAME)
    .required(MSG_NAME_REQ),
  email: yup
    .string()
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .required(MSG_EMAIL_USERNAME_REQ)
    .max(254, MSG_EMAIL_LENGTH),
  contactNumber: yup
    .string()
    .test('is-valid-phone', MSG_VALID_PHONE, value => isValidPhoneNumber(value || ''))
    .required(MSG_PHONE_REQ),
  subject: yup
    .string()
    .required(MSG_SUBJECT_REQ),
  message: yup
    .string()
    .min(20, MSG_MIN_MESSAGE)
    .max(1000, MSG_MAX_MESSAGE)
    .required(MSG_MESSAGE_REQ),
});

const validationSchemaPropertyForm = yup.object({
  firstName: yup
    .string()
    .matches(NAME_PATTERN, MSG_FIRST_NAME_VALID)
    .required(MSG_FIRST_NAME_REQ),
  lastName: yup
    .string()
    .matches(NAME_PATTERN, MSG_LAST_NAME_VALID)
    .required(MSG_LAST_NAME_REQ),
  email: yup
    .string()
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .required(MSG_EMAIL_USERNAME_REQ)
    .max(254, MSG_EMAIL_LENGTH),
  city: yup
    .string()
    .required(MSG_CITY_REQ),
  state: yup
    .string()
    .required(MSG_STATE_REQ),
  pincode: yup
    .string()
    .matches(PINCODE_PATTERN, MSG_VALID_PINCODE)
    .required( MSG_PINCODE_REQ),
  contactNumber: yup
    .string()
    .test('is-valid-phone', MSG_VALID_PHONE, value => isValidPhoneNumber(value || ''))
    .required(MSG_PHONE_REQ),
  area: yup
    .string()
    .required(MSG_AREA_REQ),
  superArea: yup
    .string()
    .required(MSG_SUPER_AREA_REQ),
  carpetArea: yup
    .string()
    .required(MSG_CARPET_AREA_REQ),
  frontage: yup
    .string()
    .required(MSG_FRONTAGE_REQ),
  cellingHeight: yup
    .string()
    .required(MSG_CELLING_HEIGHT_REQ),
  floor: yup
    .string()
    .required(MSG_FLOOR_REQ),
  financial: yup
    .string()
    .required(MSG_FINANCIAL_REQ),
  googleMapsUrl: yup
    .string()
    .matches(MAP_URL_PATTERN, MSG_VALID_MAP_URL)
});
const validationSchemaSubscribeField = yup.object({
  email: yup
  .string()
  .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
  .max(254, MSG_EMAIL_LENGTH),
})

export { validationSchemaContactUpForm, validationSchemaSubscribeField, validationSchemaPropertyForm };

import { styled } from '@mui/material/styles';

const StyledDashboard = styled('div')(
  ({ theme }) => `

    .dashboardSliderWrap .slick-slide {
      width: 100% !important;
      box-sizing: border-box;
    }

    .dashboardSliderWrap .slick-track {
      display: flex;
    }

    .dashboardSliderWrap .slick-slide > div {
      padding-top: 1px;
      padding-bottom: 1px;
      margin-left: 10px;
      margin-right: 10px;
      max-width: 259px;
      display: flex;
      justify-content: space-between;
    }

    .dashboardSliderWrap .slick-dots {
      height: 10px;
      display: flex !important;
      justify-content: center;
      bottom: -42px;
    }

    .dashboardSliderWrap .slick-dots .slick-active {
      background-color: #414141;
      opacity: 100%;
      height: 10px;
      width: 20px;
      color: black;
      margin: 0 !important;
      border-radius: 6px;
    }

    .dashboardSliderWrap .slick-dots li {
      width: 10px;
    }

    .previewImageWrap{
      background-color: #414141;
      opacity: 0.5;
      height: 10px;
      width: 10px;
      border-radius: 30px;
    }

    .dashboardSliderWrap .center .slick-disabled {
      opacity: 0.3;
    }
      
    margin-bottom: 3.563;

    .dashboardSectionWrap{
      margin-left: 7.5rem;
      margin-right: 7.5rem;
    }

    .dashboardSectionWrapPadding{
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    }
    
    @media (min-width: ${theme.breakpoints.values.xs}px) {
      .dashboardSectionWrap{
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
      
      .dashboardSectionWrapPadding{
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }

      .slick-next:before{
        display: none;
      }

      .slick-prev:before{
        display: none;
      }
    }

    @media (min-width: ${theme.breakpoints.values.xsl}px) {
      .dashboardSectionWrap{
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .dashboardSectionWrapPadding{
        padding-left: 1rem;
        padding-right: 1rem;
      }
      
      .dashboardSliderWrap .slick-slide > div {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      .dashboardSectionWrap{
        margin-left: 2rem;
        margin-right: 2rem;
      }
        
      .dashboardSliderWrap .slick-slide > div {
        margin-left: 10px;
        margin-right: 10px;
      }
        
      .dashboardSectionWrapPadding{
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      .dashboardSectionWrap{
        margin-left: 4rem;
        margin-right: 4rem;
      }

      .dashboardSectionWrapPadding{
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      .dashboardSectionWrap{
        margin-left: 7.2rem;
        margin-right: 7.2rem;
      }
        
      .dashboardSectionWrapPadding{
        padding-left: 7.2rem;
        padding-right: 7.2rem;
      }
    }
    @media (min-width: ${theme.breakpoints.values.lxs}px) {
      .dashboardSliderWrap .slick-slide {
        display: flex;
        justify-content: center;
      }
      
      .dashboardSliderWrap .slick-slide > div {
        width: 100%;
        max-width: 100%;
      }
    }
  `,
);

export default StyledDashboard;

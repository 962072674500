import { Box, Button, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import propvisionLaptopMobileScreen from '../../../assets/png/propvisionLaptopMobileScreen.png';
import propmagnetLaptopMobileScreen from '../../../assets/png/propmagnetLaptopMobileScreen.png';
import propvisionLogoWithText from '../../../assets/svg/propvisionLogoWithText.svg';
import propmagnetLogoWithText from '../../../assets/svg/propmagnetLogoWithText.svg';
import appStoreButton from '../../../assets/svg/appStoreButton.svg';
import googlePlayButton from '../../../assets/svg/googlePlayButton.svg';
import manCharMsg from '../../../assets/png/manCharMsg.png';
import manCharMsgmagnet from '../../../assets/png/manCharMsgMagnet.png';

const ExploreApplicationsSection = ({type, className='propvisionSectionWrapPadding'}) => {
    //const navigate = useNavigate();
    const [loadingStates, setLoadingStates] = useState({
        isLaptopMobileScreenLoading: true,
        isCharMsgLoading: true,
    });

    const setLoadingState = (key, value) => {
        setLoadingStates(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    return (
        <Box
            className={className}
            sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                pt: '60px',
                pb: '68px'
            }}
        >
            <Typography
                variant='type_36_500_404040'
                sx={{
                        lineHeight: '54px',
                        fontSize: { xs: '30px', sm: '36px'},
                        display: { xs: 'none', sm: 'block'}
                    }}
                >
                Explore our applications
            </Typography>
            <Typography
                variant='type_36_500_404040'
                sx={{ 
                    textAlign: 'center',
                    mb: '33px',
                    lineHeight: '54px',
                    fontSize: { xs: '30px', sm: '36px'},
                    display: { xs: 'none', sm: 'block'}
                }}
            >
                Your Tech-Powered Property Investment Partner
            </Typography>
            <Typography
                variant='type_36_500_404040'
                sx={{ 
                    textAlign: 'center',
                    mb: '33px',
                    lineHeight: '54px',
                    fontSize: { xs: '30px', sm: '36px'},
                    display: { xs: 'block', sm: 'none'}
                }}
            >
                Explore our applications Your Tech-Powered Property Investment Partner
            </Typography>
            <Box
                component='img'
                src={type === 'magnet' ? propmagnetLogoWithText : propvisionLogoWithText }
                alt={type === 'magnet' ? 'propmagnetLogoWithText' : 'propvisionLogoWithText' }
                sx={{
                    maxWidth: '248px',
                    objectFit: 'contain',
                    pb: '16px',
                }}
            />
            <Typography
                variant='type_16_500_414141'
                sx={{mb: '8px', maxWidth: '620px', textAlign: 'center', lineHeight: '24px'}}
            >
                { 
                    type === 'magnet' ?
                        `Propmagnet is specifically desgined for Brokers to seamlessly customize and match 
                        properties according to specific requirements. Enhance efficiency in managing 
                        client requirements and generating leads resulting in maximize deal closures.` 
                    : 
                        `Propvision is a powerful property management tool revolutionizes the real estate 
                        expansion experience. The tool is designed to offer personalized solutions for 
                        buyers and leasers alike. Receive tailored recommendations, connect with sellers, 
                        brokers, and make informed choices effortlessly. Experience the future of real estate 
                        with Propvision! `
                } 
            </Typography>
            <Divider sx={{ mb: '8px', width: '100%', maxWidth: '307px', border: '1px solid #D3D7DE !important'}}/>
            <Typography sx={{mb: '9.7px'}} variant="type_12_600_424242">Download Now</Typography>
            <Grid item container spacing={1} justifyContent={'center'} sx={{mb: '58.56px'}}>
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                        href={type === 'magnet' ? 'https://apps.apple.com/us/app/propmagnet/id6502830546' : 'https://apps.apple.com/app/propvision/id6502831428'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Box
                            component="img"
                            src={appStoreButton}
                            alt="appStoreButton"
                            sx={{
                                width: '66px',
                            }}
                        />
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="contained"
                        sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                        href={type === 'magnet' ? 'https://play.google.com/store/apps/details?id=com.broker.app.broker_mobile_app' : '#'}
                        target={type === 'magnet' ? '_blank' : '_self'}
                        rel="noopener noreferrer"
                    >
                        <Box
                            component="img"
                            src={googlePlayButton}
                            alt="googlePlayButton"
                            sx={{
                                width: '66px',
                            }}
                        />
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{mb: '68px'}} position="relative" display="inline-block">
                {loadingStates.isLaptopMobileScreenLoading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            width: '100vw',
                            maxWidth: '994.71px',
                            height: { xs: '124px', xsl: '221px', sm: '343px', md: '403px', lg: '413px'},
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={ type === 'magnet' ? propmagnetLaptopMobileScreen : propvisionLaptopMobileScreen}
                    alt='propvisionLaptopMobileScreen'
                    onLoad={() => setLoadingState('isLaptopMobileScreenLoading', false)}
                    sx={{
                        maxWidth: '994.71px',
                        width: '100%',
                        objectFit: 'contain',
                        display: loadingStates.isLaptopMobileScreenLoading ? 'none' : 'block',
                    }}
                />
                <Box
                    position="absolute"
                    bottom={{xs: '-45px', xsl: '-40px', sm: '20px'}}
                    textAlign="center"
                    sx={{width: '100%'}}
                >
                    <Typography
                        variant="type_12_500_707070"
                        sx={{
                            display: 'inline-block',
                            width: '100%',
                            maxWidth: '496px',
                        }}
                    >
                        {
                            type === 'magnet' ? 
                            '*Some features may differ based on your plan and administrative controls. Know the right subscription plan for your needs. '
                            :
                            '*These tools are sold separately. Some features may differ based on your plan and administrative controls. '
                        }
                    </Typography>
                </Box>
            </Box>
            <Grid container>
                <Grid item xs={12} md={6} lg={8.1} sx={{mb: { xs: '30px', md: '0px'}, display: 'flex', justifyContent: 'center' }}>
                    {loadingStates.isCharMsgLoading && (
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            sx={{
                                borderRadius: '32.94px',
                                maxWidth: '814.47px',
                                width: '100%',
                                height: { xs: '203px', xsl: '248px', sm: '507px', md: '433px', lg: '535px'},
                            }}
                        />
                    )}
                    <Box
                        component='img'
                        src={type === 'magnet' ? manCharMsgmagnet : manCharMsg}
                        alt='manCharMsg'
                        onLoad={() => setLoadingState('isCharMsgLoading', false)}
                        sx={{
                            borderRadius: '32.94px',
                            maxWidth: '814.47px',
                            width: '100%',
                            objectFit: 'contain',
                            display: loadingStates.isCharMsgLoading ? 'none' : 'block',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3.9}
                    sx={{
                        pl: {xs: '0px', md: '14.22px'}, 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#D7EDFF',
                            pt: '61px',
                            pl: '30.3px',
                            pr: '31px',
                            pb: '30px',
                            borderRadius: '32.94px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: type === 'magnet' ? 'center' : 'flex-start'
                        }}
                    >
                        <Typography
                            variant="type_24_500_414141"
                            align="left"
                            sx={{lineHeight: '36px', mb: '25px'}}
                        >
                            {
                                type === 'magnet' ?
                                'Enhance efficiency in managing client requirements and generating leads resulting in maximize deal closures.' 
                                : 
                                'Receive tailored recommendations, connect with sellers, brokers, and make informed choices effortlessly.'
                            }
                        </Typography>
                        {
                            type === 'magnet' ? 
                            ''
                            :
                            <Typography
                                variant="type_24_500_414141"
                                align="left"
                                sx={{lineHeight: '36px', mb: '26px'}}
                            >
                                Experience the future of real estate with Propvision!
                                
                            </Typography>
                        }
                        <Box textAlign="start" display="flex" alignItems={'center'}>
                            <Button
                                variant="contained"
                                sx={{
                                    background: '#0048D0',
                                    py: '7px',
                                    px: '15px',
                                    borderRadius: '2rem',
                                    textTransform: 'none',
                                    lineHeight: 'unset'
                                }}
                                href={type === 'magnet' ? process.env.REACT_APP_PROPMAGNET_URL : process.env.REACT_APP_PROPVISION_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Typography variant="type_12_500_FFFFFF">
                                    Explore more
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>  
    )
}

export default ExploreApplicationsSection;
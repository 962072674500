import {
    Box,
    Typography,
    Divider,
    Button,
    Grid,
  } from '@mui/material';
import laptopMobileScreen from '../../../assets/png/laptopMobileScreen.png';
import appStoreButton from '../../../assets/svg/appStoreButton.svg';
import googlePlayButton from '../../../assets/svg/googlePlayButton.svg';
import agprop from '../../../assets/svg/agprop.svg';
import registeredLogo from '../../../assets/svg/registeredLogo.svg';
import northEastArrow from '../../../assets/svg/northEastArrow.svg';
import arrowBackground from '../../../assets/svg/arrowBackground.svg';

const TechnologyDriven = () => {
    return (
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        alignItems="center"
        sx={{background: '#FFFFFF', paddingTop: '32px'}}
      >
        <Box
          sx={{
            position: 'relative',
            textAlign: 'center',
            width: '100%',
            marginBottom: '56px'
          }}
        >
          <Box
            component="img"
            src={laptopMobileScreen}
            alt="propvisionLogoWithText"
            sx={{
              position: 'relative',
              zIndex: 2,
              width: { xs: '65%', md: '51.60%'},
              objectFit: 'contain',
            }}
          />
          <Box
            sx={{
              opacity: '99%',
              zIndex: 1,
              borderRadius: '20.44px 20.44px 229.95px 229.95px ',
              position: 'absolute',
              mx: { xs: '2%', md: '6.1%'},
              top: '50%',
              left: '8%',
              width: { xs: '80%', md: '70.97%'},
              height: '50%',
              backgroundColor: '#DFE5F0',
            }}
          />
        </Box>
        <Typography textAlign={'center'} variant="type_16_500_414141" mb='8px' className='dashboardSectionWrapPadding'>
          Introduction our powerful management tools
        </Typography>
        <Typography textAlign={'center'} variant="type_24_600_414141" mb='48px' className='dashboardSectionWrapPadding'>
          Technology Driven Platforms for all your needs
        </Typography>
        <Box className='dashboardSectionWrapPadding'>
          <Grid container spacing={5} sx={{pb: '56px'}}>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Grid container sx={{
                borderRadius: '16px',
                backgroundColor: '#F3F7FF',
                padding: '28px'
              }}>
                <Grid
                  item
                  xs={9.5}
                  sm={7.7}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Box
                        component="img"
                        src={agprop}
                        alt="agprop"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 51.31,
                        }}
                      />
                    </Grid>
                    <Grid item sx={{pt: '0px !important'}}>
                      <Typography variant="type_24_600_424242" sx={{position: 'relative', display: 'inline-block'}}>
                        Propvision 
                        <Box
                          component="img"
                          src={registeredLogo}
                          alt="registeredLogo"
                          sx={{
                            position: 'absolute',
                            top: '5px',
                            right: '-10px',
                            width: '9.01px',
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sx={{minHeight: { xs: 'unset', md: '118.4px', lg: '100px'}}}>
                      <Typography variant="type_14_500_414141">
                        Streamline your buying/leasing process, notify sellers and brokers, and effectively make informed decisions.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider sx={{width: '70%', border: '1px solid #D3D7DE !important'}}/>
                    </Grid>
                    <Grid item>
                      <Typography variant="type_12_600_424242">Download Now</Typography>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                          href={'https://apps.apple.com/app/propvision/id6502831428'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box
                            component="img"
                            src={appStoreButton}
                            alt="appStoreButton"
                            sx={{
                              width: '66px',
                            }}
                          />
                        </Button>
                      </Grid>
                      <Grid item >
                        <Button
                          variant="contained"
                          sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                        //   href={'#'}
                        //   target={'_self'}
                        //   rel="noopener noreferrer"
                        >
                          <Box
                            component="img"
                            src={googlePlayButton}
                            alt="googlePlayButton"
                            sx={{
                              width: '66px',
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sm={4.3}
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end'
                  }}
                >
                  <Grid item>
                    <Button
                      variant="standard"
                      href={'https://propvision.agprop.in/'}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      sx={{
                        backgroundImage: `url(${arrowBackground})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '52px',
                        height: '52px',
                        minWidth: 'unset'
                      }}
                    >
                      <Box
                        component="img"
                        src={northEastArrow}
                        alt="northEastArrow"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '14.59px',
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Grid
                container
                sx={{
                  borderRadius: '16px',
                  backgroundColor: '#F3F7FF',
                  padding: '28px'
                }}
              >
                <Grid
                  item
                  xs={10}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Box
                        component="img"
                        src={agprop}
                        alt="agprop"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 51.31,
                        }}
                      />
                    </Grid>
                    <Grid item sx={{pt: '0px !important'}}>
                      <Typography variant="type_24_600_424242" sx={{position: 'relative', display: 'inline-block'}}>
                        Propmagnet
                        <Box
                          component="img"
                          src={registeredLogo}
                          alt="registeredLogo"
                          sx={{
                            position: 'absolute',
                            top: '5px',
                            right: '-10px',
                            width: '9.01px',
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item sx={{minHeight: { xs: 'unset', md: '118.4px', lg: '100px'}}}>
                      <Typography variant="type_14_500_414141">
                        Desgined for Brokers to seamlessly customize and match properties according to specific requirements. Enhance efficiency in managing client requirements and generating leads resulting in maximize deal closures.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider sx={{width: '70%', border: '1px solid #D3D7DE !important'}}/>
                    </Grid>
                    <Grid item>
                      <Typography variant="type_12_600_424242">Download Now</Typography>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                          href={'https://apps.apple.com/us/app/propmagnet/id6502830546'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Box
                            component="img"
                            src={appStoreButton}
                            alt="appStoreButton"
                            sx={{
                              width: '66px',
                            }}
                          />
                        </Button>
                      </Grid>
                      <Grid item >
                        <Button
                          variant="contained"
                          sx={{backgroundColor: '#202226', padding: '4.3px 9.6px'}}
                          href={'https://play.google.com/store/apps/details?id=com.broker.app.broker_mobile_app'}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <Box
                            component="img"
                            src={googlePlayButton}
                            alt="googlePlayButton"
                            sx={{
                              width: '66px',
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} 
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end'
                  }}
                >
                  <Grid item>
                    <Button
                      variant="standard"
                      href={'https://propmagnet.agprop.in/'}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      sx={{
                        backgroundImage: `url(${arrowBackground})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '52px',
                        height: '52px',
                        minWidth: 'unset'
                      }}
                    >
                      <Box
                        component="img"
                        src={northEastArrow}
                        alt="northEastArrow"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '14.59px',
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ marginBottom: '32px'}} display="flex" justifyContent="center" textAlign={'center'}>
            <Typography variant='type_12_500_707070'  width={{ xs: '100%', sm: '496px'}}>
              *These tools are sold separately.  
              Some Features may differ based upon your 
              plan and administrative controls.
            </Typography>
          </Box>
        </Box>
      </Box>
    )
}

export default TechnologyDriven;
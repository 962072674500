import {
  Box,
  Typography,
  Button,
  ListItemIcon,
  Skeleton,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CallMadeIcon from '@mui/icons-material/CallMade';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import StyledDashboard from './StyledDashboard';
import ReviewSlider from '../../components/ReviewSlider';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import heroSection from '../../assets/jpeg/heroSection.jpg';
import ScrollToTop from '../../components/ScrollToTop';
import DashboardSlider from '../../components/DashboardComp/DashboardSlider';
import ExploreOurProducts from '../../components/DashboardComp/ExploreOurProducts';
import TechnologyDriven from '../../components/DashboardComp/TechnologyDriven';
import { updateCurrentPage } from '../../store/reducers/loader';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isHeroSectionLoading, setIsHeroSectionLoading] = useState(true);

  const handleNavigate = (navType) => {
    switch (navType) {
      case 'home':
        navigate('/')
        break;
      case 'brand_req_list':
        navigate('/brand-requirement-list')
        break;
      case 'propvision':
        navigate('/propvision')
        break;
      case 'propmagnet':
        navigate('/propmagnet')
        break;
      case 'requirements':
        navigate('/brand-requirement-list')
        break;
      case 'about':
        navigate('/about')
        break;
      default:
        console.log('Invalid navigation type');
    }
  };
  
  return (
    <StyledDashboard>
      <ScrollToTop/>
      <Box
        className='dashboardSectionWrap'
        sx={{
          mt: { xs: '95px', md: '120px', lg: '168px'},
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isHeroSectionLoading && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              width: '100%',
              height: {xs: '500px', md: '500px', lg: '718px'},
            }}
          />
        )}
        <Box
          component="img"
          src={heroSection}
          alt="heroSection"
          onLoad={() => setIsHeroSectionLoading(false)}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
            height: { xs: '55%', sm: '40%', md: 'unset' },
            objectFit: 'fill',
            display: isHeroSectionLoading ? 'none' : 'block',
            filter: 'brightness(86%)',
          }}
        />

        <Typography
          variant='type_72_500_FFFFFF'
          sx={{
            textAlign: 'center',
            fontSize: {xs: '20px', sm: '25px', md: '40px', lg: '72px'},
            mb: {xs: '15px', sm: '10px', md: '12px', lg: '16px'},
            mt: { xs: '30px', md: 'unset'},
            lineHeight: { xs: '30px', sm: '35px', md: '60px', lg: '93.6px'}
          }}
        >
          Empowering Brokers and Retailers through AI-Driven Solutions
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: { xs: '18px', sm: '15px', md: '20px', lg: '28px'},
            lineHeight: {xs: '25px', sm: '25px', md: '30px', lg: '42px'},
            
            mb: {xs: '25px', sm: '10px', md: '15px', lg: '21px'}
          }}
          variant='type_28_500_FFFFFF'
        >
          At Agprop, we integrate advanced technology to streamline real estate transactions, providing brokers and retailers with innovative tools for efficient decision-making and growth. 
        </Typography>
        <Box
          display='flex'
          alignItems={'center'}
          mb={{xs: '28px', sm: '22px', md: '40px', lg: '52px'}}
        >
          <Button
            variant='contained'
            sx={{
              background: '#FFFFFF',
              py: '10px',
              px: '27px',
              borderRadius: '3rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(256, 256, 256, 0.9)',
              },
            }}
            onClick={() => handleNavigate('requirements')}
          >
            <Typography variant='type_14_500_0048D0' sx={{lineHeight: '16.94px'}}>
              Explore Requirements
            </Typography>
          </Button>
          <Tooltip title="About" arrow>
            <ListItemIcon
              onClick={() => handleNavigate('about')}
              sx={{
                cursor: 'pointer',
                marginLeft: '6px',
                ml: '6px',
                borderRadius: '100%',
                minWidth: '38px',
                height: '38px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#FFFFFF'
              }}
            >
              <CallMadeIcon
                sx={{width: '14.22px', height: '14.22px', color: '#0048D0'}}
              />
            </ListItemIcon>
          </Tooltip>
        </Box>
      </Box>
      <Box
        className='dashboardSectionWrap'
        sx={{
          backgroundColor: (theme) => theme.palette.customColor.neutralWhite,
          borderRadius: '16px',
          pt: {xs: '15px', sm: '25px', md: '23px', lg: '36px'},
          px: { xs: '15px', sm: '30px', md: '23px',  lg: '44px'},
          pb: { xs: '65px',  md: '65px', lg: '79px'},
          mb: '55.11px'
        }}
      >
        <Box sx={{ mb: '36px', display: 'flex', flexDirection: { xs: 'column', xsl: 'row'}, justifyContent: 'space-between'}}>
          <Typography variant='type_28_600_414141' sx={{textAlign: {xs: 'center', xsl: 'left'}}}>
            Top Brands
          </Typography>
          <Button
            onClick={() => {handleNavigate('brand_req_list'); dispatch(updateCurrentPage(1));}}
            variant='text' 
            endIcon={<EastIcon />} 
            sx={{
              textDecoration: 'underline',
              color: '#0048D0',
              textUnderlineOffset: '3px',
              textTransform: 'none'
            }}
          >
            <Typography variant='type_16_600_0048D0'>
              View All
            </Typography>
          </Button>
        </Box>
        <Box className={'dashboardSliderWrap'} sx={{width: '100%', position: 'relative'}}>
          <DashboardSlider />
        </Box>
      </Box>
      <ExploreOurProducts />
      <TechnologyDriven />
      <Box className='dashboardSectionWrap' sx={{my: '80px'}}>
        <ReviewSlider />
      </Box>
      <SubscribeToNewsletter className={'dashboardSectionWrapPadding'}/>
    </StyledDashboard>
  );
};

export default Dashboard;

// src/components/Pagination.js
import React from 'react';
import { Pagination as MUIPagination, Stack } from '@mui/material';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <Stack direction="row" justifyContent="center" sx={{ mt: 2, position: 'absolute', width: '100%', right: 0}}>
      <MUIPagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        shape="rounded"
        sx={{
          '& .Mui-selected': {
            backgroundColor: '#0048D0 !important',
            opacity: '0.9 !important',
            color: 'white',
            borderRadius: '8px !important',
            '&:hover': {
              backgroundColor: '#0048D0 !important',
              opacity: '1 !important',
            }
          }
        }}
      />
    </Stack>
  );
};

export default Pagination;

import { styled } from '@mui/material/styles';

const StyledBrandRequirementList = styled('div')(
  ({ theme }) => `

    .brandRequirementListWrap{
      margin-left: 7.5rem;
      margin-right: 7.5rem;
    }
    
    .brandRequirementListWrapPadding{
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    }
    
    @media (min-width: ${theme.breakpoints.values.xs}px) {
      .brandRequirementListWrap{
        margin-left: 0.6rem;
        margin-right: 0.6rem;
      }
      
      .brandRequirementListWrapPadding{
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }

      .slick-next:before{
        display: none;
      }

      .slick-prev:before{
        display: none;
      }
    }

    @media (min-width: ${theme.breakpoints.values.xsl}px) {
      .brandRequirementListWrap{
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .brandRequirementListWrapPadding{
        padding-left: 1rem;
        padding-right: 1rem;
      }
      
      .slick-slide > div {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      .brandRequirementListWrap{
        margin-left: 2rem;
        margin-right: 2rem;
      }
        
      .slick-slide > div {
        margin-left: 10px;
        margin-right: 10px;
      }
        
      .brandRequirementListWrapPadding{
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      .brandRequirementListWrap{
        margin-left: 4rem;
        margin-right: 4rem;
      }

      .brandRequirementListWrapPadding{
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      .brandRequirementListWrap{
        margin-left: 7.2rem;
        margin-right: 7.2rem;
      }
        
      .brandRequirementListWrapPadding{
        padding-left: 7.2rem;
        padding-right: 7.2rem;
      }
    }
  `,
);

export default StyledBrandRequirementList;

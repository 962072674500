import { Box, Card, CardContent, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import RealEstateSection from '../../components/commonComp/RealEstateSection';
import ExploreApplicationsSection from '../../components/commonComp/ExploreApplicationsSection';
import StyledPropmagnet from './StyledPropmagnet';
import ExperienceFuture from '../../components/commonComp/ExperienceFuture';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import CONSTANTS from '../../constants/common';

const {propmagnetCardsData, propmagnetSmallCardsData} = CONSTANTS;

const Propmagnet = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <StyledPropmagnet>
            <Box
                sx={{
                    mt: { xs: '95px', md: '120px', lg: '159px'},
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <RealEstateSection type={'magnet'}/>
                <ExploreApplicationsSection type={'magnet'} className={'propmagnetSectionWrapPadding'}/>
                <Box className={'propmagnetSectionWrapPadding'} sx={{ pb: '149px', textAlign: 'center', backgroundColor: '#FFFFFF'}}>
                    <Typography variant={'type_36_500_404040'}>
                        Our Features
                    </Typography>
                    <Grid sx={{mt: '32px', mb: '16px'}} container spacing={'16px'} justifyContent="center" alignItems="center">
                        {propmagnetCardsData.map((card, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Card
                                    sx={{
                                        borderRadius: {xs: '44px', md: '32px'},
                                        backgroundColor: '#F3F7FF',
                                        pl: { xs: '25px', sm: '35px', md: '42.65px'},
                                        pt: { xs: '30px', sm: '35px', md: '49.39px'},
                                    }}
                                >
                                    <CardContent sx={{ padding: '0px', pr: '10px', display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                        <Typography variant="type_16_500_414141" sx={{mb: '9.61px'}}>
                                            {card.title}
                                        </Typography>
                                        <Typography variant="type_28_500_414141_POPPINS" sx={{ pr: {xs: '20px'}, minHeight: { xs: '40px', md: '80px'}}}>
                                            {card.description1}
                                        </Typography>
                                        <Typography variant="type_12_500_414141" sx={{mb: '36.48px', pr: {xs: '20px'}, minHeight: '57px'}}>
                                            {card.description2}
                                        </Typography>
                                    </CardContent>
                                    {isLoading && (
                                        <Skeleton
                                            animation="wave"
                                            variant="rectangular"
                                            sx={{
                                                
                                                height: { xs: '127px', xsl: '237px', sm: '153px', md: '214px', lg: '251px'},
                                                borderRadius: '32.93px 0'
                                            }}
                                        />
                                    )}
                                    <CardMedia
                                        component="img"
                                        image={card.image}
                                        alt="Card image"
                                        onLoad={() => setIsLoading(false)}
                                        sx={{display: isLoading ? 'none' : 'block'}}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container>
                        {propmagnetSmallCardsData?.map((cardContent, index) => (
                            <Grid
                                item
                                xs={12}
                                md={index > 2 ? 6 : 4}
                                key={`firstRow-${index}`}
                                sx= {{
                                    px: index === 1 ? '11.18px' : '',
                                    pl: index === 4 ? { xs: '0', md: '8.2px'} : index === 1 ? { xs: '0', md: '11.18px'} : '',
                                    pr: index === 3 ? {xs: '0', md: '8.2px'} : index === 1 ? { xs: '0', md: '11.18px'} : '',

                                }}
                            >
                                    <Box
                                        sx={{
                                            backgroundColor: '#F3F7FF',
                                            borderRadius: '32.79px',
                                            pl: { xs: '20px', lg: '33.53px'},
                                            pt: '43.22px',
                                            pr: { xs: '30px', lg: '51.86px'},
                                            minHeight: '156.48px',
                                            mb: { xs: '12px', md: index < 2 ? '8.94px' : ''},
                                            position: 'relative',
                                        }}
                                    >
                                        <Box mb={2} sx={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                            <Typography variant="type_28_500_414141_POPPINS">{cardContent.title1}</Typography>
                                            <Typography variant="type_28_500_414141_POPPINS" sx={{mb: '7.3px'}}>{cardContent.title2}</Typography>
                                            <Typography variant="type_12_500_414141" sx={{maxWidth: { xs: '70%', lg: '280px'}}}>{cardContent.description}</Typography>
                                        </Box>
                                        { index === 4 ? 
                                            <Box
                                                sx={{
                                                    backgroundColor: '#DBFD91',
                                                    width: '93.14px',
                                                    height: '165.42px',
                                                    position: 'absolute',
                                                    right: 0,
                                                    bottom: 0,
                                                    borderTopLeftRadius: '32.79px', 
                                                    borderBottomRightRadius: '32.79px'
                                                }}
                                            >
                                            </Box>
                                            :
                                            ''
                                        }
                                    </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <ExperienceFuture type={'magnet'} className={'propmagnetSectionWrapPadding'}/>
                <SubscribeToNewsletter className={'propmagnetSectionWrapPadding'}/>
            </Box>
        </StyledPropmagnet>
    )
}

export default Propmagnet;
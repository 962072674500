import React from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StyledFooter from './StyledFooter';
import AgPropImage from '../../assets/svg/agpropFooterLogo.svg';
import whatsApp from '../../assets/svg/whatsApp.svg';
import locationIcon from '../../assets/svg/locationIcon.svg';
import emailIcon from '../../assets/svg/emailIcon.svg';
import linkedInIcon from '../../assets/svg/linkedInIcon.svg';

const Footer = () => {
    const navigate = useNavigate();
    const handleNavigate = (route) => {
        switch (route) {
            case 'brand_requirement':
                navigate('/brand-requirement-list')
                break;
            case 'about':
                navigate('/about')
                break;
            case 'contact':
                navigate('/contact-us')
                break;
            case 'terms_conditions':
                navigate('/terms-conditions')
                break;
            case 'privacy_policy':
                navigate('/privacy-policy')
                break;
            default:
                navigate('/')
                break;
        }
      };      
    return (
        <StyledFooter>
            <Box
                sx={{
                    px: { xs: '2rem',  sm: '2.3rem', md: '4rem', lg: '7.2rem'},
                    py: { xs: '1.8rem', sm: '1.5rem', md: '2rem', lg: '3.409rem'},
                    backgroundColor: '#262626'
                }}
            >
                <Grid container spacing={2}>
                    {/* First Column */}
                    <Grid item xs={12} xsl={12} sm={6} md={4.5} lg={3.2} xl={3.2}>
                        <Grid container direction="column" spacing={1} >
                            <Grid item>
                                <img src={AgPropImage} alt="agprop" />
                            </Grid>
                            <Grid item>
                                <Typography variant="type_14_500_FFFFFF">
                                    Helping You Make the Right Move
                                </Typography>
                            </Grid>
                            <Grid item sx={{paddingTop: '0  !important'}}>
                                <Typography variant="body1"  style={{ visibility: 'hidden'}}>
                                    blank
                                </Typography>
                            </Grid>
                            <Grid item sx={{ gap: '8px', display: 'flex', alignItems: 'center', paddingTop: '0  !important'}}>
                                <Box
                                    component='img'
                                    src={whatsApp}
                                    alt='whatsApp'
                                    sx={{
                                        width: '24px', 
                                        height: '24px'
                                    }}
                                />
                                <Typography variant="type_14_500_FFFFFF">
                                    <a
                                        style={{textDecoration: 'none', color: '#FFFFFF'}}
                                        href="https://wa.me/917070707679"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-link"
                                    >
                                        +91 7070707679
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ gap: '8px', display: 'flex', alignItems: 'center'}}>
                                <Box
                                    component='img'
                                    src={emailIcon}
                                    alt='emailIcon'
                                    sx={{
                                        width: '24px', 
                                        height: '24px'
                                    }}
                                />
                                <Typography variant="type_14_500_FFFFFF">
                                    <a
                                        href="mailto:info@agprop.in"
                                        style={{ textDecoration: 'none', color: '#FFFFFF' }}
                                        className="footer-link"
                                    >
                                        info@agprop.in
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ gap: '8px', display: 'flex', alignItems: 'start'}}>
                                <Box
                                    component='img'
                                    src={locationIcon}
                                    alt='locationIcon'
                                    sx={{
                                        width: '24px', 
                                        height: '24px'
                                    }}
                                />
                                <Typography variant="type_14_500_FFFFFF">
                                    Second floor, MM Tower, Passport Seva Kendra, 
                                    Sub. Major Laxmi Chand Rd, Sector 18, Gurugram, 
                                    Shahpur, Haryana 122022
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={12} xsl={12} sm={2.8} md={2.8} lg={2.4} xl={2.4}>
                        <Grid container direction="column" spacing={1} >
                            <Grid item>
                                <Typography variant="type_16_700_FFFFFF" gutterBottom>
                                    Quick Access
                                </Typography>
                            </Grid>
                            <Grid item sx={{paddingTop: '0  !important'}}>
                                <Typography variant="body1"  style={{ visibility: 'hidden'}}>
                                    blank
                                </Typography>
                            </Grid>
                            <Grid
                                onClick={() => handleNavigate('brand_requirement')}
                                item
                                sx={{paddingTop: '0  !important'}}
                            >
                                <Typography
                                    variant="type_16_500_FFFFFF"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'rgba(255, 255, 255, 0.8)',
                                        }
                                    }}
                                >
                                    Brand Requirements
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                onClick={() => handleNavigate('about')}
                            >
                                <Typography
                                    variant="type_16_500_FFFFFF"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                          color: 'rgba(255, 255, 255, 0.8)', // Same hover effect
                                        },
                                    }}
                                >
                                    About Us
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                onClick={() => handleNavigate('contact')}
                            >
                                <Typography
                                    variant="type_16_500_FFFFFF"
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'rgba(255, 255, 255, 0.8)',
                                        }
                                    }}
                                >
                                    Contact Us
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Third Column */}
                    <Grid
                        item
                        xs={12}
                        xsl={12}
                        sm={3.2}
                        md={4.7}
                        lg={6.4}
                        xl={6.4}
                        container
                        alignItems={'flex-end'}
                        justifyContent={{xs: 'flex-end'}
                    }>
                        <Link href="https://www.linkedin.com/company/agprop/" target="_blank" rel="noopener noreferrer">
                            <Box
                                component='img'
                                src={linkedInIcon}
                                alt='locationIcon'
                                sx={{
                                    width: '26px', 
                                    height: '26px'
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    px: { xs: '2rem', sm: '2.3rem', md: '4rem', lg: '7.2rem'},
                    py: { xs: '1rem', sm: '1rem', md: '2rem', lg: '1.969rem'},
                    backgroundColor: '#393939'
                }}
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        xsl={6}
                        sm={3}
                        md={2.2}
                        lg={1.4}
                        xl={1.4}
                        onClick={() => handleNavigate('terms_conditions')}
                    >
                        <Typography
                            variant="type_14_500_FFFFFF_POPPINS"
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: 'rgba(255, 255, 255, 0.8)',
                                }
                            }}
                        >
                            Terms of service
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        xsl={6}
                        sm={3}
                        md={2.2}
                        lg={1.4}
                        xl={1.4}
                        onClick={() => handleNavigate('privacy_policy')}
                    >
                        <Typography
                            variant="type_14_500_FFFFFF_POPPINS"
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: 'rgba(255, 255, 255, 0.8)',
                                }
                            }}
                        >
                            Privacy Policy
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xsl={12} sm={6} md={7.6} lg={9.2} xl={9.2} container justifyContent={{xs: 'flex-center',  sm: 'flex-end'}}>
                        <Typography variant="type_14_500_FFFFFF_POPPINS">
                            Copyright 2024 agprop Private Limited
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </StyledFooter>
    )
}
export default Footer;
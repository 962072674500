import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Country, State, City } from 'country-state-city';
import { useParams } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { useNavigate } from 'react-router-dom';
import StyledAllRequirementsList from './StyledAllRequirementsList';
import heroSectionReqList from '../../assets/jpeg/heroSectionReqList.jpg';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import PaginatedCards from '../../components/CardsList/PaginatedCards';
import { fetchBrandData, fetchBrandDetails, fetchBrandReqList } from '../../store/reducers/topBrandReq/apiThunk';
import CustomSearchBar from '../../components/CustomSearchBar';
import CustomSelectBox from '../../components/CustomSelectBox';
import BrandReqDetailsModal from '../../components/BrandReqDetailsModal';
import { selectorLoader } from '../../store/reducers/loader';

const initialLocationData = {
    countries: [],
    states: [],
    cities: [],
}

const initialSelectedLocation = {
    country: 'IN',
    state: '',
    city: '',
}

const AllRequirementsList = () => {
    const {isLoading=false} = useSelector(selectorLoader);
    const navigate = useNavigate();
    const { reqListId } = useParams();
    const dispatch = useDispatch();
    const [topBrandData, setTopBrandData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [brandDetail, setBrandDetail] = useState({})
    const [searchArea, setSearchArea] = useState('')
    const [locationData, setLocationData] = useState(initialLocationData);
    const [selectedLocation, setSelectedLocation] = useState(initialSelectedLocation);
    const [isLoadingHero, setIsLoadingHero] = useState(true);
    const [brandData, setBrandData] = useState({});
    const [prevSelected, setPrevSelected] = useState({});

    useEffect(() => {
        const countryOptions = Country.getAllCountries().map((country) => ({
            value: country.isoCode,
            label: country.name,
        }));
        setLocationData((prev) => ({ ...prev, countries: countryOptions }));
    }, [selectedLocation.country]);

    useEffect(() => {
        if (selectedLocation.country) {
            const stateOptions = State.getStatesOfCountry(selectedLocation.country).map((state) => ({
                value: state.isoCode,
                label: state.name,
            }));
            setLocationData((prev) => ({ ...prev, states: stateOptions, cities: [] }));
            setSelectedLocation((prev) => ({ ...prev, state: '', city: '' }));
        }
    }, [selectedLocation.country]);

    useEffect(() => {
        if (selectedLocation.state && selectedLocation.country) {
            const cityOptions = City.getCitiesOfState(selectedLocation.country, selectedLocation.state).map((city) => ({
                value: city.name,
                label: city.name,
            }));
            setLocationData((prev) => ({ ...prev, cities: cityOptions }));
            setSelectedLocation((prev) => ({ ...prev, city: '' }));
        }
    }, [selectedLocation.state, selectedLocation.country]);

    const fetchData = async (isFilter) => {
        let filterPostData = {};

        if(isFilter === 'search') {
            if(searchArea && prevSelected.state && selectedLocation.state) {
                filterPostData = { ...prevSelected, search: searchArea }
                setPrevSelected({...filterPostData})
            } else if (searchArea) {
                filterPostData = { search: searchArea }
                setPrevSelected({...filterPostData})
            } else {
                filterPostData = { ...prevSelected, search: '' }
                setPrevSelected({...prevSelected, search: '' })
            }
        }

        if(isFilter === 'searchFromClear'){
            if (searchArea) {
                filterPostData = { search: searchArea }
                setPrevSelected({...filterPostData})
            }
        }
        
        if(isFilter === 'apply') {
            if (selectedLocation.state) {
                const stateName = State.getStateByCodeAndCountry(selectedLocation.state, selectedLocation.country);
                if (stateName) {
                    filterPostData.state = stateName.name;
                }
            }

            if (selectedLocation.country) {
                const countryName = Country.getCountryByCode(selectedLocation.country);
                if (countryName) {
                    filterPostData.country = countryName.name;
                }
            }

            if (selectedLocation.city) filterPostData.city = selectedLocation.city;
            if(prevSelected.search && searchArea){
                filterPostData = {...prevSelected, ...filterPostData}
                setPrevSelected({...filterPostData})
            } else {
                setPrevSelected({...filterPostData})
            }
        }

        // if (prevSelected.searchArea && (selectedLocation.state || selectedLocation.city || selectedLocation.country)) {
        //     if(selectedLocation.state){
        //         filterPostData = {...prevSelected}
        //     } else {
        //         filterPostData = {}
        //         setPrevSelected({})
        //     }
        //     filterPostData.search = searchArea;
        // }

        if(isFilter === 'clearAll'){
            filterPostData = {}
        }

        const requestData = {
            id: reqListId,
            per_page: 9,
            page: currentPage,
            ...filterPostData
        }

        const result = await dispatch(fetchBrandReqList(requestData))

        if(result?.payload?.data?.data?.length){
            setTopBrandData(result.payload.data.data);
            const totalItems = result.payload.data.totalItems;
            const pages = Math.ceil(totalItems / 9);
            setTotalPages(pages);
        } else {
            setTopBrandData([]);
            const totalItems = result.payload.data.totalItems;
            const pages = Math.ceil(totalItems / 9);
            setTotalPages(pages);
        }

        const brandDataRes = await dispatch(fetchBrandData({id: reqListId}))
        setBrandData(brandDataRes?.payload?.data)
    };

    useEffect(() => {
        fetchData()
    }, [currentPage, dispatch])

    const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setSelectedLocation((prev) => ({
            ...prev,
            [name]: value,
            ...(name === 'country' ? { state: '', city: '' } : {}),
            ...(name === 'state' ? { city: '' } : {}),
        }));
    };

    const handleClear = (name) => {
        setSelectedLocation((prev) => ({
            ...prev,
            [name]: '',
            ...(name === 'country' ? { state: '', city: '' } : {}),
            ...(name === 'state' ? { city: '' } : {}),
        }));

        setLocationData((prev) => ({
            ...prev,
            states: name === 'country' ? [] : prev.states,
            cities: name === 'state' || name === 'country' ? [] : prev.cities,
        }));
        
        if(name === 'state' && prevSelected.search){
            setPrevSelected({...prevSelected, state: ''})
            fetchData('searchFromClear')
        } else if(name === 'state') {
            setPrevSelected({})
            fetchData('clearAll')
        }
    }

    const handleDialogOpen = (id) => {
        const fetchBrandDetailData = async () => {
            const result = await dispatch(fetchBrandDetails({id}))
            if(result?.payload?.data?._id){
                setBrandDetail({...result.payload.data});
                setDialogOpen(true);
            }            
        };
        fetchBrandDetailData()
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleFilter = () => {
        fetchData('apply')
    }

    const handleSearchChange = (e) => {
        setSearchArea(e.target.value);
    }
    
    const handleSearch = () => {
        fetchData('search')
    }

    const handleClearFilter = () => {
        setLocationData({
            ...locationData,
            cities: [],
        })
        setSelectedLocation(initialSelectedLocation)
        setSearchArea('')
        fetchData('clearAll')
    }

    const handleNavigate = (type) => {
        if(type === 'home'){
            navigate('/')
        }
    }

    return (
        <StyledAllRequirementsList>
            <BrandReqDetailsModal brandReqDetail={brandDetail} open={dialogOpen} onClose={handleDialogClose} />
            <Box
                className='brandRequirementListWrap'
                sx={{
                    mt: { xs: '100px', md: '100px', lg: '100px'},
                    mb: { xs: '80px', sm: '140px'},
                }}
            >
                {isLoadingHero && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: -1,
                            width: '100%',
                            height: { xs: '618px', lg: '718px'},
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={heroSectionReqList}
                    alt='heroSectionReqList'
                    onLoad={() => setIsLoadingHero(false)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        width: '100%',
                        minHeight: '618px',
                        objectFit: 'cover',
                        display: isLoadingHero ? 'none' : 'block',
                    }}
                />
                <Box>
                    <Button
                        onClick={() => handleNavigate('home')}
                        variant="contained"
                        sx={{
                            display: 'inline-block',
                            mb: '32px',
                            backgroundColor: '#FFFFFF',
                            textTransform: 'none',
                            p: '0px',
                            lineHeight: '16.94px',
                            py: '9.5px',
                            px: '12px',
                            borderRadius: '34px',
                            '&:hover': {
                                backgroundColor: '#F2F2F2',
                            },
                            '&:active': {
                                backgroundColor: '#E0E0E0',
                            },
                            '&:focus': {
                                backgroundColor: '#EAEAEA',
                            },
                        }}
                    >
                        <Typography variant='type_14_500_0048D0' sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <WestIcon sx={{ width: '14.17px', height: '14px' }}/>
                            Back to home
                        </Typography>
                    </Button>  
                </Box>
                
                <Typography
                    variant='type_72_500_FFFFFF'
                    sx={{
                        mb: '16px',
                        fontSize: {xs: '30px', md: '40px', lg: '72px'},
                        lineHeight: {xs: '45px', md: '50px', lg: '93.6px'},
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 3,
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {brandData.name}
                </Typography>
                {/* <Typography
                    sx={{
                        fontSize: {xs: '18px', md: '20px', lg: '28px'},
                        lineHeight: {xs: '28px', md: '30px', lg: '42px'},
                        display: 'block'
                    }}
                    variant='type_28_500_FFFFFF'
                >
                    Starbucks Corporation is an American multinational chain of coffeehouses 
                    and roastery reserves headquartered in Seattle, Washington. It was founded 
                    in 1971 by Jerry Baldwin, Zev Siegl, and Gordon Bowker at Seattle's Pike Place 
                    Market initially as a coffee bean wholesaler.
                </Typography> */}
            </Box>
            <Box
                className='brandRequirementListWrap'
                sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                    px: { xs: '10px', sm: '44px'},
                    pt: '36px',
                    mb: '100px',
                    pb: '44px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '24px',
                        flexDirection: {xs: 'column', md: 'row'},
                        gap: {xs: '20px', md: '0px'}
                    }}
                >
                    <Typography
                        variant='type_28_600_414141'
                        sx={{
                            textAlign: 'center',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2,
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            fontSize: {xs: '22px', sm: '28px'}
                        }}
                    >
                        All {brandData?.name || ''} Requirements
                    </Typography>
                    <CustomSearchBar
                        onChange={handleSearchChange}
                        onSearch={handleSearch}
                        placeholder={'Search by area or pincode'}
                        value={searchArea}
                    />
                </Box>
                <Box sx={{display: 'flex', mb: '36px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} sx={{pt: '0px !important'}}>
                                    <CustomSelectBox
                                        label="Country"
                                        options={locationData.countries}
                                        value={locationData.countries && locationData.countries.length > 0 ? selectedLocation.country || 'IN' : ''}
                                        handleChange={(e) => handleLocationChange(e)}
                                        handleClear={handleClear}
                                        name={'country'}
                                        disabled={true}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{pt: '0px !important'}}>
                                    <CustomSelectBox
                                        label="State"
                                        options={locationData.states}
                                        value={selectedLocation.state}
                                        handleChange={(e) => handleLocationChange(e)}
                                        disabled={!selectedLocation.country}
                                        handleClear={handleClear}
                                        name={'state'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{pt: '0px !important'}}>
                                    <CustomSelectBox
                                        label="City"
                                        options={locationData.cities}
                                        value={selectedLocation.city}
                                        handleChange={(e) => handleLocationChange(e)}
                                        disabled={!selectedLocation.state}
                                        handleClear={handleClear}
                                        name={'city'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} gap={'16px'} display="flex" alignItems="center" justifyContent={'end'}  sx={{pt: '0px !important'}}>
                            <Button
                                onClick={handleFilter}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#0048D0',
                                    textTransform: 'none',
                                    p: '0px',
                                    lineHeight: '16.94px',
                                    py: '15.5px',
                                    px: '20px',
                                    borderRadius: '8px'
                                }}
                                disabled={!selectedLocation.state}
                            >
                                <Typography variant='type_14_600_FFFFFF'>
                                    Apply Filter
                                </Typography>
                            </Button>
                            <Button
                                onClick={handleClearFilter}
                                variant="outlined"
                                sx={{
                                    textTransform: 'none',
                                    p: '0px',
                                    lineHeight: '16.94px',
                                    py: '15.5px',
                                    px: '20px',
                                    borderRadius: '8px',
                                    border: '1px solid #2F3038',
                                    display: selectedLocation?.state ? 'block' : 'none'
                                }}
                            >
                                <Typography variant='type_14_600_2F3038'>
                                    Clear All
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{pb: '16px'}}>
                    <PaginatedCards
                        isLoading={isLoading}
                        isAllReq={true}
                        handleCurrentPage={(page) => setCurrentPage(page)}
                        cards={topBrandData}
                        pageLimit={20}
                        totalPages={totalPages}
                        handlePaginatedCardClick={handleDialogOpen}
                    />
                </Box>
            </Box>
            <SubscribeToNewsletter className={'brandRequirementListWrapPadding'}/>
        </StyledAllRequirementsList>
    )
}

export default AllRequirementsList;
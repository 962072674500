import React, { useState } from 'react';
import {
  Autocomplete,
  TextField,
  Checkbox,
  Chip,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    return (
        <ul
            ref={ref}
            style={{ maxHeight: 200, overflowY: 'auto' }}
            {...other}
            >
            {children}
        </ul>
    );
});

const MultiSelectDropdown = ({ options, onSelectChange }) => {
    const theme = useTheme();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const MAX_VISIBLE_CHIPS = 3;

    const handleSelectChange = (event, values) => {
        setSelectedOptions(values);
        onSelectChange(values);
    };

    const handleDeleteChip = (chipToDelete) => {
        const updatedOptions = selectedOptions.filter((chip) => chip !== chipToDelete)
        setSelectedOptions(updatedOptions);
        onSelectChange(updatedOptions);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400}}>
            <Box
                sx={{
                    display: selectedOptions.length ? 'flex' : 'none',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    mb: 1,
                    border: '1px solid #D4D4D4',
                    py: '12px',
                    px: '12px'
                }}

            >
                {selectedOptions.slice(0, MAX_VISIBLE_CHIPS).map((option, index) => (
                    <Chip
                        key={index + options}
                        label={option}
                        onDelete={() => handleDeleteChip(option)}
                        deleteIcon={
                            <IconButton
                                sx={{
                                    backgroundColor: '#363636',
                                    width: '16px',
                                    height: '16px',
                                    '&:hover': {
                                        backgroundColor: '#505050',
                                    },
                                }}
                            >
                                <Close sx={{ color: '#FFFFFF', width: '8.8px', height: '8.8px' }} />
                            </IconButton>
                        }
                        sx={{
                            maxWidth: '100px',
                            mr: 0.5,
                            ...theme.typography.type_14_500_404040,
                            backgroundColor: '#EAEAEA',
                            py: '4px',
                            '& .MuiChip-label': {
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '20px',
                                width: '30%',
                                mr: '10px',
                            }
                        }}
                    />
                ))}
                {selectedOptions.length > MAX_VISIBLE_CHIPS && (
                    <Typography variant="body2" sx={{ ml: 1 }}>
                        +{selectedOptions.length - MAX_VISIBLE_CHIPS} more
                    </Typography>
                )}
            </Box>

            <Autocomplete
                multiple
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={selectedOptions}
                onChange={handleSelectChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Select options" />
                )}
                renderOption={(props, option, { selected }) => {
                const { key, ...restProps } = props;
                return (
                    <li key={key} {...restProps}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}}
                renderTags={() => null}
            />
        </Box>
    );
};

export default MultiSelectDropdown;

import { Box, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledBrandRequirementList from './StyledBrandRequirementList';
import heroSectionReqList from '../../assets/jpeg/heroSectionReqList.jpg';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import PaginatedCards from '../../components/CardsList/PaginatedCards';
import MultiSelectDropdown from '../../components/CardsList/MultiSelectDropdown';
import { fetchTopBrandList } from '../../store/reducers/topBrandReq/apiThunk';
import { selectorLoader, updateCurrentPage } from '../../store/reducers/loader';


const BrandRequirementList = () => {
    const {isLoading=false, currentPageRedux=1} = useSelector(selectorLoader);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);
    const [topBrandData, setTopBrandData] = useState([]);
    //const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isHeroSectionLoading, setIsHeroSectionLoading] = useState(true);

    useEffect(() => {
        const fetchNameList = async () => {
            const nameList = await dispatch(
                fetchTopBrandList({
                    all: true,
                })
            )
            if(nameList?.payload?.data?.data?.length){
                const nameArray = nameList.payload.data.data.map(item => item.name);
                setMultiSelectOptions(nameArray);
            }
        };
        fetchNameList()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(
                fetchTopBrandList({
                    per_page: 20,
                    page: currentPageRedux,
                    filter: selectedOptions,
                })
            )
            if(result?.payload?.data?.data?.length){
                setTopBrandData(result.payload.data.data);
                const totalItems = result.payload.data.totalItems;
                const pages = Math.ceil(totalItems / 20);
                setTotalPages(pages);
            }
        };
        fetchData()
    }, [currentPageRedux, dispatch, selectedOptions])

    const handleCardNavigation = (id) => {
        navigate(`/all-requirements-list/${id}`);
    }

    useEffect(() => {
        if(selectedOptions.length > 0) {
            dispatch(updateCurrentPage(1)); 
        }
    }, [selectedOptions])
    
    return (
        <StyledBrandRequirementList>
            <Box
                className='brandRequirementListWrap'
                sx={{
                mt: { xs: '95px', md: '120px', lg: '168px'},
                mb: { xs: '60px', md: '80px', lg: '140px'},
                display: 'flex',
                flexDirection: 'column',
                }}
            >
                {isHeroSectionLoading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: -1,
                            width: '100%',
                            height: {xs: '500px', md: '500px', lg: '718px'},
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={heroSectionReqList}
                    alt='heroSection'
                    onLoad={() => setIsHeroSectionLoading(false)}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        width: '100%',
                        minHeight: {xs: '700px', xsl: '650px', sm: '618px'},
                        objectFit: 'fill',
                        display: isHeroSectionLoading ? 'none' : 'block',
                        filter: 'brightness(45%)',
                    }}
                />
                <Typography
                    variant='type_72_500_FFFFFF'
                    sx={{
                        textAlign: 'center',
                        fontSize: { xs: '40px', sm: '50px', lg: '72px'},
                        lineHeight: { xs: '50px', sm: '60px', lg: '93.6px'}
                    }}
                >
                    Welcome to Real Estate
                </Typography>
                <Typography
                    variant='type_72_500_FFFFFF'
                    sx={{
                        textAlign: 'center',
                        fontSize: { xs: '40px', sm: '50px', lg: '72px' },
                        mb: { xs: '28px', sm: '12px', lg: '16px' },
                        lineHeight: { xs: '50px', sm: '60px', lg: '93.6px' }
                    }}
                >
                    Excellence!
                </Typography>
                <Typography
                    sx={{
                        fontSize: {xs: '20px', md: '24px', lg: '28px'},
                        lineHeight: {xs: '30px', md: '35px', lg: '42px'},
                    }}
                    variant='type_28_500_FFFFFF'
                >
                    At Requirements, we unlock unparalleled opportunities in the real estate realm. 
                    With expertise and cutting-edge technology, bespoke solutions for leasing and 
                    sales are crafted, ensuring maximum returns and optimal cost efficiency.  
                    Join us to unleash the full potential of assets and embark on a journey fueled 
                    by innovation and research-backed strategies.
                </Typography>
            </Box>
            <Box
                className='brandRequirementListWrap'
                sx={{backgroundColor: '#FFFFFF'}}
                px={{xs: '50px', md: '127px'}}
                py="12px"
                mb={{xs: '28px', md: '16px'}}
                borderRadius={'8px'}
            >
                <Typography variant='type_16_500_151515'>
                    Join us to unleash the full potential of assets and embark on a journey fueled by innovation and research-backed strategies.
                </Typography>
            </Box>
            <Box
                className='brandRequirementListWrap'
                sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                    pl: '44px',
                    pt: '36px',
                    pr: '44px',
                    pb: '50px',
                    mb: '100px',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '24px',
                        flexDirection: {xs: 'column', md: 'row'}
                    }}
                >
                    <Typography variant='type_28_600_414141' sx={{mb: { xs: '20px', md: '0px' }}}>
                        Top Brand Requirements
                    </Typography>
                    <MultiSelectDropdown options={multiSelectOptions} onSelectChange={setSelectedOptions}/>
                </Box>
                <Box sx={{pb: '16px'}}>
                    <PaginatedCards
                        isUseDispatch={true}
                        isLoading={isLoading}
                        cards={topBrandData}
                        pageLimit={20}
                        totalPages={totalPages}
                        handlePaginatedCardClick={handleCardNavigation}
                    />
                </Box>
            </Box>
            <SubscribeToNewsletter className={'brandRequirementListWrapPadding'}/>
        </StyledBrandRequirementList>
    )
}

export default BrandRequirementList;
import React, { useState } from 'react';
import { Box, Button, Divider, Avatar, Typography, Grid } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import doubleQuotes from '../../assets/svg/doubleQuotes.svg';
import CONSTANTS from '../../constants/common';

const {reviewData} = CONSTANTS;

const ReviewSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewData.length);
      setFade(true);
    }, 500);
  };

  const handlePrevious = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? reviewData.length - 1 : prevIndex - 1
      );
      setFade(true);
    }, 500);
  };

  const { description, name, title, avatar } = reviewData[currentIndex];

  return (
    <Box
      sx={{
        padding: {xs: '15px', lg: '36px'},
        borderRadius: 9,
        textAlign: 'left',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
        <Box
          component="img"
          src={doubleQuotes}
          alt="doubleQuotes"
          sx={{
              width: '34.38px',
              height: '32.56px',
              display: 'block',
              paddingBottom: '18px'
          }}
        />
        <Box
          sx={{
            position: 'relative',
            minHeight: {xs: '180px', sm: '200px', lg: '160px'},
          }}
        >
          <Typography
            variant="type_24_500_414141"
            sx={{
              whiteSpace: 'pre-line',
              opacity: fade ? 1 : 0,
              transition: 'opacity 0.5s ease',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
             // WebkitLineClamp: { xs: 9, md: 7, lg: 6},
              wordBreak: 'break-word',
              textOverflow: 'ellipsis',
              fontSize: {xs: '16px', xsl: '18px', sm: '20px', md: '22px', lg: '24px'}
            }}
          >
            {description}
          </Typography>
        </Box>
        <Divider sx={{ my: '24px', border: '0.5px solid #D3D7DE' }} />
        <Grid container direction={ { xs: 'column',  xsl: 'row'}}>
            <Grid item xsl={12} sm={9} md={9} lg={8} sx={{ minHeight: '48px', paddingRight: '12px', position: 'relative' }}>
              <Avatar
                src={avatar}
                alt={name}
                sx={{
                  width: 48,
                  height: 48,
                  opacity: fade ? 1 : 0,
                  transition: 'opacity 0.5s ease',
                  position: 'absolute',
                }}
              />
              <Box sx={{ minHeight: '48px', paddingLeft: '60px', display: 'flex',flexDirection: 'column', justifyContent: 'center'}}>
                <Typography
                  variant="type_20_600_414141"
                  sx={{
                    opacity: fade ? 1 : 0,
                    transition: 'opacity 0.5s ease',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                   // WebkitLineClamp: 2,
                    wordBreak: 'break-word',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="type_16_400_707070"
                  color="text.secondary"
                  sx={{
                    opacity: fade ? 1 : 0,
                    transition: 'opacity 0.5s ease',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  //  WebkitLineClamp: { xs: 3, sm: 2},
                    wordBreak: 'break-word',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xsl={12} sm={3} md={3} lg={4} sx={{ mt: {xs: '15px', sm: 0}, display: 'flex', justifyContent: {xs: 'center', sm: 'end'}, alignItems: 'center'}}>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: '100%',
                        backgroundColor: '#DFE5F0',
                        color: '#414141',
                        minWidth: '36px',
                        height: '36px',
                        width: '36px'
                    }}
                    onClick={handlePrevious}
                >
                    <WestIcon sx={{ width: '14.17px', height: '14px' }}/>
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: '100%',
                        backgroundColor: '#DFE5F0',
                        color: '#414141',
                        minWidth: '36px',
                        height: '36px',
                        width: '36px',
                        marginLeft: '12px',
                    }}
                    onClick={handleNext}
                >
                    <EastIcon  sx={{ width: '14.17px', height: '14px' }}/>
                </Button>
            </Grid>
        </Grid>
    </Box>
  );
};

export default ReviewSlider;

import { Card as MUICard, CardContent, Typography, Box, Grid, Divider, Button, Skeleton } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useEffect, useState } from 'react';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import verifiedIcon from '../../../assets/svg/verifiedIcon.svg';

const ReqCard = ({ card, handleCardClick }) => {
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImageError(false);
    setLoading(true);
    const img = new Image();
    img.src = card.brand.logo;
    img.onload = () => handleImageLoad();
    img.onerror = () => handleImageError();
  }, [card.brand.logo]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setImageError(true);
    setLoading(false);
  };

  const handleClick = () => {
    handleCardClick(card._id || '')
  }
  
  return (
    <MUICard
      sx={{
        width: '100%',
        maxWidth: 356,
        position: 'relative',
        borderRadius: '18px',
        border: '1px solid #9EC1FF80',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Grid container sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
        <Grid item sx={{ width: '6px', backgroundColor: '#0048D0', height: '100%' }} />
        <Grid item sx={{ flexGrow: 1, position: 'relative' }}>
          <CardContent sx={{p: '18px', pr: '20px', pl: '12px',  pb: '16px !important', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: '10px'}}>
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 42 }}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                        width: '42px',
                        height: {xs: '42px'},
                    }}
                  />
                </Box>
              )}
              {card.brand.logo && !imageError ? (
                <Box
                  component='img'
                  src={card.brand.logo}
                  alt='logo'
                  sx={{
                    width: '42px',
                    height: '42px',
                    objectFit: 'fill',
                    borderRadius: '8px',
                    mr: '8px',
                  }}
                />
              ) : (
                imageError ? (
                  <BrokenImageIcon sx={{ height: '42px', width: '42px', fontSize: '124.89px', color: 'gray' }} />
                ) : (
                  <ImageNotSupportedIcon sx={{ height: '42px', width: '42px', fontSize: '124.89px', color: 'gray' }} />
                )
              )}
             
              <Typography
                variant="type_18_700_3E3E3E"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 1,
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis'
                }}
              >
                {card.brand.name}
              </Typography>
            </Box>
            <Divider sx={{backgroundColor: '#9EC1FF', opacity: '0.5', mb: '18px'}}/>
            <Box sx={{ mb: '16px', display: 'flex', alignItems: 'center' }}>
              <Box
                component="img"
                src={verifiedIcon}
                alt="verifiedIcon"
                sx={{
                  width: 20,
                  height: 20,
                  mr: '5px'
                }}
              />
              <Typography variant="type_12_600_808080" >
                Verified by Agprop
              </Typography>
            </Box>
            <Grid container spacing={1.8} sx={{height: '100%', mb: '18px'}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left',}}>
                  <Typography variant='type_12_600_808080' sx={{mb: '8px'}}>
                    City
                  </Typography>
                  <Typography variant='type_18_600_3E3E3E' color='textSecondary'>
                    {card.city}
                  </Typography>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                  <Typography variant='type_12_600_808080' sx={{mb: '8px'}}>
                    State
                  </Typography>
                  <Typography variant='type_18_600_3E3E3E' color='textSecondary'>
                    {card.state}
                  </Typography>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                  <Typography variant='type_12_600_808080' sx={{mb: '8px'}}>
                    Area
                  </Typography>
                  <Typography variant='type_18_600_3E3E3E' color='textSecondary'>
                    {card.area_name}
                  </Typography>
              </Grid>
              {/* <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                  <Typography variant='type_12_600_808080' sx={{mb: '8px'}}>
                    Carpet Area(SQFT)
                  </Typography>
                  <Typography variant='type_18_600_3E3E3E' color='textSecondary'>
                    {card.carpet_area}
                  </Typography>
              </Grid> */}
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                  <Typography variant='type_12_600_808080' sx={{mb: '8px'}}>
                    Pincode
                  </Typography>
                  <Typography variant='type_18_600_3E3E3E' color='textSecondary'>
                    {card.pincode}
                  </Typography>
              </Grid>
            </Grid>
            <Box textAlign={'right'} sx={{height: '18px'}}>
              <Button
                variant='standard'
                sx={{
                  textTransform: 'none',
                  gap: '8px',
                  p: '0px',
                  px: '0px',
                  py: '0px',
                  lineHeight: 'unset',
                  backgroundColor: 'transparent',
                  position: 'absolute',
                  right: '24px',
                  bottom: '15px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
                onClick={handleClick}
              >
                <Typography variant='type_16_600_0048D0'>View Details</Typography>
                <EastIcon sx={{ color: '#0048D0', width: '16px', height: '16px' }}/>
              </Button>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </MUICard>
  );
};

export default ReqCard;

// src/components/PaginatedCards.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Pagination from '../Pagination';
import Card from '../Card/Card';
import ReqCard from '../ReqCard/ReqCard';
import { selectorLoader, updateCurrentPage } from '../../../store/reducers/loader';

const PaginatedCards = ({isUseDispatch=false, isLoading= false, isAllReq = false, cards, totalPages, handleCurrentPage, currentPage, handlePaginatedCardClick }) => {
  const dispatch = useDispatch();
  const {currentPageRedux=1} = useSelector(selectorLoader);

  const handlePageChange = (page) => {
    if(isUseDispatch){
      dispatch(updateCurrentPage(page))
    } else {
      handleCurrentPage(page);
    }
  };

  return (
    <Box sx={{position: 'relative'}}>
      {isLoading && (
        <Box 
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {cards?.length > 0 ? (
        <>
          <Grid container spacing={isAllReq ? 2.9 : 4.3} justifyContent={'start'}>
            {cards.map((card, index) => (
              <Grid
                item
                key={index}
                xs={12} sm={isAllReq ? 12 : 6} md={isAllReq ? 6 : 4} lg={isAllReq ? 4 : 3} xll={2.4}
                sx={{display: 'flex', justifyContent: 'center'}}
              >
                {isAllReq ? (
                  <ReqCard card={card} handleCardClick={handlePaginatedCardClick} />
                ) : (
                  <Card card={card} handleCardClick={handlePaginatedCardClick} />
                )}
              </Grid>
            ))
            }
          </Grid>

          {totalPages > 1 && ( // Render pagination only if there is more than one page
            <Pagination
              currentPage={isUseDispatch ? currentPageRedux : currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', padding: '2rem' }}>
          <Typography variant="h6" color="textSecondary">
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PaginatedCards;

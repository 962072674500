import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Divider, Drawer, List, ListItem, ListItemText } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, Button, Card, CardContent, MenuItem, ListItemIcon } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import StyledHeader from './StyledHeader';
import { ROUTE_PATH } from '../../constants/route';
import CONSTANTS from '../../constants/common';
import {COMMON_LANG, BUTTON_LANG} from '../../constants/lang';
import { ReactComponent as AgpropComp } from '../../assets/svg/agprop.svg';

const {headerComp: {headerPages, headerSettings, productsSettings}} = CONSTANTS

const Header = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const customColor = theme.palette.customColor;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenProductsMenu = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleCloseProductsMenu = () => {
    setAnchorElProducts(null);
  };

  const handleNavigation = (item, event) => {
    switch(item) {
      case 'Products':
        if (!anchorElProducts) {
          handleOpenProductsMenu(event);
        } else {
          toggleDrawer(false);
        }
        break;
      case 'Requirements':
        navigate(ROUTE_PATH.BRAND_REQUIREMENT_LIST);
        break;
      case 'About':
        navigate('/about');
        break;
      case 'Contact Us':
        navigate('/contact-us');
        break;
      default:
        console.log('No path defined for', item);
        break;
    }
  };

  const handleIsActive = (item) => {
    switch (item) {
      case 'Requirements':
        return location?.pathname === '/brand-requirement-list';
      case 'Products':
        return location?.pathname === '/propvision' || location?.pathname === '/propmagnet';
      case 'About':
        return location?.pathname === '/about';
      case 'Contact Us':
        return location?.pathname === '/contact-us';
      case 'logo':
        return location?.pathname === '/';
      default:
        return false;
    }
  };

  return (
    <StyledHeader>
      <AppBar
        position="fixed"
        sx={{
          color: customColor.textColor1,
          borderRadius: '100px',
          top: '1.125rem',
          left: 0,
          right: 0,
          zIndex: '50',
          mx: { xs: '0.6rem', xsl: '1rem', sm: '2rem', md: '4rem', lg: '7.3rem'},
          display: 'block',
          width: 'unset',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        <Container maxWidth="100%">
          <Toolbar disableGutters sx={{minHeight: { xs: '40px', sm: '64px'}}}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => {
                navigate('/');
                window?.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex', cursor: 'pointer' },
              }}
            >
              <Box
                  component={AgpropComp}
                  sx={{
                      width: '76px',
                      height: '17px',
                      '& path': { fill: handleIsActive('logo') ? '#0048D0' : '#3E3E3E'},
                      '&:hover path': { fill: '#0048D0', },
                  }}
              />
            </Typography>
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="open navigation drawer"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                sx={{display: { xs: 'flex', md: 'none' }}}
                PaperProps={{
                  sx: {
                    // width: { xs: '80%', sm: 250 },
                    width: { xs: 250 },
                  },
                }}
              >
                <Box
                  sx={{ width: '100%' }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <Box
                    className="drawer-header"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 2,
                      borderBottom: `1px solid ${customColor.textColor2}`,
                    }}
                  >
                      <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => {
                          navigate('/');
                          window?.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }}
                        sx={{
                          cursor: 'pointer', display: drawerOpen ? 'block' : 'none',
                        }}
                      >
                        <Box
                            component={AgpropComp}
                            sx={{
                                width: '76px',
                                height: '17px',
                                '& path': { fill: handleIsActive('logo') ? '#0048D0' : '#3E3E3E'},
                                '&:hover path': { fill: '#0048D0', },
                            }}
                        />
                      </Typography>
                  </Box>

                  <List>
                    {headerPages?.map((item, index) => (
                      <ListItem
                        button 
                        key={index} 
                        onClick={(event) => handleNavigation(item, event)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              component="div"
                              sx={{
                                ...theme.typography.type_16_500_414141,
                                '&:hover': {
                                  ...theme.typography.type_16_600_0048D0
                                },
                                '&:focus': {
                                  ...theme.typography.type_16_600_0048D0
                                },
                                '&:active': {
                                  ...theme.typography.type_16_600_0048D0
                                },
                                '&.Mui-active': {
                                  ...theme.typography.type_16_600_0048D0,
                                },
                                color: handleIsActive(item) ? customColor.primaryBlue100 : customColor.textColor2,
                                fontWeight: handleIsActive(item) ? '600' : '500'
                              }}
                            >
                              {item}
                            </Typography>
                          }
                        />
                        {item === 'Products' && (
                          <>
                            {anchorElProducts ? (
                              <KeyboardArrowUpIcon sx={{ ml: 1 }} />
                            ) : (
                              <KeyboardArrowDownIcon sx={{ ml: 1 }} />
                            )}
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </Box>
            <Box
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1
              }}
            >
              <Typography
                noWrap
                component="a"
                onClick={() => {
                  navigate('/');
                  window?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'inherit',
                  textDecoration: 'none',
                  cursor: 'pointer'
                }}
              >
                <Box
                    component={AgpropComp}
                    sx={{
                      width: '76px',
                      height: '17px',
                      '& path': { fill: handleIsActive('logo') ? '#0048D0' : '#3E3E3E'},
                      '&:hover path': { fill: '#0048D0' },
                    }}
                />
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>
              {headerPages.map((item, index) => (
                <Button
                  key={index}
                  onClick={(event) => handleNavigation(item, event)}
                  sx={{
                    textTransform: 'none',
                    my: 2,
                    mx: 1,
                    display: 'flex',
                    ...theme.typography.type_16_500_414141,
                    '&:hover': {
                      ...theme.typography.type_16_600_0048D0
                    },
                    '&:focus': {
                      ...theme.typography.type_16_600_0048D0
                    },
                    '&:active': {
                      ...theme.typography.type_16_600_0048D0
                    },
                    color: handleIsActive(item) ? customColor.primaryBlue100 : customColor.textColor2,
                    fontWeight: handleIsActive(item) ? '600' : '500'
                  }}
                >
                  {item}
                  {item === 'Products' && (
                    anchorElProducts ? <KeyboardArrowUpIcon sx={{ ml: 1 }} /> : <KeyboardArrowDownIcon sx={{ ml: 1 }} />
                  )}
                </Button>
              ))}
              <Button
                sx={{width: '6.75rem', my: 2, mx: 1, color: customColor.textColor1, display: {xs: 'none !important',  lg: 'block !important'}}}
              >
              </Button>
              {/* product dropdown start */}
              <Popover
                id="products-popover"
                anchorEl={anchorElProducts}
                open={Boolean(anchorElProducts)}
                onClose={handleCloseProductsMenu}
                PaperProps={{
                  sx: {
                    left: {xs: '50%', md: ''},
                    mr: {xs: '4%', md: '5%'},
                    ml: {xs: '2%', md: '0'},
                    mt: '65px',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    padding: {xs: '12px', sm: '24px'},
                    boxShadow: '0px 4px 25px 0px #5C5C5C24',
                    border: '1px solid #D4D4D4',
                    background: '#FFFFFF'
                  },
                }}
              >
                <Grid container spacing={2} columns={{ xs: 1, md: 2}}>
                  {productsSettings.map((productSetting, index) => (
                    <Grid item xs={1} key={index + productSetting.name}>
                      <Card
                        sx={{
                          justifyContent: 'space-between',
                          height: '88%',
                          backgroundColor: '#F8FAFC',
                          color: 'white',
                          padding: '12px',
                          borderRadius: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid #EFEFF0',
                        }}
                      >
                        {/* Row 1: Logo and Name */}
                        <CardContent sx={{ marginBottom: '0.938rem', padding: '0px', display: 'flex', alignItems: 'center'}}>
                          <ListItemIcon sx={{minWidth: '36px'}}>
                            <img src={productSetting.icon} alt={productSetting.name} style={{ width: 24, height: 24 }} />
                          </ListItemIcon>
                          <Typography variant="type_24_600_414141" textAlign="center">
                            {productSetting.name}
                          </Typography>
                        </CardContent>

                        {/* Row 2: Additional Text */}
                        <CardContent sx={{padding: '0px', pr: '1px', }}>
                          <Typography
                            variant='type_14_500_000000'
                            textAlign="left"
                            
                          >
                            {productSetting.description || 'Text not loaded'}
                          </Typography>
                        </CardContent>

                        {/* Row 3: Button with Icon */}
                        <CardContent sx={{ paddingTop: '15px', paddingBottom: '0px !important', display: 'flex', justifyContent: 'end' }}>
                          <Button
                            onClick={() => {
                              navigate(productSetting.url)
                              toggleDrawer(false)
                              handleCloseProductsMenu();
                            }}
                            variant="standard"
                            sx={{
                              px: '8.5px',
                              py: '8px',
                              textTransform: 'none',
                              borderRadius: '100px',
                              border: '1px solid #EFEFF0',
                              backgroundColor: '#FFFFFF',
                              '&:hover': { backgroundColor: '#F3F7FF' }
                            }}
                          >
                            <Typography variant='type_12_600_0048D0'>
                              {BUTTON_LANG.EXPLORE_MORE}
                            </Typography>
                            <ListItemIcon
                              sx={{
                                ml: '4px',
                                borderRadius: '100%',
                                minWidth: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#F3F7FF'
                              }}
                            >
                              <CallMadeIcon sx={{width: '13.33px', height: '13.33px', color: '#0048D0'}}/>
                            </ListItemIcon>
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Popover>
              {/* product dropdown end */}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                variant='contained'
                onClick={handleOpenUserMenu}
                sx={{background: '#0048D0', py: { xs: '5px', sm: '9.5px'}, px: {xs: '16px', xsl: '22px'}, borderRadius: '3rem', textTransform: 'none' }}
              >
                <Typography variant="type_14_600_FFFFFF">
                  {COMMON_LANG.LOGIN}
                </Typography>
                  {anchorElUser ? <KeyboardArrowUpIcon sx={{ color: '#FFFFFF' }}/> : <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }}/>}
              </Button>
              <Menu
                sx={{ mt: '65px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  sx: {
                    borderRadius: '21.89px',
                    overflow: 'hidden',
                    px: '16.42px',
                    py: '8.42px'
                  },
                }}
              >
                {headerSettings.map((setting, index) => (
                  <Box key={index + setting.name}>
                    <MenuItem
                      sx={{p: '0px', justifyContent: 'space-between'}}
                      onClick={() => {
                        handleCloseUserMenu();
                        window.open(setting.url, '_blank');
                      }}>
                      <ListItemIcon sx={{mr: '11px'}}>
                        <img src={setting.icon} alt={setting.name} style={{ width: 32.83, height: 32.83 }} />
                      </ListItemIcon>
                      <Typography sx={{width: '98px'}}>
                        {setting.name}
                      </Typography>
                      <ListItemIcon
                        sx={{
                          ml: '1.847rem',
                          borderRadius: '100%',
                          width: '32.83px',
                          height: '32.83px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#F3F7FF'
                        }}
                      >
                        <CallMadeIcon sx={{width: '14.94px', height: '14.94px', color: '#0048D0'}}/>
                      </ListItemIcon>
                    </MenuItem>
                    {index < headerSettings.length - 1 && <Divider sx={{border: '1.37px solid #DFEAFF'}}/>}
                  </Box>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </StyledHeader>
  );
};

export default Header;

import { RouterProvider } from 'react-router-dom';
import routes from './routes';
import StyledApp from './StyledApp';
import { StyledToast } from './components/Toast/StyledToast';
// import './assets/fonts.css';

function App() {
  return (
    <StyledApp>
      <StyledToast />
      <RouterProvider router={routes} />
    </StyledApp>
  );
}

export default App;

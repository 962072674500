import { Container } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { updateCurrentPage } from '../../store/reducers/loader';

// const { LOGIN = '' } = ROUTE_PATH;
const PublicLayout = ({ children, title=''}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if(location.pathname !== '/brand-requirement-list' && !location.pathname.startsWith('/all-requirements-list')){
      dispatch(updateCurrentPage(1));
    }
  }, [location.pathname])
//   const authentication = useSelector((state) => state.authentication);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const { auth: { token = '' } = {} } = authentication;
//     if (!token && privateRoute) {
//       navigate(LOGIN);
//     }
//   }, [authentication, privateRoute, navigate]);

  return (
    <>
      {title &&
        <HelmetProvider>
          <Helmet>
            <title>{title}: Helping You Make the Right Move</title>
          </Helmet>
        </HelmetProvider>
      }
        <Container sx={{mx: '0px !important', my: '0px !important', maxWidth: '100% !important', px: '0px !important', py: '0px !important', height: '100dvh', flexDirection: 'column', display: 'flex', justifyContent: 'space-between'}} maxWidth="lg" className="publicLayoutWrap">
        <Header/>
        <ScrollToTop />
        {children}
        <Footer />
      </Container>
    </>
  
  );
};

export default PublicLayout;

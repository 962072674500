import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {
    Box,
    Typography,
    CardContent,
    Card,
    CardMedia,
    Skeleton,
} from '@mui/material';
import Slider from 'react-slick';
import verifiedIcon from '../../../assets/svg/verifiedIcon.svg';
import { fetchTopBrandList } from '../../../store/reducers/topBrandReq/apiThunk';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DashboardSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [topBrandData, setTopBrandData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(fetchTopBrandList({per_page: 10, page: 1}))
      if(result?.payload?.data?.data?.length){
        setTopBrandData(result.payload.data.data)
      }
    };
    fetchData()
  }, [])

  const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
      }}
      {...props}
    >
      {children}
    </Box>
  );

    
  const settings = {
    customPaging: function () {
      return <div className='previewImageWrap'></div>;
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    className: 'center',
    lazyLoad: 'anticipated',
    autoplay: topBrandData.length < 1 ? false : true,
    autoplaySpeed: 4000,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <SlickButtonFix>
        <EastIcon
          sx={{
            borderRadius: '100%',
            padding: '10.5px',
            background: '#DFE5F0 !important',
            right: {xs: '0', sm: '18px', lxs: '15px'},
            top: '-9px',
            position: 'absolute',
            color: '#414141 !important',
            height: '15.17px',
            width: '15px'
          }}
        />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <WestIcon
          sx={{
            borderRadius: '100%',
            padding: '10.5px',
            background: '#DFE5F0 !important',
            left: {xs: '0', sm: '15px'},
            top: '-9px',
            position: 'absolute',
            color: '#414141 !important',
            height: '15.17px',
            width: '15px',
            zIndex: 5,
          }}
        />
      </SlickButtonFix>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
        slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
        slidesToShow: 1,
        }
      }
    ]
  };

  const handleCardClick = (cardId) => {
    if(cardId){
    navigate(`/all-requirements-list/${cardId}`);
    }
  };

  return (
    topBrandData && topBrandData.length > 0 ? (
      <Slider {...settings}>
        {topBrandData.map((item, index) => (
          <Card
            onClick={() => handleCardClick(item?._id)}
            key={item._id + index}
            sx={{
              width: {lxs: '300px'},
              borderRadius: '18px',
              border: '1px solid #9EC1FF80',
              cursor: 'pointer',
              '&:hover': {
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Box sx={{ p: '5px', position: 'relative', display: 'flex', }}>
              {item.logo ? (
                <CardMedia 
                  component="img" 
                  sx={{ maxHeight: '124.89px', height: '124.89px', objectFit: 'contain'}} 
                  image={item.logo} 
                  alt={item.name} 
                />
              ) : (
                <ImageNotSupportedIcon sx={{ mx: 'auto', fontSize: '124.89px', color: 'gray' }} />
              )}
            </Box>
            <CardContent sx={{ padding: '12px', paddingBottom: '12px !important', display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="type_20_600_3E3E3E"
                sx={{
                  mb: '8px',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 1,
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis'
                }}
              >
                  {item.name}
              </Typography>
              <Typography
                variant="type_12_600_808080"
                sx={{
                  mb: '12px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  src={verifiedIcon}
                  alt="verifiedIcon"
                  sx={{
                    width: 20,
                    height: 20,
                    mr: '5px'
                  }}
                />
                  Verified by Agprop
              </Typography>
              <Typography
                variant="type_12_600_3E3E3E"
                sx={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 2,
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  minHeight: '30px'
                }}
              >
                {
                  item.requirementCount === 0 && item.locationCount === 0 ?
                  ''
                  : item.requirementCount < 99 && item.locationCount > 0 ? 
                  `${item.requirementCount} Requirements in ${item.locationCount} Locations`
                  : `99+ Requirements in ${item.locationCount} Locations`
                }
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Slider>
    )
    :
    (
      <Slider {...settings} className='skeltonSliderContainerWrap'>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item , index) => (
          <Card
            key={index+item}
            sx={{
              width: { lxs: '300px' },
              borderRadius: '18px',
              border: '1px solid #9EC1FF80',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Box sx={{ p: '5px', position: 'relative', display: 'flex' }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="124.89px"
                animation="wave"
                sx={{ borderRadius: '18px',  maxHeight: '124.89px', height: '124.89px' }}
              />
            </Box>
            <CardContent
              sx={{
                padding: '12px',
                paddingBottom: '12px !important',
                display: 'flex',
                flexDirection: 'column',
              }}
              >
              <Skeleton
                variant="text"
                width="70%"
                animation="wave"
                sx={{ mb: '8px' }}
              />
              <Skeleton
                variant="text"
                width="50%"
                animation="wave"
                sx={{ mb: '12px' }}
              />
              <Skeleton
                variant="text"
                width="90%"
                animation="wave"
                sx={{ minHeight: '30px' }}
              />
            </CardContent>
          </Card>
        ))}
      </Slider>
    )
  )
}

export default DashboardSlider;
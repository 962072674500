import React from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const handleNavigate = (type) => {
        if(type === 'home'){
            navigate('/')
        }
    }

    return (
        <Container
            sx={{
                mt: '120px',
                mx: {xs: '0.6', xsl: '1rem', sm: '2rem', md: '4rem', lg: '7.2rem' }
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    p: '15px',
                    borderRadius: '20px',
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '20px',
                }}
            >
                <Button
                    onClick={() => handleNavigate('home')}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        width: '30px',
                        minWidth: 'unset',
                        height: '30px',
                        p: '0px',
                        borderRadius: '50%',
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'unset',
                        '&:hover': {
                        backgroundColor: '#F2F2F2',
                        },
                        '&:active': {
                            backgroundColor: '#E0E0E0',
                        },
                        '&:focus': {
                            backgroundColor: '#EAEAEA',
                        },
                    }}
                >
                    <WestIcon color={'#000000'} sx={{ color: '#000000',  width: '24px', height: '24px' }}/>
                </Button>
                <Typography variant='type_14_600_424242' sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    Policy
                </Typography>
            </Box>
            <Paper elevation={3} sx={{ borderRadius: '25px', padding: '20px', my: '20px'  }}>
                <Typography sx={{ mb: '46px', fontFamily: 'Inter', textAlign: 'center', fontSize: '12px', fontWeight: '700', textDecoration: 'underline'}}>
                    DATA BREACH AND PRIVACY POLICY
                </Typography>

                <Typography variant="h6" paragraph>
                    Effective date: Jan 1, 2024
                </Typography>
                <Typography variant="h6" paragraph sx={{mb: '46px'}}>
                    Last updated on: Aug 1, 2024.
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '20px'}}>
                    The website <a href="https://agprop.in">https://agprop.in</a> and the mobile application 
                    {' ‘'}Propmagnet and Propvision{'’ '} (together “Website”) is owned and operated by Agprop Consultants LLP 
                    (“Company/Agprop”). The Company is engaged in the business of Real Estate and other related services (“Services”).
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '20px'}}>
                    This Privacy Policy (“Privacy Policy”) sets out the privacy practices of the Company with respect to 
                    the entire content of the Website.
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '20px'}}>
                    This document is published in accordance with the provisions of the Information Technology Act, 2000 and 
                    the rules made thereunder that require publishing the rules and regulations, privacy policy and terms of 
                    use on an online portal of the Company. We request you to go through this Privacy Policy and the Terms of 
                    Use carefully before you decide to access this Website.
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '20px'}}>
                    For the purposes of this Privacy Policy, the words “us”, “we”, and “our” refer to the Company and all 
                    references to “you”, “your” or “user”, as applicable mean the person who accesses, uses and/or participates 
                    in the Website in any manner or capacity.
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '20px'}}>
                    The protection and security of your personal information is our top priority and we have taken all necessary 
                    and reasonable measures to protect the confidentiality of the user information and its transmission through 
                    the internet.
                </Typography>

                <Typography variant="body1" paragraph sx={{mb: '46px', fontWeight: '700'}}>
                    By using our Services and the Website or by otherwise giving us your information, you agree to the terms of 
                    this Privacy Policy. You also expressly consent to our use and disclosure of your Personal Information 
                    (as defined below) in the manner prescribed under this Privacy Policy and further signify your agreement 
                    to this Privacy Policy and the Terms of Use. If you do not agree to this Privacy Policy, do not subscribe 
                    to the Services, use the Website or give us any of your information.
                </Typography>

                <Typography variant="h6" gutterBottom sx={{mb: '25px'}}>
                    1. <strong>COLLECTION OF INFORMATION</strong>
                </Typography>

                <Typography variant="body1" paragraph sx={{pl: '28px'}}>
                    We may collect information from you, through your use of the Website or by joining the Agprop community on 
                    social media websites, or which is provided to one of our marketing partners or through any engagement with 
                    Agprop. We may collect and process personal information provided by you, including but not limited to:
                </Typography>

                <Typography variant="body1" paragraph>
                    Information that you provide at the time of registration including any information that identifies or can be 
                    used to identify, contact or locate the user such as name, address, email address, property photos, bank details, 
                    and phone number.
                </Typography>

                <Typography variant="body1" paragraph>
                    Any data that is automatically captured by the Website such as your mobile phone operating system every 
                    computer / mobile device connected to the internet is given a domain name and a set of numbers that 
                    serve as that computer's Internet Protocol or “IP” address. When you request a page from any page within 
                    the Website, our web servers automatically recognize your domain name and IP address. The domain name 
                    and IP address reveal nothing personal about you other than the IP address from which you have accessed the Website.
                </Typography>

                <Typography variant="h6" gutterBottom sx={{mb: '20px'}}>
                    2. <strong>USE OF THE INFORMATION COLLECTED</strong>
                </Typography>

                <Typography variant="body1" paragraph sx={{pl: '28px'}}>
                    Use of the Information for Services The primary goal of the Company in collecting the information is to provide you a 
                    platform for the purpose of providing the Services. The Company may use the Personal Information provided by you in 
                    the following ways:
                </Typography>
                <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                    <ol type="a">
                        <li>To help provide you the Services;</li>
                        <li>To observe, improve and administer the quality of Service;</li>
                        <li>To analyze how the Website is used, diagnose technical problems;</li>
                        <li>Remember the basic information provided by you for effective access;</li>
                        <li>To confirm your identity in order to determine your eligibility to use the Website and avail the Services;</li>
                        <li>To notify you about any changes to the Website;</li>
                        <li>To enable the Company to comply with its legal and regulatory obligations;</li>
                        <li>For the purpose of sending administrative notices and Service-related alerts and similar communication, as detailed under this Privacy Policy, with a view to optimizing the efficiency of the Website;</li>
                        <li>Doing market research, troubleshooting, protection against error, project planning, fraud and other criminal activity; and</li>
                        <li>To enforce the Company’s Terms of Use.</li>
                        <li>Sale of Assets, Merger, Acquisition, Bankruptcy</li>
                        <li>Information collected from you may be transferred to a third party as a result of a sale or acquisition, merger or bankruptcy involving the Company.</li>
                    </ol>
                </Typography>

                <Typography variant="body1" sx={{display: 'block', mb: '20px'}}>
                    <strong>Cookies </strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    Cookies are small portions of information saved by your browser onto your computer / mobile. Cookies are used to record 
                    various aspects of your visit and assist the Company to provide you with uninterrupted service. Any information that is 
                    gathered by cookies or other web tracking technologies on the Website, and the use of the same is subject to the disclosures 
                    and options provided in the  Cookies Policy.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    3. <strong>SHARING OF INFORMATION </strong>
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Consulting</strong>
                </Typography>
                
                <Typography variant="body1" sx={{mb: '46px'}}>
                    The Company may partner with another party to provide certain specific services if required. When you sign-up 
                    for such services, the Company will share names, or other Personal Information that is necessary for the 
                    third party to provide these Services. The Company’s contractual arrangements with such third parties 
                    restrict these parties from using personally identifiable information except for the explicit purpose 
                    of assisting in the provision of these Services.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Targeted Advertising</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    You expressly acknowledge and agree that we may also share or transfer your Personal Information to third-party 
                    service providers, for the limited purpose of allowing or permitting such third-party service providers, 
                    including Google, and other social media websites to display Agprop advertisements and notifications on 
                    websites across the Internet, based on the information that is collected by Agprop, to optimize and display 
                    advertisements based on your past preferences and visits on the Website as part of its Services. The Company 
                    does not allow any unauthorized persons or organization to use any information that the Company may collect 
                    from you through this Website.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Regulatory Disclosures</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. 
                    Thus, we may access, use, store, transfer and disclose your information (including Personal Information), 
                    including disclosure to third parties such as government or law enforcement officials or private parties as 
                    we reasonably determine is necessary and appropriate: (i) to satisfy any applicable law, regulation, governmental 
                    requests or legal process; (ii) to protect the safety, rights, property or security of the Company, our Services, 
                    the Website or any third party; (iii) to protect the safety of the public for any reason; (iv) to detect, prevent 
                    or otherwise address fraud, security or technical issues; and /or (v) to prevent or stop any activity we consider 
                    to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity. Such disclosures may be 
                    carried out without notice to you.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Link to Third Part Websites</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    This Website may contain links which may lead you to other Websites. Please note that once you leave the Company’s 
                    Website you will be subjected to the privacy policy of the other Website. The linked websites are not necessarily 
                    under the control of the Company. Please be aware that the Company is not responsible for the privacy practices of 
                    such other sites. The Company encourages you to read the privacy policies of each and every website that collects 
                    Personal Information. If you decide to access any of the third-party sites linked to the Website, you do this 
                    entirely at your own risk. Any links to any partner of the Company should be the responsibility of the linking 
                    party, and the Company will not be responsible for notification of any change in name or location of any information on the Website.
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    We may further disclose your Personal Information to such third parties to whom we have transferred our rights and 
                    duties under any agreement entered into with such third parties and may also disclose your Personal Information 
                    to any of our affiliates, subsidiaries or related entity.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    4. <strong>SECURITY OF INFORMATION</strong>
                </Typography>

                <Typography variant="body1" paragraph sx={{pl: '28px'}}>
                    The Company has put in place appropriate methods and managerial procedures to safeguard and secure such Personal Information. 
                    The Company only processes Personal Information in a way that is compatible with and relevant for the purpose for 
                    which it was collected or authorized by the user. The Website allows users access to their Personal Information 
                    and allows them to correct, amend or delete inaccurate information.
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    The Company uses commercially reasonable precautions to preserve the integrity and security of your information 
                    against loss, theft, unauthorised access, disclosure, reproduction, use or amendment.
                </Typography>
                
                <Typography variant="body1" sx={{mb: '46px'}}>
                    The information that is collected from you may be transferred to, stored and processed at any destination 
                    within and / or outside India. By submitting information on the Website, you agree to this transfer, 
                    storing and / or processing. The Company will take such steps as it considers reasonably necessary 
                    to ensure that your information is treated securely and in accordance with this Privacy Policy.
                </Typography>
                
                <Typography variant="body1" sx={{mb: '46px'}}>
                    In using the Website, you accept the inherent security implications of data transmission over the internet. 
                    Therefore, the use of the Website will be at your own risk and the Company assumes no liability for any 
                    disclosure of information due to errors in transmission, unauthorised third-party access or other acts 
                    of third parties, or acts or omissions beyond its reasonable control and you agree not to hold the 
                    Company responsible for any breach of security.
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    In the event the Company becomes aware of any breach of the security of your information, it will 
                    promptly notify you and take appropriate action to the best of its ability to remedy such a breach.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    5. <strong>EXCLUSION</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    This Privacy Policy does not apply to any information other than information collected by the Company 
                    through the Website including such information collected in accordance with the clause on “Collection 
                    of Information” above. This Privacy Policy will not apply to any unsolicited information provided by 
                    you through this Website or through any other means. This includes, but is not limited to, information 
                    posted on any public areas of the Website. All such unsolicited information shall be deemed to be 
                    non-confidential and the Company will be free to use, disclose such unsolicited information without limitation.
                </Typography>
                
                <Typography variant="body1" sx={{mb: '46px'}}>
                    The Company also protects your Personal Information off-line other than as specifically mentioned in this 
                    Privacy Policy. Access to your Personal Information is limited to employees, agents or partners and third 
                    parties, who the Company reasonably believes will need that information to enable the Company to provide 
                    Services to you. However, the Company is not responsible for the confidentiality, security or distribution 
                    of your own Personal Information by our partners and third parties outside the scope of our agreement 
                    with such partners and third parties.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    6. <strong>DATA RETENTION</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    The Company shall not retain Personal Information longer than is necessary to fulfil the purposes for which 
                    it was collected and as permitted by applicable law. If you wish to cancel your account or request that 
                    the Company no longer use your information to provide you Services, contact us through email at 
                    info@agprop.in. Even after your account is terminated, the Company may retain your Personal 
                    Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any 
                    activities related to cancellation of an account, investigate or prevent fraud and other inappropriate 
                    activity, to enforce our agreements, and for other business reason. 
                </Typography>
                
                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    7. <strong>RIGHT TO WITHDRAW CONSENT</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    The consent that you provide for the collection, use and disclosure of your Personal Information will remain 
                    valid until such time it is withdrawn by you in writing. If you withdraw your consent, the Company will stop 
                    processing the relevant Personal Information except to the extent that the Company has other grounds for 
                    processing such Personal Information under applicable laws. The Company will respond to your request within a 
                    reasonable time frame. You may withdraw your consent at any time by contacting the Company or by using the 
                    functionalities of the Website. 
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    8. <strong>RIGHT TO CORRECTION</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    You are responsible for maintaining the accuracy of the information you submit to us, such as your contact 
                    information provided as part of account registration. The Company relies on the users to disclose to it 
                    all relevant and accurate information and to inform the Company of any changes. If you wish to make a 
                    request to correct or update any of your personal data which we hold about you, you may submit your 
                    request in writing or via email to info@agprop.in
                </Typography>
                
                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    9. <strong>ACCESS OUTSIDE INDIA</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    The Company makes no representation that the Website is appropriate to be used or accessed outside India. 
                    This document is published in accordance with the provisions of the Information Technology Act, 2000 and 
                    the Company is compliant with the laws of India. If any users use or access the Website from outside India, 
                    they do so at their own risk and are responsible for compliance with the laws of such jurisdiction and 
                    the Company shall not have any responsibility for or liability arising from, such compliance with local laws. 
                    These Privacy Policy does not constitute, nor may it be used for or in connection with, any promotional 
                    activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation 
                    is not authorized or to any person to whom it is unlawful to promote or solicit.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    10. <strong>NOTIFICATION OF CHANGES</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    From time to time, the Company may update this Privacy Policy to reflect changes to its information practices. 
                    Any changes will be effective immediately upon the posting of the revised Privacy Policy. If the Company makes 
                    any material changes, it will notify you by email (sent to the e-mail address specified in your account) or 
                    by means of a notice on the Services prior to the change becoming effective. We encourage you to periodically 
                    review this page for the latest information on our privacy practices.
                </Typography>
                
                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    11. <strong>OPT IN AND OPT OUT PROCEDURES</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    Upon registration, you may receive certain communication from the Company and you may at any time choose 
                    to opt-out of receiving further communications from the Company. To be completely removed from the Company’s 
                    mailing list, you may contact us at info@agprop.in. If you are using an e-mail forwarding service or other 
                    similar service please make sure to include the correct e-mail address or addresses.
                </Typography>
                                
                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    12. <strong>CONTACTS PERMISSION</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    On registration or App download you might be asked for access to your phonebook contact details.
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    13. <strong>GRIEVANCE OFFICER</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    In accordance with the Information Technology Act, 2000 and the rules made thereunder, the name and contact 
                    details of the Grievance Officer are provided below. Any User grievance relating to the discrepancies or misuse 
                    of information so provided to Agprop may be addressed to the grievance officer, whose details are provided 
                    below appointed by Agprop for this purpose. 
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Name:</strong>
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Designation:</strong>
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Contact Number:</strong>
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    <strong>Email Id:</strong>
                </Typography>
                
                <Typography variant="body1" sx={{mb: '46px'}}>
                    The grievance officer shall address the grievance within one month of the date of receipt of such grievance 
                    from the User. 
                </Typography>

                <Typography variant="h5" sx={{display: 'block', mb: '20px'}}>
                    14. <strong>ADDRESS FOR PRIVACY QUESTIONS</strong>
                </Typography>

                <Typography variant="body1" sx={{mb: '46px'}}>
                    Should you have questions about this Privacy Policy or Company’s information collection, use and disclosure 
                    practices, you may contact us at: info@agprop,in. We will use reasonable efforts to respond promptly to requests, 
                    questions or concerns you may have regarding our use of Personal Information about you. Except where required by 
                    law, the Company cannot ensure a response to questions or comments regarding topics unrelated to this policy or 
                    Company’s privacy practices. 
                </Typography>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;

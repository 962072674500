import { FormControl, InputLabel, MenuItem, Select, IconButton, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomSelectBox = ({name, label, options, value='', handleChange, handleClear, disabled=false }) => (
  <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel>{label}</InputLabel>
    <Box display="flex" alignItems="center" position="relative">
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        label={label}
        IconComponent={value ? () => null : ArrowDropDownIcon}
        sx={{
          width: '100%',
          borderRadius: '8px',
          backgroundColor: '#F8F8F8',
          '& .MuiSelect-select': {
            padding: '14px 12px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #D4D4D4',
          },
        }}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {value && !disabled && (
        <IconButton
          size="small"
          onClick={() => handleClear(name || '')}
          edge="end"
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  </FormControl>
);

export default CustomSelectBox;

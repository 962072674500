import { Box, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import techDrivenSolutions from '../../../assets/png/techDrivenSolutions.png';
import propvisionLogoWithText from '../../../assets/svg/propvisionLogoWithText.svg';
import propmagnetLogoWithText from '../../../assets/svg/propmagnetLogoWithText.svg';

const RealEstateSection = ({type=''}) => {
    const [loadingStates, setLoadingStates] = useState({
        isTechDrivenSolutionsLoading: true,
    });

    const setLoadingState = (key, value) => {
        setLoadingStates(prev => ({
            ...prev,
            [key]: value,
        }));
    };
    return (
        <Box className={'propvisionSectionWrap'} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant='type_24_500_404040' sx={{mb: '11px'}}>
                Real Estate Simplified
            </Typography>
            {/* <Typography variant='type_72_500_404040' sx={{ textAlign: 'center', mb: '49px'}}>
                Tech-Driven Solutions
            </Typography> */}
            <Box
                component='img'
                src={type === 'magnet' ? propmagnetLogoWithText : propvisionLogoWithText }
                alt={type === 'magnet' ? 'propmagnetLogoWithText' : 'propvisionLogoWithText' }
                sx={{
                    maxWidth: '248px',
                    objectFit: 'contain',
                    pb: '16px',
                }}
            />
            {loadingStates.isTechDrivenSolutionsLoading && (
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                        width: '100%',
                        maxWidth: { xs: '250px', xsl: '350px', sm: '500px', md: '856px'},
                        height: { xs: '111px', xsl: '202px', sm: '269px', md: '426px', lg: '473px'},
                    }}
                />
            )}
            <Box
                component='img'
                src={techDrivenSolutions}
                alt='techDrivenSolutions'
                onLoad={() => setLoadingState('isTechDrivenSolutionsLoading', false)}
                sx={{
                    maxWidth: { xs: '250px', xsl: '350px', sm: '500px', md: '856px'},
                    objectFit: 'contain',
                    pb: '47.65px',
                    display: loadingStates.isTechDrivenSolutionsLoading ? 'none' : 'block',
                }}
            />
        </Box>  
    )
}

export default RealEstateSection;
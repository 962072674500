import propVisionLogo from '../assets/svg/propVisionLogo.svg'
import propMagnetLogo from '../assets/svg/propMagnetLogo.svg'
import propvisionOurFeatures1 from '../assets/png/propvisionOurFeatures1.png';
import propvisionOurFeatures2 from '../assets/png/propvisionOurFeatures2.png';
import propvisionOurFeatures3 from '../assets/png/propvisionOurFeatures3.png';
import propvisionOurFeatures4 from '../assets/png/propvisionOurFeatures4.png';
import propmagnetOurFeatures1 from '../assets/png/propmagnetOurFeatures1.png';
import propmagnetOurFeatures2 from '../assets/png/propmagnetOurFeatures2.png';
const CONSTANTS = {
  REGEX: {
    EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
    NAME_PATTERN: /^[A-Za-z\s]+$/,
    PINCODE_PATTERN: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
    MAP_URL_PATTERN: /^(https?:\/\/)(.*map.*)$/i,
  },
  propvisionCards: [
    { 
      title: 'Enhance Brand Visibility with Propvision',
      description: 'Tailor property monitoring requirements for improved oversight. With Propvision.',
      bgColor: '#FFCC8A'
    },
    { 
      title: 'Maximize Property Exposure with Propvision',
      description: 'Tailor property monitoring requirements for improved oversight. With Propvision.',
      bgColor: '#DAD4FF'
    },
    { 
      title: 'Efficient Real Estate Operations with Propvision',
      description: 'Tailor property monitoring requirements for improved oversight. With Propvision.',
      bgColor: '#ACE6A6'
    },
    { 
      title: 'Empower Brand Control with Propvision',
      description: 'Tailor property monitoring requirements for improved oversight. With Propvision.',
      bgColor: '#DBFD91'
    },
  ],
  promagnetCards: [
    { 
      title: 'Enhance Brand Visibility with Propmagnet',
      description: 'Tailor property monitoring requirements for improved oversight. With Propmagnet.',
      bgColor: '#FFCC8A'
    },
    { 
      title: 'Maximize Property Exposure with Propmagnet',
      description: 'Tailor property monitoring requirements for improved oversight. With Propmagnet.',
      bgColor: '#DAD4FF'
    },
    { 
      title: 'AI-Driven Real Estate Queries',
      description: 'Leverage agprop AI to get instant answers to your real estate queries.',
      bgColor: '#ACE6A6'
    },
    { 
      title: 'Trend Tracking Efficiency',
      description: 'Stay ahead with efficient tools for faster trend tracking in the real estate market.',
      bgColor: '#DBFD91'
    },
  ],
  ourApproachData: [
    {
      title: 'Digitalize',
      description1: 'Harnessing advanced data tools and analytical mechanisms, we deliver optimal real estate solutions tailored to your needs.',
      description2: 'Leveraging cutting-edge technology is vital for maximizing returns and enhancing efficiency.',
    },
    {
      title: 'Democratize',
      description1: 'Empowering businesses and industry stakeholders with access to specialized knowledge for every market segment.',
      description2: 'This inclusive approach fosters sustainable real estate strategies and informed decision-making.',
    },
    {
      title: 'Institutionalize',
      description1: 'Expanding networks across cities and establishing a pan-India reputation for reliability.',
      description2: 'We ensure transparency and equity through our intuitive user interface and comprehensive information channels.',
    },
  ],
  headerComp: {
    headerPages: ['Requirements', 'Products', 'About', 'Contact Us'],
    headerSettings: [
      { name: 'Propvision', icon: propVisionLogo, url: process.env.REACT_APP_PROPVISION_URL || '' },
      { name: 'Propmagnet', icon: propMagnetLogo, url: process.env.REACT_APP_PROPMAGNET_URL || ''},
    ],
    productsSettings: [
      {
        name: 'Propvision',
        icon: propVisionLogo,
        url: '/propvision',
        description: 'Propvision is a powerful property management tool revolutionizes the real estate expansion experience.'
      },
      {
        name: 'Propmagnet',
        icon: propMagnetLogo,
        url: '/propmagnet',
        description: 'Propmagnet is specifically desgined for Brokers to seamlessly customize and match properties according to specific...'
      },
    ],
  },
  propvisionCardsData: [
    {
      title: 'Expansion Requirement',
      description1: 'Enhance Brand Visibility with Propvision',
      description2: 'Tailor property monitoring requirements for improved oversight. With Propvision, efficiently list and manage real estate assets to maximize brand efficiently.',
      image: propvisionOurFeatures1,
    },
    {
        title: 'Magic Links',
        description1: 'Maximize Property Exposure with Propvision',
        description2: 'Receive personalized URLs tailored to specific property needs. Share them effortlessly with brokers and real estate companies to enhance property visibility.',
        image: propvisionOurFeatures2,
    },
    {
        title: 'Property Management',
        description1: 'Efficient Real Estate Operations with Propvision',
        description2: 'Streamline property uploads, site verification, and all facets of brand real estate operations. Experience seamless efficiency with Propvision\'s comprehensive property management solutions.',
        image: propvisionOurFeatures3,
    },
    {
        title: 'Access and Control',
        description1: 'Empower Brand Control with Propvision',
        description2: 'Gain full control over property listings with an adaptable interface. Customize permissions and update property requirements as needed for enhanced management.',
        image: propvisionOurFeatures4,
    },
  ],
  propmagnetCardsData: [
    {
        title: 'Brand Requirement',
        description1: 'Customized Property Oversight',
        description2: 'A SaaS solution that empowers brokers and HNIs (High Networth Individuals) with efficient property management by facilitating seamless information sharing. This platform streamlines operations, enhancing productivity and effectiveness in managing properties.',
        image: propmagnetOurFeatures1,
    },
    {
        title: 'Access and Control',
        description1: 'Flexible Management Solutions',
        description2: 'Flexible Management Solutions for your evergrowing team: The user-friendly interface provides adaptability for internal management requirements. Customize permissions to regulate and update property listings, ensuring flexibility and autonomy.',
        image: propmagnetOurFeatures2,
    }
  ],
  propmagnetSmallCardsData: [
    {   
        id: 1,
        title1: 'AI-Driven Real Estate',
        title2: 'Queries',
        description: 'Leverage agprop AI to get instant answers to your real estate queries.'
    },
    {   
        id: 2,
        title1: 'Trend Tracking',
        title2: 'Efficiency',
        description: 'Stay ahead with efficient tools for faster trend tracking in the real estate market.'
    },
    {   
        id: 3,
        title1: 'Smart',
        title2: 'Calculators',
        description: 'Use our smart calculators for accurate property valuations and financial planning.'
    },
    {   
        id: 4,
        title1: 'Proposal',
        title2: 'Generators',
        description: 'Generate professional proposals quickly with our intuitive proposal generators.'
    },
    {   
        id: 5,
        title1: 'Auto-Property',
        title2: 'Match',
        description: 'Experience smart search with auto-property matching that alerts you to perfect listings.'
    },
  ],
  reviewData: [
    {
      description: 'I strongly recommend agprop and Shivek as an outstanding research consultant. I had the pleasure of working with him on a project to expand across multiple states, and I was consistently impressed by his hardworking, data-driven approach. Research was thorough and well-informed, and he provided valuable insights that helped us make informed decisions.',
      name: 'Ammol Ramtteke',
      title: 'Head Business Development at Haldiram Foods International Ltd',
      avatar: '/path/to/avatar1.jpg',
    },
    {
      description: 'Agprop and Shivek is a very hard-working person who can tackle problems with ease. We have worked together on several projects, and I found him a highly skilled and dedicated professional. His expertise in his field has helped our company immensely. He’s also an all-around great guy to work with! He also possesses excellent communication skills, sets realistic goals, and gives reasonable deadlines for project milestones. I highly recommend him to anyone looking for help in Real Estate and Finance sector.',
      name: 'Rohan Makkar',
      title: 'Business Development Manager at Xero Degrees Cafe Pvt Ltd',
      avatar: '/path/to/avatar2.jpg',
    },
  ]
};

export default CONSTANTS;

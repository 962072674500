import { createSlice } from '@reduxjs/toolkit';
import { fetchBrandData, fetchBrandDetails, fetchBrandReqList, fetchTopBrandList } from './apiThunk';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';

const initialData = {
  topBrand: {},
};

const topBrandListSlice = createSlice({
  name: 'topBrand',
  initialState: { ...initialData },
  reducers: {
    updateTopBrandData: (state, action) => {
      state.topBrand = action.payload;
    },
    clear: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTopBrandList.fulfilled, (state, action) => {
      state.topBrand = action?.payload;
    });
    builder.addCase(fetchTopBrandList.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchBrandReqList.fulfilled, (state, action) => {
      state.topBrand = action?.payload;
    });
    builder.addCase(fetchBrandReqList.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchBrandDetails.fulfilled, (state, action) => {
      state.topBrand = action?.payload;
    });
    builder.addCase(fetchBrandDetails.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchBrandData.fulfilled, (state, action) => {
      state.topBrand = action?.payload;
    });
    builder.addCase(fetchBrandData.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
  },
});

const {
  actions: { updateTopBrandData, clear },
  reducer,
} = topBrandListSlice;

const selectorTopBrand = (state) => state.topBrandListSlice;

export { updateTopBrandData, clear, selectorTopBrand };

export default reducer;

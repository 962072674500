import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import PublicLayout from '../layout/PublicLayout';
import Dashboard from '../pages/Dashboard';
import Error from '../pages/404.jsx';
import Propvision from '../pages/Propvision/index.jsx';
import Propmagnet from '../pages/Propmagnet/index.jsx';
import BrandRequirementList from '../pages/BrandRequirementList/index.jsx';
import AllRequirementsList from '../pages/AllRequirementsList/index.jsx';
import About from '../pages/About/index.jsx';
import ContactUs from '../pages/ContactUs/index.jsx';
import TermsConditions from '../pages/TermsConditions/index.jsx';
import PrivacyPolicy from '../pages/PrivacyPolicy/index.jsx';
//import SubmitProperty from '../pages/SubmitProperty/index.jsx';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.DASHBOARD,
    element: (
      // <PublicLayout privateRoute={true}>
      //   <Dashboard />
      // </PublicLayout>
      <PublicLayout  title={'Agprop'}>
        <Dashboard />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.PROPVISION,
    element: (
      <PublicLayout title={'Propvision'}>
        <Propvision />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.PROPMAGNET,
    element: (
      <PublicLayout title={'Propmagnet'}>
        <Propmagnet />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.BRAND_REQUIREMENT_LIST,
    element: (
      <PublicLayout title={'Requirements'}>
        <BrandRequirementList />
      </PublicLayout>
    ),
  },
  {
    path: `${ROUTE_PATH.ALL_REQUIREMENT_LIST}/:reqListId`,
    element: (
      <PublicLayout title={'All Requirements'}>
        <AllRequirementsList />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.ABOUT,
    element: (
      <PublicLayout title={'About'}>
        <About />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.CONTACT_US,
    element: (
      <PublicLayout title={'Contact Us'}>
        <ContactUs />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.TERMS_CONDITIONS,
    element: (
      <PublicLayout title={'Terms and Condition'}>
        <TermsConditions />
      </PublicLayout>
    ),
  },
  {
    path: ROUTE_PATH.PRIVACY_POLICY,
    element: (
      <PublicLayout title={'Privacy Policy'}>
        <PrivacyPolicy />
      </PublicLayout>
    ),
  },
  // {
  //   path: `${ROUTE_PATH.SUBMIT_PROPERTY}/:reqId`,
  //   element: (
  //     <PublicLayout>
  //       <SubmitProperty />
  //     </PublicLayout>
  //   ),
  // },
  {
    path: '*',
    element: (
      <PublicLayout title={'Error'}>
        <Error />
      </PublicLayout>
    ),
  },
]);

export default routes;

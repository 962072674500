import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function CustomSearchBar({
  placeholder,
  value,
  onChange,
  onSearch,
}) {
  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };
  return (
    <Paper
      component="form"
      onSubmit={handleFormSubmit}
      sx={{
        border: '1px solid #D4D4D4',
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 268,
        width: '100%',
        backgroundColor: '#F8F8F8',
        borderRadius: '8px'
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

import { Box, Divider, Grid, Typography, Skeleton } from '@mui/material';
import { useState } from 'react';
import StyledContactUs from './StyledContactUs';
import { ReactComponent as EmailIcon } from '../../assets/svg/emailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/svg/phone.svg';
import contactUsImage from '../../assets/png/contactUsImage.png';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import ContactUsForm from '../../components/ContactUsForm';
import 'react-phone-number-input/style.css';

const ContactUs = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <StyledContactUs>
            <Box className={'contactUsWrap'}>
                <Grid
                    container
                    spacing={4}
                    sx={{
                        ml: 'unset',
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                        mt: { xs: '95px', md: '120px', lg: '154px'},
                        pt: {xs: '0px', sm: '32px'},
                        borderRadius: '24px',
                        pb: '32px',
                        mb: '100px',
                        pr: {xs: '0px', sm: '32px', md: '0px' }
                    }}
                >
                    <Grid item xs={12} md={6} sx={{ pl: {xs: '0px !important', sm: '32px !important' }, pt: '0px !important', pr: '0px', pb: {xs: '30px', md: '0px'}}}>
                        {isLoading && (
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                    width: '100%',
                                    height: { xs: '394px', xsl: '682px', sm: '929px', md: '591px', lg: '716px'},
                                    borderRadius: '8px'
                                }}
                            />
                        )}
                        <Box
                            component="img"
                            src={contactUsImage}
                            alt="contactUsImage"
                            onLoad={() => setIsLoading(false)}
                            sx={{width: '100%', height: 'auto', borderRadius: '8px', display: isLoading ? 'none' : 'block'}}
                        />
                    </Grid>
                    <ContactUsForm />
                </Grid>
                <Grid container spacing={2} sx={{mb: '100px', ml: 'unset', width: '100%'}}>
                    <Grid item xs={12} md={3.5}>
                        <Typography variant="type_32_600_464646" gutterBottom>
                            Contact us for Business Solutions
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3.8} sx={{mb: {xs: '25px', sm: '0px' }}}>
                        <Typography variant="type_18_400_414141" sx={{display: 'block', mb: '14px'}}>Address 1</Typography>
                        <Typography variant="type_36_500_414141">Corporate Office</Typography>
                        <Divider sx={{ width: '70%', my: '19px',  }} />
                        <Typography variant="type_16_500_414141" sx={{ display: 'block', mb: '24px'}}>
                            Second floor, MM Tower, Passport Seva Kendra, 
                            Sub. Major Laxmi Chand Rd, Sector 18, Gurugram, 
                            Shahpur, Haryana 122022
                        </Typography>
                        <Grid container alignItems="center" sx={{ mb: '10px' }}>
                            <Box
                                component={EmailIcon}
                                sx={{
                                    mr: '10px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'black',
                                    '& path': { stroke: 'black' },
                                }}
                            />
                            <Typography variant="type_16_400_414141">
                                <a
                                    href="mailto:info@agprop.in"
                                    style={{ textDecoration: 'none', color: '#414141'}}
                                    className="footer-link"
                                >
                                    info@agprop.in
                                </a>
                            </Typography>
                        </Grid>
                        <Grid container alignItems="center" sx={{ mt: 1 }}>
                            <Box
                                component={PhoneIcon}
                                sx={{
                                    mr: '10px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'black',
                                }}
                            />
                            <Typography variant="type_16_400_414141">7070707679</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4.7}>
                        <Typography variant="type_18_400_414141" sx={{display: 'block', mb: '14px'}}>Address 2</Typography>
                        <Typography variant="type_36_500_414141">Delhi</Typography>
                        <Divider sx={{ width: '70%', my: '19px',  }} />
                        <Typography variant="type_16_500_414141" sx={{ display: 'block', mb: '40px'}}>
                            E-367, Nirman Vihar, First floor, New Delhi 110092
                        </Typography>
                        <Grid container alignItems="center" sx={{ mt: 1 }}>
                            <Box
                                component={EmailIcon}
                                sx={{
                                    mr: '10px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'black',
                                    '& path': { stroke: 'black' },
                                }}
                            />
                            <Typography variant="type_16_400_414141">
                                <a
                                    href="mailto:info@agprop.in"
                                    style={{ textDecoration: 'none', color: '#414141'}}
                                    className="footer-link"
                                >
                                    info@agprop.in
                                </a>
                            </Typography>
                        </Grid>
                        <Grid container alignItems="center" sx={{ mt: 1 }}>
                            <Box
                                component={PhoneIcon}
                                sx={{
                                    mr: '10px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'black',
                                }}
                            />
                            <Typography variant="type_16_400_414141">7070707679</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <SubscribeToNewsletter className={'contactUsWrapPadding'}/>
        </StyledContactUs>
    )
}

export default ContactUs;
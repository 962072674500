import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import { fetchContactUs, fetchSubscribe } from './apiThunk';

const initialData = {
  contactInfo: {},
  subscribe: {},
};

const contactDataSlice = createSlice({
  name: 'contactData',
  initialState: { ...initialData },
  reducers: {
    updateContactData: (state, action) => {
      state.contactInfo = action.payload;
    },
    clear: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContactUs.fulfilled, (state, action) => {
      state.contactInfo = action?.payload;
    });
    builder.addCase(fetchContactUs.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchSubscribe.fulfilled, (state, action) => {
      state.subscribe = action?.payload;
    });
    builder.addCase(fetchSubscribe.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
  },
});

const {
  actions: { updateContactData, clear },
  reducer,
} = contactDataSlice;

const selectorContactData = (state) => state.contactDataSlice;

export { updateContactData, clear, selectorContactData };

export default reducer;

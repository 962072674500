import { styled } from '@mui/system';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const StyledToast = styled(ToastContainer)(({ theme }) => ({
  '& .Toastify__close-button': {
    marginTop: '0.5rem',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  '& .Toastify__toast-container': {
    textAlign: 'center',
  },
  '& .Toastify__toast': {
    minHeight: '1rem',
  },

  '& .Toastify__toast-icon': {
    '& svg': {
      fill: theme.palette.customColor.primary,
    },
  },
  '& .Toastify__toast--info': {
    background: theme.palette.customColor.secondaryFirst,
    borderRadius: '0',
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '12px',
  },
  '& .Toastify__toast--success': {
    background: theme.palette.customColor.secondaryFirst,
    borderRadius: '0',
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '12px',
    wordBreak: 'break-word',
  },
  '& .Toastify__toast--error': {
    background: theme.palette.customColor.secondaryFirst,
    borderRadius: '0',
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '12px',
    wordBreak: 'break-word',
  },
}));

import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import experienceFuturePropvision from '../../../assets/png/experienceFuturePropvision.png';
import experienceFuturePropmagnet from '../../../assets/png/experienceFuturePropmagnet.png';

const ExperienceFuture = ({type, className}) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <Box sx={{backgroundColor: '#FFFFFF'}}>
            <Box className={className}
                sx={{
                    backgroundColor: '#EFEFEF',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: '59.25px',
                    borderRadius: '32px 32px 0 0',
                }}
            >
                <Typography variant='type_32_600_414141' sx={{mb: '10px', textAlign: 'center'}}>
                    Experience the future of real estate with{ type === 'magnet' ? ' Propmagnet' : ' Propvision'}!
                </Typography>
                <Box textAlign="start" display="flex" alignItems={'center'} sx={{mb: '49px'}}>
                    <Button
                        variant="contained"
                        sx={{
                            background: '#0048D0',
                            py: '7px',
                            px: '15px',
                            borderRadius: '2rem',
                            textTransform: 'none',
                            lineHeight: 'unset'
                        }}
                        href={type === 'magnet' ? process.env.REACT_APP_PROPMAGNET_URL : process.env.REACT_APP_PROPVISION_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography variant="type_12_500_FFFFFF">
                            Explore more
                        </Typography>
                    </Button>
                </Box>
                {isLoading && (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            width: {  xs: 'calc(100% - 60px)',  sm: 'calc(100% - 80px)', lg: 'calc(100% - 157.6px)'},
                            height: { xs: '77px', xsl: '158px', sm: '236px', md: '308px', lg: '336px'},
                            borderRadius: '8px'
                        }}
                    />
                )}
                <Box
                    component='img'
                    src={ type === 'magnet' ? experienceFuturePropmagnet : experienceFuturePropvision }
                    alt='experienceFuturePropvision'
                    onLoad={() => setIsLoading(false)}
                    sx={{
                        width: {  xs: 'calc(100% - 60px)',  sm: 'calc(100% - 80px)', lg: 'calc(100% - 157.6px)'},
                        mx: { xs: '30px', sm: '40px', lg: '78.8px'},
                        objectFit: 'contain',
                        display: isLoading ? 'none' : 'block',
                    }}
                />
            </Box>
        </Box>
    )
}

export default ExperienceFuture;
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import BedroomChildOutlinedIcon from '@mui/icons-material/BedroomChildOutlined';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import PropaneTankOutlinedIcon from '@mui/icons-material/PropaneTankOutlined';
import FireHydrantAltOutlinedIcon from '@mui/icons-material/FireHydrantAltOutlined';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import RoofingOutlinedIcon from '@mui/icons-material/RoofingOutlined';
import { Box, Divider, Grid, IconButton, Popover, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import verifiedIcon from '../../assets/svg/verifiedIcon.svg';
import airConditioning from '../../assets/svg/air_conditioning.svg';
import { ReactComponent as PowerBackup } from '../../assets/svg/powerBackup.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/share.svg';
import { ToastNotifySuccess } from '../Toast/ToastNotify';

export default function BrandReqDetailsModal({brandReqDetail, open, onClose }) {
    const [imageError, setImageError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const brandReqUrl = `${process.env.REACT_APP_PROPMAGNET_URL}brand_requirements/public/${brandReqDetail?._id}`;

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(brandReqUrl);
        ToastNotifySuccess('URL copied to clipboard!');
    };

    const handleOpenLink = () => {
        window.open(brandReqUrl, '_blank');
    };

    const openTemp = Boolean(anchorEl);
    const id = openTemp ? 'share-popover' : undefined;

    useEffect(() => {
        setImageError(false);
        setLoading(true);
        const img = new Image();
        img.src = brandReqDetail?.brand?.logo;
        img.onload = () => handleImageLoad();
        img.onerror = () => handleImageError();
    }, [brandReqDetail?.brand?.logo]);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleImageError = () => {
        setImageError(true);
        setLoading(false);
    };

    // const handleSubmitProperty = () => {
    //     navigate(`/submit-property/${brandReqDetail?._id}`);
    // }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='responsive-dialog-title'
            slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'blur(6px)',
                        backgroundColor: 'rgba(1, 10, 19, 0.4)',
                    },
                },
            }}
            PaperProps={{
                sx: {
                    maxWidth: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 124px 0px rgba(0, 0, 0, 0.45)',
                    borderRadius: '16px',
                    padding: { xs: '25px', sm: '36px'},
                    margin: { xs: '40px', sm: '80px', md: '120px'}
                },
            }}
        >
            <Button
                sx={{
                    position: 'absolute',
                    top: {xs: '26px', sm: '36px'},
                    right: '36px',
                    minWidth: 'unset',
                    width: '28px',
                    height: '28px',
                    padding: 0,
                    color: '#FFF',
                    backgroundColor: '#363636',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: 'rgba(54, 54, 54, 0.8)',
                    },
                }}
                onClick={onClose}
            >
                <CloseIcon
                    sx={{
                        width: '17px',
                        height: '17px',
                    }}
                />
            </Button>
            <Button
                sx={{
                    position: 'absolute',
                    top: {xs: '26px', sm: '36px'},
                    right: '70px',
                    minWidth: 'unset',
                    width: '28px',
                    height: '28px',
                    padding: 0,
                    color: '#FFF',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '50%',
                    border: '1px solid #1976d2',
                    '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                    },
                }}
                onClick={handleOpenPopover}
            >
                <ShareIcon
                    sx={{
                        width: '14px',
                        height: '14px',
                    }}
                />
            </Button>
            { anchorEl && (
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{
                        marginLeft: { xs: '0%', xxl: '-5%', sm: '-12%'},
                        marginTop: {xs: '10px', lg: '5px'}
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            gap: { xs: '5px', sm: '10px'},
                        }}
                    >
                        <TextField
                            value={brandReqUrl}
                            variant='outlined'
                            size='small'
                            disabled
                            sx={{ flexGrow: 1 }}
                        />
                        <Tooltip title="Copy to clipboard" arrow>
                            <IconButton onClick={handleCopy}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Open in new tab" arrow>
                            <IconButton onClick={handleOpenLink}>
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Popover>
            )}
            <DialogTitle
                id='responsive-dialog-title'
                sx={{
                    padding: '0',
                    pr: '75px',
                    marginBottom: '10px',
                    textAlign: 'left',
                    lineHeight: 'unset',
                }}
            >
                <Typography variant='type_24_600_414141'>
                    Brand Requirement Details
                </Typography>
            </DialogTitle>
            <Divider sx={{mb: '16px', width: '100%', height: '1px', background: '#D3D7DE', border: 'unset'}} />
            <Box
                sx={{
                    color: '#C7CDD2',
                    textAlign: 'left',
                }}
            >
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, alignItems: {xs: 'start', sm: 'center'}, mb: '16px'}}>
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140 }}>
                            <Skeleton
                                animation='wave'
                                variant='rectangular'
                                sx={{
                                    width: '100%',
                                    height: {xs: '140px'},
                                }}
                            />
                        </Box>
                    )}
                    {brandReqDetail?.brand?.logo && !imageError ? (
                        <Box
                            component='img'
                            src={brandReqDetail?.brand?.logo}
                            alt='logo'
                            sx={{
                                width: '80px',
                                height: '80px',
                                objectFit: 'fill',
                                borderRadius: '8px',
                                mr: '16px',
                            }}
                        />
                    ) : (
                        imageError ? (
                        <BrokenImageIcon sx={{ height: '80px', width: '80px', fontSize: '124.89px', color: 'gray' }} />
                        ) : (
                        <ImageNotSupportedIcon sx={{ height: '80px', width: '80px', fontSize: '124.89px', color: 'gray' }} />
                        )
                    )}
                    <Typography
                        variant='type_24_700_414141'
                        sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: {xs: 3, sm: 2},
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {brandReqDetail?.brand?.name}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ mb: '12px', display: 'flex', alignItems: 'center' }}>
              <Box
                component='img'
                src={verifiedIcon}
                alt='verifiedIcon'
                sx={{
                  width: 20,
                  height: 20,
                  mr: '5px'
                }}
              />
              <Typography variant='type_12_600_808080' >
                Verified by Agprop
              </Typography>
            </Box>
            <Box sx={{width: '100%', mb: '24px'}}>
                <Typography variant='type_24_600_414141' sx={{lineHeight: '1', mb: '24px', display: 'inline-block'}}>Property Details</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={1.6} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography variant='type_16_600_808080' >
                                Area
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail.area_name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1.4} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography variant='type_16_600_808080' >
                                City
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.city}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1.4} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography variant='type_16_600_808080' >
                                State
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.state}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1.4} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography variant='type_16_600_808080' >
                            Pincode
                        </Typography>
                        <Typography variant='type_20_600_414141' >
                            {brandReqDetail.pincode}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2.1} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography variant='type_16_600_808080' >
                            Carpet Area(SQFT)
                        </Typography>
                        <Typography variant='type_20_600_414141' >
                            {brandReqDetail.carpet_area}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1.7} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography variant='type_16_600_808080' >
                            Super Area
                        </Typography>
                        <Typography variant='type_20_600_414141' >
                            {brandReqDetail.super_area + ' sq.ft'}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2.3} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography variant='type_16_600_808080' >
                            Below Beam Height
                        </Typography>
                        <Typography variant='type_20_600_414141' >
                            {brandReqDetail.below_beam_height + ' ft'}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1.5} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography variant='type_16_600_808080' >
                            Frontage
                        </Typography>
                        <Typography variant='type_20_600_414141' >
                            {brandReqDetail.frontage + ' ft'}
                        </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{width: '100%', height: '1px', background: '#D3D7DE', border: 'unset', mb: '24px'}} />
            <Box sx={{width: '100%', mb: '24px'}}>
                <Typography variant='type_24_600_414141' sx={{ lineHeight: '1', mb: '24px', display: 'inline-block'}}>
                    Amenities
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}} variant='type_16_600_808080' >
                            <Box 
                                component={PowerBackup} 
                                width={24} 
                                height={24} 
                                sx={{
                                    mr: '4px',
                                    '& path': { fill: '#808080' }, 
                                }} 
                            /> 
                                Power Backup
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.power_backup || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{gap: '4px', display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <BedroomChildOutlinedIcon sx={{ mr: '4px' }}/>
                                Flooring
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.flooring || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <LocalParkingIcon sx={{ mr: '4px' }}/>
                                Parking
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.parking || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <WaterDropOutlinedIcon sx={{ mr: '4px' }}/>
                                Water Supply
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.water_supply || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <WcOutlinedIcon sx={{ mr: '4px' }}/>
                                Washroom
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.washroom || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <PropaneTankOutlinedIcon sx={{ mr: '4px' }}/>
                                Gas Bank
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.gas_bank || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{pt: '32px !important'}}>
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <FireHydrantAltOutlinedIcon sx={{ mr: '4px' }}/>
                                Fire System
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.fire_system || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{pt: '32px !important'}}>
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <DoorSlidingOutlinedIcon sx={{ mr: '4px' }}/>
                                Glass Gazing
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.glass_glazing || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{pt: '32px !important'}}>
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{gap: '5.5px', display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <img src={airConditioning} alt='airConditioning'/>
                                Air Conditioning
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.air_conditioning || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{pt: '32px !important'}}>
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{ gap: '5px', display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <RoofingOutlinedIcon />
                                Roof Available
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.roof_usable_area || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{pt: '32px !important'}}>
                        <Box  sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                            <Typography sx={{gap: '4px', display: 'flex', alignItems: 'center'}}  variant='type_16_600_808080' >
                                <ElevatorOutlinedIcon />
                                Lift
                            </Typography>
                            <Typography variant='type_20_600_414141' >
                                {brandReqDetail?.amenities?.lift || 'NA' }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{width: '100%', height: '1px', background: '#D3D7DE', border: 'unset', mb: '16px'}} />
            <Box sx={{width: '100%', textAlign: 'right'}}>
                <Button
                    href={brandReqUrl}
                    target={'_blank'}
                    rel='noopener noreferrer'
                    sx={{
                        border: '1px solid #36A4D7',
                        borderRadius: '48px',
                        color: '#FFF',
                        textTransform: 'unset',
                        padding: '15.5px 20px',
                        backgroundColor: '#0048D0',
                        '&:hover': {
                            backgroundColor: '#336FDB',
                        },
                    }}
                    onClick={() => onClose()}
                >
                    <Typography variant='type_14_600_FFFFFF'>
                        Submit your Property
                    </Typography>
                </Button>
            </Box>
        </Dialog>
    );
}

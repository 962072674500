import { createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../../constants/apiRoutes';
import { authApi } from '../../../constants/reduxConstant';
import { getAuthorization } from '../../../services/config';
import { updateLoading } from '../loader';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';

const { topBrand = '', brandReqList = '', req_detail = '', brand_data = '' } = authApi;
const { V1: { TOP_BRAND_LIST = '', BRAND_REQ_LIST = '',  REQ_DETAILS, BRAND_DETAIL = '' } = {} } = API_ROUTES;

const fetchTopBrandList = createAsyncThunk(
  topBrand,
  async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const API = getAuthorization(getState, { isAuthHeader: false });
    let postData;
    try {
      dispatch(updateLoading(true)); 
      if(payload.all){
        postData = `all=${payload.all}`
      } else if (payload.filter?.length) {
        postData = `page=${payload.page}&per_page=${payload.per_page}&filter=[${payload?.filter?.map(item => `"${item}"`)?.join(',')}]`
      } else {
        postData = `page=${payload.page}&per_page=${payload.per_page}`
      }
      const response = await API.get(
        TOP_BRAND_LIST + `?${postData}`,
      );
      dispatch(updateLoading(false)); 
      const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
      if (status === 400) {
        return rejectWithValue(errresponse);
      }
      return data;
    } catch (err) {
      ToastNotifyError(err);
      return err;
    }
  },
);

const fetchBrandReqList = createAsyncThunk(
  brandReqList,
  async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const API = getAuthorization(getState, { isAuthHeader: false });
    let postData = [];
    try {
      dispatch(updateLoading(true)); 
      if (payload.page) postData?.push(`page=${payload.page}`);
      if (payload.per_page) postData?.push(`per_page=${payload.per_page}`);
      if (payload.search) postData?.push(`search=${payload.search}`);
      if (payload.state) postData?.push(`state=${payload.state}`);
      if (payload.city) postData?.push(`city=${payload.city}`);
      if (payload.country) postData?.push(`country=${payload.country}`);
      const queryString = postData?.join('&');
      const response = await API.get(
        BRAND_REQ_LIST + `/${payload.id}` + (queryString ? `?${queryString}` : ''),
      );
      dispatch(updateLoading(false)); 
      const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
      if (status === 400) {
        return rejectWithValue(errresponse);
      }
      return data;
    } catch (err) {
      ToastNotifyError(err);
      return err;
    }
  },
);

const fetchBrandDetails = createAsyncThunk(
  req_detail,
  async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const API = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        REQ_DETAILS + `/${payload.id}`,
      );
      dispatch(updateLoading(false));
      const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
      if (status === 400) {
        return rejectWithValue(errresponse);
      }
      return data;
    } catch (err) {
      ToastNotifyError(err);
      return err;
    }
  },
);

const fetchBrandData = createAsyncThunk(
  brand_data,
  async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const API = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        BRAND_DETAIL + `/${payload.id}`,
      );
      dispatch(updateLoading(false));
      const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
      if (status === 400) {
        return rejectWithValue(errresponse);
      }
      return data;
    } catch (err) {
      ToastNotifyError(err);
      return err;
    }
  },
);

export { fetchTopBrandList, fetchBrandReqList, fetchBrandDetails, fetchBrandData};

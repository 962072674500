import { styled } from '@mui/material/styles';

const StyledFooter = styled('div')(
  ({ theme }) => `
    background: ${theme.customStyles};
    .footer-link {
      cursor: pointer;
      text-decoration: none;
      color: #FFFFFF;
    }
    .footer-link:hover {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  `
);

export default StyledFooter;

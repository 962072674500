const ROUTE_PATH = {
  DASHBOARD: '/',
  PROPVISION: '/propvision',
  PROPMAGNET: '/propmagnet',
  BRAND_REQUIREMENT_LIST: '/brand-requirement-list',
  ALL_REQUIREMENT_LIST: '/all-requirements-list',
  ABOUT: '/about',
  CONTACT_US: '/contact-us',
  TERMS_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  SUBMIT_PROPERTY: '/submit-property',
};

const API_PATH = {};

export { ROUTE_PATH, API_PATH };

import { createAsyncThunk } from '@reduxjs/toolkit';
import API_ROUTES from '../../../constants/apiRoutes';
import { authApi } from '../../../constants/reduxConstant';
import { getAuthorization } from '../../../services/config';
import { updateLoading } from '../loader';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';

const { contactUs = '', subscribe = '' } = authApi;
const { V1: { CONTACT_US = '', SUBSCRIBE_NEWSLETTER = '' } = {} } = API_ROUTES;

const fetchContactUs = createAsyncThunk(
    contactUs,
    async (payload = {}, { dispatch, getState, rejectWithValue }) => {
      const API = getAuthorization(getState);
      try {
        dispatch(updateLoading(true));
        const response = await API.post(CONTACT_US, payload);
        dispatch(updateLoading(false));
        const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
        if (status === 400) {
          return rejectWithValue(errresponse);
        }
        return data;
      } catch (err) {
        ToastNotifyError(err);
        return err;
      }
    },
)

const fetchSubscribe = createAsyncThunk(
  subscribe,
  async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(SUBSCRIBE_NEWSLETTER, payload);
      dispatch(updateLoading(false));
      const { data = {}, response: errresponse, response: { status = '' } = {} } = response;
      console.log('statuss', response)
      if (status === 400) {
        return rejectWithValue(errresponse);
      } else if (response.status === 200){
        ToastNotifySuccess('You have successfully subscribed!');
      }
      return data;
    } catch (err) {
      ToastNotifyError(err);
      return err;
    }
  },
)

export { fetchContactUs, fetchSubscribe };

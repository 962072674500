import { Grid, TextField, Typography, Paper, InputAdornment, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { fetchSubscribe } from '../../store/reducers/contactUs/apiThunk';
import { validationSchemaSubscribeField } from '../../utils/validations';

const SubscribeToNewsletter = ({className}) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const submitHandler = async (values, formikHelpers) => {
        const postData = {email: values?.email}
        const response = await dispatch(fetchSubscribe(postData)).then(({ payload }) => payload)
        if(response) {
            formikHelpers.resetForm();
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchemaSubscribeField,
        onSubmit: submitHandler
    })

    const {
        handleSubmit,
        values: {
            email,
        } = {},
        touched: {
            email: tucEmail = false,
        } = {},
        errors: {
            email: errEmail,
        } = {}
    } = formik;

    return (
        <Paper
            className={className}
            elevation={3}
            sx={{
                py: '48px',
                boxShadow: 'unset',
                backgroundColor: '#DFE5F0'
            }}
        >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            {/* First Column */}
            <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'}>
            <Typography variant="type_32_600_414141" sx={{mb: '4px'}}>
                Subscribe to our Newsletter
            </Typography>
            <Typography variant="type_14_500_414141">
            Equip your businesses and property advisors with the tools they need to elevate their operations, boost efficiency, and successfully attain their strategic goals.
            </Typography>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
                <TextField
                    name='email'
                    variant="outlined"
                    label="Email address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={tucEmail && Boolean(errEmail)}
                    inputProps={{
                        maxLength: 254,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <Button
                                variant="contained" 
                                color="primary" 
                                onClick={handleSubmit}
                                sx={{
                                    borderRadius: '100px',
                                    textTransform: 'none',
                                    padding: '10px 40px',
                                    backgroundColor: '#0048D0'

                                }}
                            >
                                <Typography variant='type_12_500_DFE5F0_POPPINS'>
                                    Subscribe
                                </Typography>
                            </Button>
                            </InputAdornment>
                        ),
                        sx: {
                            borderColor: '#FFFFFF',
                            borderRadius: '100px',
                            background: '#FFFFFF',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#FFFFFF',
                                borderRadius: '100px !important',
                                border: '0.96px solid #F1F1F1'
                            },
                        },
                    }}
                    InputLabelProps={{
                        sx: { ...theme.typography.type_16_500_76786B},

                    }}
                />
            </Grid>
        </Grid>
        </Paper>
    );
};

export default SubscribeToNewsletter;

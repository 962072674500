import { Box, Card, CardContent, CardMedia, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import RealEstateSection from '../../components/commonComp/RealEstateSection';
import ExploreApplicationsSection from '../../components/commonComp/ExploreApplicationsSection';
import StyledPropvision from './StyledPropvision';
import ExperienceFuture from '../../components/commonComp/ExperienceFuture';
import SubscribeToNewsletter from '../../components/SubscribeToNewsletter';
import CONSTANTS from '../../constants/common';
const {propvisionCardsData} = CONSTANTS;

const Propvision = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <StyledPropvision>
            <Box
                sx={{
                    mt: { xs: '95px', md: '120px', lg: '159px'},
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <RealEstateSection type={'propvision'}/>
                <ExploreApplicationsSection />
                <Box className={'propvisionSectionWrapPadding'} sx={{ pb: '80.61px', textAlign: 'center', backgroundColor: '#FFFFFF'}}>
                    <Typography variant={'type_36_500_404040'}>
                        Our Features
                    </Typography>
                    <Grid sx={{mt: '32px'}} container spacing={'16px'} justifyContent="center" alignItems="center">
                        {propvisionCardsData.map((card, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Card
                                    sx={{
                                        borderRadius: {xs: '44px', md: '32px'},
                                        backgroundColor: '#F3F7FF',
                                        pl: { xs: '25px', sm: '35px', md: '42.65px'},
                                        pt: { xs: '30px', sm: '35px', md: '49.39px'},
                                    }}
                                >
                                    <CardContent sx={{ padding: '0px', pr: '10px', display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                                        <Typography variant="type_16_500_414141" sx={{mb: '9.61px'}}>
                                            {card.title}
                                        </Typography>
                                        <Typography
                                            variant="type_28_500_414141_POPPINS" 
                                            sx={{
                                                pr: {xs: '20px'},
                                                minHeight: { xs: '40px', md: '96px'},
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                WebkitLineClamp: 2,
                                                wordBreak: 'break-word',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {card.description1}
                                        </Typography>
                                        <Typography
                                            variant="type_12_500_414141"
                                            sx={{
                                                mb: '36.48px', 
                                                pr: {xs: '20px', lg: '85px'}, 
                                                minHeight: '41px',
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                WebkitLineClamp: 3,
                                                wordBreak: 'break-word',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {card.description2}
                                        </Typography>
                                    </CardContent>
                                    {isLoading && (
                                        <Skeleton
                                            animation="wave"
                                            variant="rectangular"
                                            sx={{
                                                
                                                height: { xs: '127px', xsl: '237px', sm: '153px', md: '214px', lg: '251px'},
                                                borderRadius: '32.93px 0'
                                            }}
                                        />
                                    )}
                                    <CardMedia
                                        component="img"
                                        image={card.image}
                                        alt="Card image"
                                        onLoad={() => setIsLoading(false)}
                                        sx={{display: isLoading ? 'none' : 'block'}}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <ExperienceFuture className={'propvisionSectionWrapPadding'}/>
                <SubscribeToNewsletter className={'propvisionSectionWrapPadding'}/>
            </Box>
        </StyledPropvision>
    )
}

export default Propvision;
const messages = {
  MSG_NAME_REQ: 'Please enter your Name',
  MSG_EMAIL_USERNAME_REQ: 'Please enter your Email Address',
  MSG_VALID_EMAIL: 'Please enter valid Email Address',
  MSG_PHONE_REQ: 'Please enter Contact Number',
  MSG_VALID_PHONE: 'Please enter valid Phone Number',
  MSG_EMAIL_LENGTH: 'Email must be less than 254 characters',
  MSG_FIRST_NAME_REQ: 'Please Enter First Name',
  MSG_LAST_NAME_REQ: 'Please Enter Last Name',
  MSG_FIRST_NAME_VALID: 'First name can only contain alphabets and spaces',
  MSG_LAST_NAME_VALID: 'Last name can only contain alphabets and spaces',
  MSG_CITY_REQ: 'Please enter City Name',
  MSG_STATE_REQ: 'Please enter State Name',
  MSG_PINCODE_REQ: 'Pincode is required',
  MSG_AREA_REQ: 'Please enter Area Name',
  MSG_SUPER_AREA_REQ: 'Please enter Super Area',
  MSG_CARPET_AREA_REQ: 'Please enter Carpet Area',
  MSG_FRONTAGE_REQ: 'Please enter Frontage',
  MSG_CELLING_HEIGHT_REQ: 'Please enter Celling Height',
  MSG_FLOOR_REQ: 'Please enter Floor',
  MSG_FINANCIAL_REQ: 'Please enter Financial',
  MSG_VALID_PINCODE: 'Pincode must be valid 6 digits long and contain only numbers',
  MSG_VALID_MAP_URL: 'Please enter a valid Google Map Url.',
  MSG_SUBJECT_REQ: 'Subject is a required field',
  MSG_MESSAGE_REQ: 'Message is a required field',
  MSG_VALID_NAME: 'Please enter a valid Name',
  MSG_MIN_MESSAGE: 'Message must be at least 20 characters',
  MSG_MAX_MESSAGE: 'Message must be at most 1000 characters',
};

export default messages;

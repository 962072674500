import { Box, Button, List, ListItem, Stack, Typography, Paper } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { useNavigate } from 'react-router-dom';
import agpropSqureBoxLogo from '../../assets/png/agpropSquareBoxLogo.png';

const TermsConditions = () => {
    const navigate = useNavigate();
    const handleNavigate = (type) => {
        if(type === 'home'){
            navigate('/')
        }
    }
    
    return (
        <Stack
            sx={{
                mt: '120px',
                mx: {xs: '0.6', xsl: '1rem', sm: '2rem', md: '4rem', lg: '7.2rem' }
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    p: '15px',
                    borderRadius: '20px',
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '20px',
                }}
            >
                <Button
                    onClick={() => handleNavigate('home')}
                    variant="contained"
                    sx={{
                        textTransform: 'none',
                        width: '30px',
                        minWidth: 'unset',
                        height: '30px',
                        p: '0px',
                        borderRadius: '50%',
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'unset',
                        '&:hover': {
                        backgroundColor: '#F2F2F2',
                        },
                        '&:active': {
                            backgroundColor: '#E0E0E0',
                        },
                        '&:focus': {
                            backgroundColor: '#EAEAEA',
                        },
                    }}
                >
                    <WestIcon color={'#000000'} sx={{ color: '#000000',  width: '24px', height: '24px' }}/>
                </Button>
                <Typography variant='type_14_600_424242' sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    Terms and conditions
                </Typography>
            </Box>
            <Paper elevation={3} sx={{ borderRadius: '25px', padding: '20px', my: '20px' }}>
                <Box sx={{textAlign: 'right', mb: '20px'}}>
                    <Box
                        component="img"
                        src={agpropSqureBoxLogo}
                        alt="agpropSqureBoxLogo"
                        sx={{
                        width: 120,
                        height: 120,
                        mr: '5px',
                        
                        }}
                    />
                </Box>
                <Typography sx={{ fontFamily: 'Inter', textAlign: 'center', fontSize: '12px', fontWeight: '700', textDecoration: 'underline'}}>
                    TERMS AND CONDITIONS
                </Typography>
                <Typography variant="subtitle1" align="justify">
                    <strong>Effective Date:</strong> Jan 1, 2024
                </Typography>
                <Typography variant="subtitle1" align="justify" sx={{mb: '46px'}}>
                    <strong>Website Covered:</strong> agprop.in
                </Typography>
                <Typography variant="body1" align="justify" paragraph sx={{mb: '46px'}}>
                    <strong>THE AGREEMENT:</strong> The use of this website and services on
                    this website provided by Agprop Consultants LLP (hereinafter referred
                    to as <strong>"Owner/Agprop"</strong>) are subject to the following Terms & Conditions
                    (hereinafter the <strong>"Terms of Service"</strong>), all parts and sub-parts of which
                    are specifically incorporated by reference here. Following are the
                    Terms of Service governing your use of https://agprop.in (the
                    <strong>"Website"</strong>), all pages on the Website and any services provided by or on
                    this Website (<strong>"Services"</strong>).
                </Typography>
                <Typography variant="body1" align="justify" paragraph sx={{mb: '46px'}}>
                    By accessing either directly or through a hyperlink, the Website, and/
                    or purchasing something from us, you engage in our "Service" and agree
                    to be bound by the Terms of Service including those additional terms
                    and conditions and policies referenced herein and/or available by
                    hyperlink. These Terms of Service apply to all users of the site,
                    including without limitation vendors, buyers, customers, merchants,
                    browsers and/or contributors of content.
                </Typography>
                <Typography variant="body1" align="justify" paragraph sx={{mb: '46px'}}>
                    The parties referred to in these Terms of Service shall be defined as
                    follows:
                </Typography>
                <Typography variant="body1" align="justify" paragraph sx={{mb: '46px'}}>
                    <strong>Owner, Us, We:</strong> The Owner, as the creator, operator,
                    and publisher of the Website, makes the Website, and certain Products
                    and Services on it, available to users. Owner, Us, We, Our, Ours and
                    other first-person pronouns will refer to the Owner, as well as all
                    employees and affiliates of the Owner.
                </Typography>
                <Typography variant="body1" align="justify" paragraph sx={{mb: '46px'}}>
                    <strong>You, the User, the Client:</strong> You, as the user of the
                    Website, will be referred to throughout these Terms of Service with
                    second-person pronouns such as You, Your, Yours, or as User or Client.
                    For the purpose of these Terms of Service, the term "User" or "you"
                    shall mean any natural or legal person who person is accessing the
                    Website. The term "Your" shall be construed accordingly.
                </Typography>
                <Typography variant="body1" align="justify" paragraph  sx={{mb: '46px'}}>
                    <strong>Parties:</strong> Collectively, the parties to these Terms of
                    Service (the Owner and You will be referred to as Parties).
                </Typography>
                <List>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'1) '}<strong>DEFINITIONS</strong>
                        </Typography>
                        <Typography variant="body1">
                            Unless otherwise specified, the
                            capitalized terms shall have the meanings set out below:
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            a. <strong>Account</strong> means and includes the account created on
                            the Site, by the User, in accordance with the terms of the
                            Agreement, registered with and approved by Owner.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            b. <strong>Agreement</strong> means and includes the Terms and
                            Conditions, Privacy Policy and any other such terms and conditions
                            that may be mutually agreed upon between Agprop and the User in
                            relation to the Services.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            c. <strong>Applicable Law</strong> means and includes any statute, law, 
                            regulation, sub-ordinate legislation, ordinance, rule, judgment, rule of 
                            law, order (interim or final), writ, decree, clearance, Authorizations, 
                            approval, directive, circular guideline, policy, requirement, code of practice 
                            or guidance note, or other governmental, regulatory, statutory, administrative 
                            restriction or any similar form of decision, or determination by, or any interpretation 
                            or administration of any of the foregoing by, any statutory or regulatory authority or 
                            government agency or any other authority, in each case having jurisdiction over the 
                            subject matter of this Agreement.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            d. <strong>Broker</strong> means and includes all brokers, channel 
                            partners, sales agencies and other third parties who/which negotiate 
                            or act on behalf of one person in a transaction of transfer including 
                            sale, purchase, leave and license, lease or such other form of transfer 
                            in relation to a plot, apartment or building, as the case may be, including 
                            marketing and promotion of such plot, apartment or building, for 
                            remuneration or fees or any other charges for his services whether 
                            as commission or otherwise and includes a person who introduces, 
                            through any medium, prospective parties to each other for negotiations 
                            and includes property dealers, brokers, middlemen by whatever name 
                            called and real estate agent as defined under the Real Estate 
                            (Regulation and Development) Act, 2016.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            e. <strong>Computer Virus</strong> means and includes any computer instruction, 
                            information, data or programme that destroys, damages, degrades or adversely 
                            affects the performance of a computer resource or attaches itself to another 
                            computer resource and operates when a programme, data or instruction is executed 
                            or some other event takes place in that computer resource.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            f. <strong>Confidential Information</strong>means and includes all information 
                            that is not in the public domain, in spoken, printed, electronic or any other 
                            form or medium, relating directly or indirectly to, the assets, business processes, 
                            practices, methods, policies, subscription plans, publications, documents, research, 
                            operations, services, strategies, techniques, agreements, contracts, terms of 
                            agreements, transactions, potential transactions, negotiations, pending negotiations, 
                            know-how, trade secrets, computer programs, computer software, applications, 
                            operating systems, software design, web design, work-in-process, databases, 
                            manuals, records and reports, articles, systems, material, sources of material, 
                            supplier identity and information, vendor identity and information, User identity 
                            and information, financial information, results, accounting information, accounting 
                            records, legal information, marketing information, advertising information, pricing 
                            information, credit information, developments, internal controls, security procedures, 
                            graphics, drawings, sketches, sales information, costs, formulae, product plans, 
                            designs, ideas, inventions, original works of authorship, discoveries and specifications, 
                            of Agprop and/or affiliates or their respective businesses, or any existing or 
                            prospective customer, supplier, investor or other associated third party, or of 
                            any other person or entity that has entrusted information to Agprop in confidence.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            g. <strong>Content</strong> means and includes any information all data and information on the Site.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            h. <strong>Government Authority</strong> means and includes any government, any state or other political 
                            subdivision thereof, any entity exercising executive, legislative, judicial, regulatory or 
                            administrative functions of or pertaining to government, or any other government authority, agency, 
                            department, board, commission or instrumentality or any political subdivision thereof, and any court, 
                            tribunal or arbitrator(s) of competent jurisdiction, and, any Government or non-Government 
                            self-regulatory organization, agency or authority; having jurisdiction over the Agreement 
                            and the Services contemplated under the Agreement.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            i. <strong>Information Technology Act</strong> means the Information Technology Act, 2000.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            j. <strong>Intellectual Property</strong> means and includes patents, inventions, know how, 
                            trade secrets, trademarks, service marks, designs, tools, devices, models, methods, procedures, 
                            processes, systems, principles, algorithms, works of authorship, flowcharts, drawings, 
                            and other confidential and proprietary information, data, documents, instruction manuals, 
                            records, memoranda, notes, user guides, ideas, concepts, information, materials, discoveries, 
                            developments, and other copyrightable works, and techniques in either printed or machine-readable 
                            form, whether or not copyrightable or patentable, each of which is not in the public domain 
                            or would by its very nature fall within public domain.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            k. <strong>Intellectual Property Rights</strong> mean and include (a) all right, title, and interest 
                            under including but not limited to patent, trademark, copyright under the Patents Act, 1970, 
                            Trademarks Act, 1999 and Copyright Act, 1957 respectively; any statute or under common law including 
                            patent rights; copyrights including moral rights; and any similar rights in respect of Intellectual 
                            Property, anywhere in the world, whether negotiable or not; (b) any licenses, permissions and grants 
                            in connection therewith; (c) applications for any of the foregoing and the right to apply for them 
                            in any part of the world; (d) right to obtain and hold appropriate registrations in Intellectual 
                            Property; (e) all extensions and renewals thereof; (f) causes of action in the past, present or 
                            future, related thereto including the rights to damages and profits, due or accrued, arising out 
                            of past, present or future infringements or violations thereof and the right to sue for and recover 
                            the same; (g) any Confidential Information.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            l. <strong>Privacy Policy</strong> means and includes the privacy policy of Agprop.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            m. <strong>Prohibited Conduct</strong> means and includes the User’s use of the Service in contravention 
                            of the Agreement and Applicable Law; violation of or the abetment of violation of third party rights; 
                            infringement or misappropriation of Agprop’s or any persons Intellectual Property Right; attempt to 
                            gain or assist another person/ User to gain unauthorized access to the Site and/or Services or its 
                            related systems or networks; create internet links to the Site or "frame" or "mirror" any content 
                            on any other server or wireless or Internet-based device; act of sending spam, duplicated or 
                            unsolicited messages; usage or storage of obscene, threatening, libellous, or otherwise unlawful 
                            or tortious material, including material harmful to children or in violation of third party privacy 
                            rights leading to harassment, annoyance, anxiety or inconvenience to any person; modify or make 
                            derivative works based upon the Service and/or impersonation in relation to any person or entity, 
                            claiming a false affiliation, accessing any other Account without permission, or falsely representing 
                            User Information.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            n. <strong>Registration Data</strong> means and includes the mobile number, e-mail address, username 
                            and such other particulars and/or information as may be required by Owner/Agprop and supplied by 
                            the User on initial application and subscription.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            o. <strong>Personal Information</strong> means and includes details of Aadhaar Card, PAN Card, biometric information, 
                            financial information such as bank account, debit card, credit card details and such other sensitive 
                            information, of the User, and/or concerned person which personally identifies such User and/or person.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            p. <strong>Services</strong> means and includes the services provided by Agprop within the territory of India 
                            to the User of the Site and shall include the provision of (a) connecting tenants and owners to find each 
                            other, (b) services to the Users who wish to post their listings for the purposes of renting their property, 
                            (c) Leasing , (d) services to the Users pertaining to paperwork and documentation processing, relating to 
                            lease agreement registration, (e) Investment Advisory (g) Asset Development and Built to Suit (h) Legal 
                            Comprehensive services in all aspect of property & real estate transactions (i) connecting the Users to 
                            various Third Party Service Providers for the provision of other related services.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            q. <strong>Site</strong> means and includes the website owned, operated and managed by Agprop Consultants LLP 
                            at the URL https://agprop.in and also includes the mobile application owned, operated and managed by Agprop Consultants LLP.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            r. <strong>Terms and Conditions</strong> means and includes the terms and conditions contained in the Agreement.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            s. <strong>Third Party Service Provider</strong> means and includes any service provider with whom Agprop has an 
                            agreement for the purposes of making services in addition to the Services available for the User.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify" sx={{mb: '46px'}}>
                            t. <strong>User Information</strong> means and includes Registration Data, Personal Information and any other data, 
                            details or information in relation to the User, provided by the User to Agprop in relation to the Services, whether 
                            through the Site, e-mail or any other form of communication, agreeable to the User and Agprop.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}} >
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'2) '}<strong>ASSENT & ACCEPTANCE</strong>
                        </Typography>
                        <Typography variant="body1">
                            By using the Website, You warrant that You have read and reviewed these Terms of Service and that You agree to be 
                            bound by it. If You do not agree to be bound by these Terms of Service, please leave the Website immediately. 
                            The Owner only agrees to provide use of this Website and Services to You if You assent to these Terms of Service. 
                            Further, based on the Products and Services obtained by a User, additional terms and conditions in respect of 
                            the specific Services may apply, which shall be deemed an agreement between the Users and the Owner.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'3) '}<strong>AGE RESTRICTION</strong>
                        </Typography>
                        <Typography variant="body1">
                            You must be at least 18 (eighteen) years of age to use this Website, or any Services contained herein. By using this 
                            Website, You represent and warrant that You are at least 18 years of age and may legally agree to these Terms of 
                            Service. The Owner assumes no responsibility or liability for any misrepresentation of Your age.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'4) '}<strong>ABOUT THE SITE</strong>
                        </Typography>
                        <Typography variant="body1">
                            The Website is an technology driven real estate flatform. We reserve the right to refuse service or refuse to sell 
                            the products and services on the Website at our sole discretion to anyone for any reason at any time. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            The Website does not screen or censor the users who register on and access the Website. You assume all risks 
                            associated with dealing with other users with whom you come in contact through the Website. You agree to use 
                            the Website only for lawful purposes without infringing the rights of or restricting the use of this Website 
                            by any third party.  
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'5) '}<strong>LICENSE TO USE WEBSITE</strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may provide You with certain information as a result of Your use of the Website or Services. 
                            Such information may include but is not limited to, documentation, data, or information developed by the 
                            Owner, and other materials which may assist in Your use of the Website or Services ("Owner Materials"). 
                            Subject to these Terms of Service, the Owner grants You a non-exclusive, limited, non-transferable and 
                            revocable license to use the Owner Materials solely in connection with Your use of the Website and Services. 
                            The Owner Materials may not be used for any other purpose and this license terminates upon Your cessation of 
                            use of the Website or Services or at the termination of these Terms of Service. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            You agree not to collect contact information of other Users from the Website or download or copy any 
                            information by means of unsolicited access so as to communicate directly with them or for any reason whatsoever. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            Any unauthorized use by you shall terminate the permission or license granted to you by the Website and 
                            You agree that you shall not bypass any measures used by the Owner to prevent or restrict access to the Website
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'6) '}<strong>INTELLECTUAL PROPERTY</strong>
                        </Typography>
                        <Typography variant="body1">
                            You agree that the Website and all Products (Propmagnet and Propvision) and Services provided by the Owner 
                            are the property of the Owner, including all copyrights, trademarks, trade secrets, patents, and other 
                            intellectual property ("Owner IP"). You agree that the Owner owns all rights, title, and interest in 
                            and to the Owner IP and that You will not use the Owner IP for any unlawful or infringing purpose. 
                            You agree not to reproduce or distribute the Owner IP in any way, including electronically or via 
                            registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), 
                            without express written permission from the Owner. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            a. In order to make the Website and Services available to You, You hereby grant the Owner a royalty- free, 
                            non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative 
                            works of any content You publish, upload or otherwise make available to the Website ("Your Content"). 
                            The Owner claims no further proprietary rights in Your Content. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            b. If You feel that any of Your intellectual property rights have been infringed or otherwise violated by 
                            the posting of information or media by another of Our users, please contact Us and let us know.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'7) '}<strong>USER OBLIGATIONS </strong>
                        </Typography>
                        <Typography variant="body1">
                            As a user of the Website or Services, You may be asked to register with Us. When You do so, 
                            You will choose a user identifier, which may be Your email address or another term, as well as a 
                            password. You may also provide personal information, including, but not limited to, Your name. 
                            You are responsible for ensuring the accuracy of this information. This identifying information 
                            will enable You to use the Website and Services. You must not share such identifying information 
                            with any third party and if You discover that Your identifying information has been compromised, 
                            You agree to notify Us immediately in writing. An email notification will suffice. You are 
                            responsible for maintaining the safety and security of Your identifying information as well 
                            as keeping Us apprised of any changes to Your identifying information. The billing information 
                            You provide us, including credit card, billing address and other payment information, is 
                            subject to the same confidentiality and accuracy requirements as the rest of Your identifying 
                            information. Providing false or inaccurate information, or using the Website or Services to 
                            further fraud or unlawful activity is grounds for immediate termination of these Terms of Service. 
                            The Owner reserves the right to refuse service, terminate accounts, or remove or edit content 
                            in its sole discretion.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'8) '}<strong>PAYMENT & FEES </strong>
                        </Typography>
                        <Typography variant="body1">
                            Should You register for any of the paid Product and Services on this website or purchase any product 
                            or service on this website, You agree to pay Us the specific monetary amounts required for that 
                            product or those Services. These monetary amounts ("Fees") will be described to You during Your 
                            account registration and/or confirmation process. The final amount required for payment will be 
                            shown to You immediately prior to purchase. Payment for any on-going Services is billed automatically 
                            until You notify Us that You would like to terminate Your access to the Services.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            We reserve the right to refuse service or refuse to sell the products and services on the Website at 
                            our sole discretion to anyone for any reason at any time.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '26px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'9) '}<strong>ACCEPTABLE USE </strong>
                        </Typography>
                        <Typography variant="body1">
                            You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under 
                            this clause. You agree not to use the Website or Services in any way that could damage the Website, 
                            Services or general business of the Owner. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1">
                            {'a) '}You further agree not to use the Website or Services: 
                        </Typography>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="I">
                                <li>To harass, abuse, or threaten others or otherwise violate any person's legal rights;</li>
                                <li>To violate any intellectual property rights of the Owner or any third party;</li>
                                <li>To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;</li>
                                <li>To perpetrate any fraud;</li>
                                <li>To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</li>
                                <li>To publish or distribute any obscene or defamatory material;</li>
                                <li>To publish or distribute any material that incites violence, hate, or discrimination towards any group;</li>
                                <li>To unlawfully gather information about others.</li>
                            </ol>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You are prohibited from using the site or its content: 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="a">
                                <li>For any unlawful purpose;</li>
                                <li>To solicit others to perform or participate in any unlawful acts;</li>
                                <li>
                                    To infringe on any third party's intellectual property or proprietary rights, 
                                    or rights of publicity or privacy, whether knowingly or unknowingly;
                                </li>
                                <li>To violate any local, federal or international law, statute, ordinance or regulation;</li>
                                <li>To harass, abuse, insult harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                                <li>To submit false or misleading information or any content which is defamatory, libelous, threatening, unlawful, harassing, indecent, abusive, obscene, or lewd and lascivious or pornographic, or exploits minors in any way or assists in human trafficking or content that would violate rights of publicity and/or privacy or that would violate any law;</li>
                                <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
                                <li>To collect or track the personal information of others;</li>
                                <li>To damage, disable, overburden, or impair the Website or any other party's use of the Website;</li>
                                <li>To spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                                <li>For any obscene or immoral purpose;</li>
                                <li>To interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet: to personally threaten or has the effect of personally threatening other Users.</li>
                            </ol>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            We reserve the right to terminate your use of the Service or any related website for violating 
                            any of the prohibited uses. The Company has the full authority to review all content posted 
                            by Users on the Website. You acknowledge that the Website is not responsible or liable and 
                            does not control the content of any information that may be posted to the Website by You or 
                            other User of the Website and you are solely responsible for the same. You agree that You 
                            shall not upload, post, or transmit any content that you do not have a right to make 
                            available (such as, the intellectual property of another party).
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You agree to comply with all applicable laws, statutes and regulations concerning your use of the 
                            Website and further agree that you will not transmit any information, data, text, files, 
                            links, software, communication or other materials that are abusive, invasive of another's 
                            privacy, harassing, defamatory, vulgar, obscene, unlawful, false, misleading, harmful, 
                            threatening, hateful or racially or otherwise objectionable, including without limitation 
                            material of any kind or nature that encourages conduct that could constitute a criminal 
                            offence, give rise to civil liability or otherwise violate any applicable local, 
                            state, provincial, national, or international law or regulation, or encourage the use of controlled substances. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            We may, but have no obligation to, monitor, edit or remove content that we determine in our 
                            sole discretion are unlawful, offensive, threatening, libellous, defamatory, pornographic, 
                            obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Service. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You may not use our products for any illegal or unauthorized purpose nor may you, in the use 
                            of the Service, violate any laws in your jurisdiction (including but not limited to Intellectual Property Laws).
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'10) '}<strong>COMMUNICATION </strong>
                        </Typography>
                        <Typography variant="body1">
                            You understand that each time uses the Website in any manner, you agree to these Terms. By agreeing to these 
                            Terms, you acknowledge that you are interested in availing and purchasing the Services that you have 
                            selected and consent to receive communications via phone or electronic records from the Website 
                            including e-mail messages telling you about products and services offered by the Website (or its 
                            affiliates and partners) and understanding your requirements. Communication can also be by posting 
                            any notices on the Website. You agree that the communications sent to You by the Website shall 
                            not be construed as spam or bulk under any law prevailing in any country where such communication is received.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'11) '}<strong>AFFILIATE MARKETING & ADVERTISING </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner, through the Website and Services, may engage in affiliate marketing whereby the Owner receives 
                            a commission on or percentage of the sale of goods or services on or through the Website. The Owner may 
                            also accept advertising and sponsorships from commercial businesses or receive other forms of advertising 
                            compensation. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'12) '}<strong>PRIVACY INFORMATION </strong>
                        </Typography>
                        <Typography variant="body1">
                            Through Your Use of the Website and Services, You may provide Us with certain information. By using the 
                            Website or the Services, You authorize the Owner to use Your information in India and any other country 
                            where We may operate. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="a">
                                <li><strong>Information We May Collect or Receive:</strong> When You register for an account, You provide Us with a valid email address and may provide Us with additional information, such as Your name or billing information. Depending on how You use Our Website or Services, We may also receive information from external applications You use to access Our Website, or We may receive information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.</li>
                                <li><strong>How We Use Information:</strong> We use the information gathered from You to ensure Your continued good experience on Our website, including through email communication. We may also track certain of the passive information received to improve Our marketing and analytics, and for this, We may work with third-party providers. </li>
                                <li>
                                    <strong>How You Can Protect Your Information:</strong> If You would like to disable Our access to any passive information We receive from the use of various technologies, You may choose to disable cookies in Your web browser. Please be aware that the Owner will still receive information about You that You have provided, such as Your email address. If You choose to terminate Your account, the Owner may store some information about You for the future reference
                                </li>
                            </ol>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'13) '}<strong>ASSUMPTION OF RISK </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Website and Services are provided for communication purposes only. You acknowledge and agree that any 
                            information posted on Our Website is not intended to be legal advice, medical advice, or financial advice, 
                            and no fiduciary relationship has been created between You and the Owner. You further agree that Your 
                            purchase of any of the products on the Website is at Your own risk. The Owner does not assume 
                            responsibility or liability for any advice or other information given on the Website. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'14) '}<strong>SALE OF PRODUCTS/SERVICES </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may sell products or services or allow third parties to sell product or services on the Website. 
                            The Owner undertakes to be as accurate as possible with all information regarding the products and 
                            services, including product descriptions and images. However, the Owner does not guarantee the 
                            accuracy or reliability of any product information and You acknowledge and agree that You purchase 
                            such products at Your own risk. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            We may provide you with access to third-party tools over which we neither monitor nor have any 
                            control nor input. You acknowledge and agree that we provide access to such tools "as is" and 
                            "as available" without any warranties, representations or conditions of any kind and without 
                            any endorsement. We shall have no liability whatsoever arising from or relating to your use 
                            of optional third-party tools. Any use by you of optional tools offered through the site is 
                            entirely at your own risk and discretion and you should ensure that you are familiar with 
                            and approve of the terms on which tools are provided by the relevant third-party provider(s).  
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'15) '}<strong>REVERSE ENGINEERING & SECURITY </strong>
                        </Typography>
                        <Typography variant="body1">
                            You agree not to undertake any of the following actions:  
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="a">
                                <li>Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;</li>
                                <li>Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network. </li>
                            </ol>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'16) '}<strong>DATA LOSS </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner does not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at your own risk.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'17) '}<strong>INDEMNIFICATION </strong>
                        </Typography>
                        <Typography variant="body1">
                            You agree to defend and indemnify the Owner and any of its affiliates (if applicable) and hold Us harmless against any and all 
                            legal claims and demands, including reasonable legal fees, which may arise from or relate to Your use or misuse of 
                            the Website or Services, Your breach of these Terms of Service, or Your conduct or actions. You agree that the Owner 
                            shall be able to select its own legal counsel and may participate in its own defence if the Owner wishes.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'18) '}<strong>SPAM POLICY </strong>
                        </Typography>
                        <Typography variant="body1">
                            You are strictly prohibited from using the Website or any of the Owner's Services for illegal spam activities, 
                            including gathering email addresses and personal information from others or sending any mass commercial emails.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'19) '}<strong>THIRD-PARTY LINKS & CONTENT </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may occasionally post links to third-party websites or other services. You agree that the Owner is 
                            not responsible or liable for any loss or damage caused as a result of Your use of any third party services 
                            linked to from Our Website
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'20) '}<strong>MODIFICATION & VARIATION </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may, from time to time and at any time without notice to You, modify these Terms of Service. 
                            You agree that the Owner has the right to modify these Terms of Service or revise anything contained herein. 
                            You further agree that all modifications to these Terms of Service are in full force. and effect 
                            immediately upon posting on the Website and that modifications or variations will replace any prior 
                            version of these Terms of Service unless prior versions are specifically referred to or incorporated 
                            into the latest modification or variation of these Terms of Service.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="a">
                                <li>To the extent any part or sub-part of these Terms of Service is held ineffective or invalid by any court of law, You agree that the prior, effective version of these Terms of Service shall be considered enforceable and valid to the fullest extent.</li>
                                <li>You agree to routinely monitor these Terms of Service and refer to the Effective Date posted at the top of these Terms of Service to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of these Terms of Service. You agree that Your continued use of the Website after any modifications to these Terms of Service is a manifestation of Your continued assent to these Terms of Service. </li>
                                <li>In the event that You fail to monitor any modifications to or variations of these Terms of Service, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.</li>
                            </ol>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'21) '}<strong>ENTIRE AGREEMENT </strong>
                        </Typography>
                        <Typography variant="body1">
                            This Agreement constitutes the entire understanding between the Parties with respect to any and 
                            all use of this Website. This Agreement supersedes and replaces all prior or contemporaneous 
                            agreements or understandings, written or oral, regarding the use of this Website.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'22) '}<strong>SERVICE INTERRUPTIONS </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may need to interrupt Your access to the Website to perform maintenance or emergency 
                            services on a scheduled or unscheduled basis. You agree that Your access to the Website may 
                            be affected by unanticipated or unscheduled downtime, for any reason, but that the Owner 
                            shall have no liability for any damage or loss caused as a result of such downtime
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'23) '}<strong>TERM, TERMINATION & SUSPENSION </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner may terminate these Terms of Service with You at any time for any reason, with 
                            or without cause. The Owner specifically reserves the right to terminate these Terms of 
                            Service if You violate any of the terms outlined herein, including, but not limited to, 
                            violating the intellectual property rights of the Owner or a third party, failing to comply 
                            with applicable laws or other legal obligations, and/or publishing or distributing illegal 
                            material. If You have registered for an account with Us, You may also terminate these 
                            Terms of Service at any time by contacting Us and requesting termination. Please keep 
                            in mind that any outstanding fees will still be due even after termination of Your account. 
                            At the termination of these Terms of Service, any provisions that would be expected to 
                            survive termination by their nature shall remain in full force and effect. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'24) '}<strong>NO WARRANTIES </strong>
                        </Typography>
                        <Typography variant="body1">
                            You agree that Your use of the Website and Services is at Your sole and exclusive risk and 
                            that any Services provided by Us are on an "As Is" basis. The Owner hereby expressly 
                            disclaims any and all express or implied warranties of any kind, including, but not 
                            limited to the implied warranty of fitness for a particular purpose and the implied 
                            warranty of merchantability. The Owner makes no warranties that the Website or Services 
                            will meet Your needs or that the Website or Services will be uninterrupted, error-free, 
                            or secure. The Owner also makes no warranties as to the reliability or accuracy of any 
                            information on the Website or obtained through the Services. You agree that any damage 
                            that may occur to You, through Your computer system, or as a result of the loss of Your 
                            data from Your use of the Website or Services is Your sole responsibility and that the 
                            Owner is not liable for any such damage or loss.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            All information, software, products, services and related graphics are provided on this 
                            site is “as is" and "as available" basis with without warranty of any kind, either 
                            expressed or implied. The Website disclaims all warranties, expressed or implied 
                            including, without limitation, all implied warranties of merchantability, fitness for 
                            a particular purpose, title and non-infringement or arising from a course of dealing, 
                            usage, or trade practice. The company makes no representation about the suitability 
                            of the information, software, products, and services contained on this Website for 
                            any purpose, and the inclusion or offering of any products or services on this Website 
                            does not constitute any endorsement or recommendation of such products or services. 
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            The Website makes no warranty that the use of the Website will be uninterrupted, 
                            timely, secure, without defect or error-fee. You expressly agree that use of the 
                            site is at your own risk. The Website shall not be responsible for any content found on the Website.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            Your use of any information or materials on this site or otherwise obtained through use 
                            of this Website is entirely at your own risk, for which we shall not be liable. 
                            It shall be your own responsibility to ensure that any products, services or 
                            information available through this website meet your specific requirements
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            The Website assumes no responsibility for the accuracy, currency, completeness or usefulness of 
                            Information, views, opinions, or advice in any material contained on the Website. Any information 
                            from third parties or advertisers is made available without making any changes and so the Website 
                            cannot guarantee accuracy and is not liable for any inconsistencies arising thereof. All postings, 
                            messages, advertisements, photos, sounds, images, text, files, video, or other materials posted on, 
                            transmitted through, or linked from the Website, are solely the responsibility of the person 
                            from whom such Content originated, and the Website does not control and is not responsible for 
                            Content available on the Website.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            There may be instances when incorrect information is published inadvertently on our Website or 
                            in the Service such as typographical errors, inaccuracies or omissions that may relate to 
                            product descriptions, pricing, promotions, offers. Any errors, inaccuracies, or omissions 
                            may be corrected at our discretion at any time, and we may change or update information if 
                            any information in the Service or on any related that website is inaccurate at any time without prior notice.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            We undertake no obligation to update, amend or clarify information in the Service or on any 
                            related website, including without limitation, pricing information, except as required by law. 
                            No specified update or refresh date applied in the Service or on any related website should 
                            be taken to indicate that all information in the Service or on any related website has been 
                            modified or updated.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            The Website shall not be responsible for any interaction between you and the other users of the 
                            Website. Under no circumstances will the Website be liable for any goods, services, resources, or 
                            content available through such third-party dealings or communications, or for any harm related thereto. 
                            The Website is under no obligation to become involved in any disputes between you and other users 
                            of the Website or between you and any other third parties. You agree to release the Website 
                            from any and all claims, demands, and damages arising out of or in connection with such dispute.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You agree and understand that while the Website has made reasonable efforts to safeguard the Website, 
                            it cannot and does not ensure or make any representations that the Website or any of the 
                            information provided by You cannot be hacked by any unauthorised third parties. You specifically 
                            agree that the Website shall not be responsible for unauthorized access to or alteration of Your 
                            transmissions or data, any material or data sent or received not sent or received, or any 
                            transactions entered into through the Website.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You hereby agree and confirm that the Website shall not be held liable or responsible in any manner 
                            whatsoever for such hacking or any loss or damages suffered by you due to unauthorized access of 
                            the Website by third parties or for any such use of the information provided by You or any spam 
                            messages or information that You may receive from any such unauthorised third party (including 
                            those which are although sent representing the name of the Website but have not been authorized 
                            by the Website) which is in violation or contravention of this Terms of Service or the Privacy Policy.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You specifically agree that the Website is not responsible or liable for any threatening, defamatory. 
                            obscene, offensive, or illegal content or conduct of any other party or any infringement of another's 
                            rights, including intellectual property rights. You specifically agree that the Website is not 
                            responsible for any content sent using and/or included on the Website by any third party.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            The Website has no liability and will make no refund in the event of any delay, cancellation, strike, 
                            force majeure, or other causes beyond their direct control, and they have no responsibility for 
                            any additional expense omissions delays or acts of any government or authority.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You will be solely responsible for any damage to your computer system or loss of data that results 
                            from the download of any information and/or material. Some jurisdictions do not allow the exclusion 
                            of certain warranties, so some of the above exclusions may not apply to you.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            In no event shall the Website be liable for any direct, indirect, punitive, incidental, special, 
                            consequential damages or any damages whatsoever including, without limitation, damages for 
                            loss of use, data or profits, arising out of or in any way connected with the use or performance 
                            of the site, with the delay or inability to use the site or related services, the provision of 
                            or failure to provide Services, or to deliver the products or for any information, software, 
                            products, services and related graphics obtained through the site, or any interaction between you 
                            and other participants of the Website or otherwise arising out of the use of the Website, 
                            damages resulting from use of or reliance on the information present, whether based on contract, 
                            tort, negligence, strict liability or otherwise, even if the Website or any of its 
                            affiliates/suppliers has been advised of the possibility of damages.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1">
                            You accept all responsibility for and hereby agree to indemnify and hold harmless the company 
                            from and against, any actions taken by you or by any person authorized to use your account, 
                            including without limitation, disclosure of passwords to third parties. By using the Website, 
                            you agree to defend, indemnify, and hold harmless the indemnified parties from any and all 
                            liability regarding your use of the site or participation in any site's activities. 
                            If you are dissatisfied with the Website, or the Services or any portion thereof, or do not 
                            agree with these terms, your only recourse and exclusive remedy shall be to stop using the site.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'25) '}<strong>LIMITATION ON LIABILITY </strong>
                        </Typography>
                        <Typography variant="body1">
                            The Owner is not liable for any damage that may occur to You as a result of Your use of the 
                            Website or Services, to the fullest extent permitted by law. The maximum liability of the 
                            Owner arising from or relating to these Terms of Service
                        </Typography>
                    </ListItem>
                    <ListItem sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '16px', mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'26) '}<strong>USER GRIEVANCE </strong>
                        </Typography>
                        <Typography variant="body1">
                            Any User grievance relating to the discrepancies or misuse of information so provided to Agprop 
                            may be addressed to the grievance officer, whose details are provided below appointed by Agprop 
                            for this purpose.
                        </Typography>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            <strong>Name: </strong>
                        </Typography>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            <strong>Designation: </strong>
                        </Typography>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            <strong>Email Id : info@agprop,in</strong>
                        </Typography>
                        <Typography variant="body1">
                            The grievance officer shall address the grievance within one month of the date of receipt of such grievance from the User.
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography variant="body1" sx={{display: 'block'}}>
                            {'27) '}<strong>GENERAL PROVISIONS </strong>
                        </Typography>
                    </ListItem>
                    <ListItem sx={{mb: '46px'}}>
                        <Typography component="div" sx={{ '& ol > li': { marginBottom: '10px' } }}>
                            <ol type="a">
                                <li><strong>LANGUAGE: </strong>All communications made or notices given pursuant to these Terms of Service shall be in the English language.</li>
                                <li>
                                    <strong>JURISDICTION, VENUE & GOVERNING LAW: </strong>Through Your use of the Website or Services, You 
                                    agree that the laws of India shall govern any matter or dispute relating to or arising out of these Terms 
                                    of Service, as well as any dispute of any kind that may arise between You and the Owner, with the exception 
                                    of its conflict of law provisions. In case any litigation specifically permitted under these Terms of 
                                    Service is initiated, the Parties agree to submit to the exclusive jurisdiction of the courts at New Delhi, 
                                    India. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, 
                                    but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion 
                                    of the doctrine of forum non-conveniens or similar doctrine.
                                </li>
                                <li><strong>SEVERABILITY: </strong>If any part or sub-part of these Terms of Service is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of these Terms of Service shall continue in full force.</li>
                                <li><strong>NO WAIVER: </strong>In the event that We fail to enforce any provision of these Terms of Service, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of these Terms of Service will not constitute a waiver of any other part or sub- part. </li>
                                <li><strong>HEADINGS FOR CONVENIENCE ONLY: </strong>Headings of parts and sub-parts under these Terms of Service are for convenience and organization, only. Headings shall not affect the meaning of any provisions of these Terms of Service.</li>
                                <li><strong>NO AGENCY, PARTNERSHIP OR JOINT VENTURE: </strong>No agency, partnership, or joint venture has been created between the Parties as a result of these Terms of Service. No Party has any authority to bind the other to third parties.</li>
                                <li><strong>ELECTRONIC COMMUNICATIONS PERMITTED: </strong>Electronic communications are permitted to both Parties under these Terms of Service, including e-mail or fax. For any questions or concerns, please email Us at the following address: info@agprop.in</li>
                            </ol>
                        </Typography>
                    </ListItem>
                </List>
            </Paper>
        </Stack>
    )
}

export default TermsConditions;
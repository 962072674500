const authApi = {
  // login: 'auth/login',
  // logout: 'auth/logout',
  topBrand: 'brand/top-brand',
  brandReqList: 'brand/req-list',
  req_detail: 'req/details',
  contactUs: 'contactUs/form',
  subscribe: 'subscribe/newsLetter',
  brand_data: 'brand/data',
};

export { authApi };
